import { Flex } from 'src/components/design-system';
import styled from 'styled-components';

const HEADER_HEIGHT = 40;

export const Container = styled.div`
  height: 100%;
  overflow: auto;
`;

export const Header = styled(Flex)`
  height: ${HEADER_HEIGHT}px;
  border-bottom: 1px solid ${({ theme }) => theme.border.color.primary};
  padding: 4px;
  text-align: right;
`;

export const Title = styled.div`
  margin: 0;
  font-weight: bold;
  padding-left: 10px;
`;

export const List = styled.div`
  overflow-y: auto;
  height: calc(100% - ${HEADER_HEIGHT}px);
`;
