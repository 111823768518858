import React from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from 'src/components/design-system';

import { AssigneeFullName, NoAssigneeIcon } from './styles';

const NoAssignee = ({ compact = false }) => {
  const { t } = useTranslation();
  return (
    <>
      <NoAssigneeIcon>
        <Icon name="user-circle" size={14} />
      </NoAssigneeIcon>
      {!compact && (
        <AssigneeFullName>{t('task.assignee.noAssignee')}</AssigneeFullName>
      )}
    </>
  );
};

export default NoAssignee;
