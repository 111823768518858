import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  top: 50px;
  right: 50px;
  z-index: 100;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.border.color.primary};
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  background-color: ${({ theme }) => theme.background.primary};
`;
