import { Flex } from 'src/components/design-system';
import styled from 'styled-components';

export const Container = styled(Flex)`
  position: absolute;
  background: white;
  border: 1px solid #e0e0e0;
  padding: 10px;
  z-index: 1;
`;

type ColorButtonProps = {
  bgColor: string;
};

export const ColorButton = styled.button<ColorButtonProps>`
  width: 24px;
  height: 24px;
  border: 1px solid ${({ theme }) => theme.border.color.primary};
  border-radius: ${({ theme }) => theme.border.radius.sm};
  background-color: ${({ bgColor }) => bgColor};
  cursor: pointer;
`;
