import React, { memo } from 'react';

import { colorToCss } from '../utils/utils';
import Ellipse from './Ellipse';
import Note from './Note';
import Path from './Path';
import Rectangle from './Rectangle';
import Text from './Text';
import { useStorage } from 'src/liveblocks.config';

import { LayerType } from '../types';

type LayerPreviewProps = {
  id: string;
  onPointerDown: (e: React.PointerEvent, id: string) => void;
  selectionColor?: string;
};
const LayerPreview = ({
  id,
  onPointerDown,
  selectionColor,
}: LayerPreviewProps) => {
  const layer = useStorage(state => state.layers.get(id));

  if (!layer) return null;

  switch (layer.type) {
    case LayerType.Rectangle:
      return (
        <Rectangle
          id={id}
          layer={layer}
          onPointerDown={onPointerDown}
          selectionColor={selectionColor}
        />
      );

    case LayerType.Text:
      return (
        <Text
          id={id}
          layer={layer}
          onPointerDown={onPointerDown}
          selectionColor={selectionColor}
        />
      );

    case LayerType.Note:
      return (
        <Note
          id={id}
          layer={layer}
          onPointerDown={onPointerDown}
          selectionColor={selectionColor}
        />
      );

    case LayerType.Ellipse:
      return (
        <Ellipse
          id={id}
          layer={layer}
          onPointerDown={onPointerDown}
          selectionColor={selectionColor}
        />
      );
    case LayerType.Path:
      return (
        <Path
          key={id}
          points={layer.points}
          onPointerDown={e => onPointerDown(e, id)}
          x={layer.x}
          y={layer.y}
          fill={layer.fill ? colorToCss(layer.fill) : '#000'}
          stroke={selectionColor}
        />
      );
    default:
      console.error('Unknown layer type', layer);
      return null;
  }
};

export default memo(LayerPreview);
