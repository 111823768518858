import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { FormItem } from 'src/components/design-system';

import { useTaskCustomFields } from 'src/hooks/useTaskCustomFields';

type TextCustomFieldProps = {
  text: string;
};
const TextCustomField = ({ customField }) => {
  const { _id, title, value } = customField;

  const { onCreateTaskCustomField } = useTaskCustomFields();

  const methods = useForm<TextCustomFieldProps>({
    defaultValues: {
      text: value,
    },
  });

  const { handleSubmit } = methods;

  const onSubmit = ({ text }) => {
    onCreateTaskCustomField({
      customFieldId: _id,
      value: text,
    });
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormItem label={title} name="text">
            <input name="text" placeholder={`Enter ${title}`} />
          </FormItem>
        </form>
      </FormProvider>
    </>
  );
};

export default TextCustomField;
