import {
  GET_PROJECT_TASKS_FOR_BOARD_VIEW,
  GET_TASKS_WITH_SUBTASKS,
  TASK_HISTORY,
} from '../graphql/actions';
import { TASK_FRAGMENT } from '../graphql/actions/fragments';
import { GET_COMMENTS } from '../graphql/comments';
import { TASK_DESCRIPTION_HISTORY } from '../graphql/task-description-history';
import uniqBy from 'lodash/uniqBy';
import { useUserWorkspace } from 'src/store';

import {
  useOnCreateCommentSubscription,
  useOnDeleteTasksSubscription,
  useOnCreateTaskHistorySubscription,
  useOnDeleteCommentSubscription,
  useOnUpdateTaskSubscription,
  useOnCreateTaskDescriptionHistorySubscription,
  useOnCreateTaskSubscription,
  GetTasksDocument,
} from '../generated';

export const useProjectSubscriptions = () => {
  const workspaceId = useUserWorkspace();

  useOnCreateTaskSubscription({
    onSubscriptionData: ({ client, subscriptionData }) => {
      if (!subscriptionData.data) return;

      const newTask = subscriptionData.data.onCreateTask;

      if (newTask.parentId) {
        client.cache.updateQuery(
          {
            query: GetTasksDocument,
          },
          data => {
            const tasks = data?.getTasks || [];
            return {
              getTasks: [...tasks, newTask],
            };
          },
        );
        return;
      }

      client.cache.updateQuery(
        {
          query: GET_PROJECT_TASKS_FOR_BOARD_VIEW,
          variables: { projectId: newTask?.projectId?._id },
        },
        cachedTasks => {
          const { kanbanTasks } = cachedTasks;
          return {
            kanbanTasks: kanbanTasks.map(column => {
              if (column._id === newTask.statusId) {
                return {
                  ...column,
                  tasksCount: column.tasksCount + 1,
                  actions: uniqBy([newTask, ...column.actions], '_id'),
                };
              }
              return column;
            }),
          };
        },
      );

      client.cache.updateQuery(
        {
          query: GET_TASKS_WITH_SUBTASKS,
          variables: { projectId: newTask?.projectId?._id, workspaceId },
        },
        data => {
          const tasksWithSubtasks = data?.getTasksWithSubtasks || [];
          return {
            getTasksWithSubtasks: [
              { ...newTask, subtasks: [] },
              ...tasksWithSubtasks,
            ],
          };
        },
      );
    },
  });

  useOnUpdateTaskSubscription({
    onSubscriptionData: ({ client, subscriptionData }) => {
      if (!subscriptionData.data) return;
      const updatedTask = subscriptionData.data.onUpdateTask;

      console.log('updatedTask', updatedTask);

      client.cache.writeFragment({
        id: client.cache.identify(updatedTask),
        fragment: TASK_FRAGMENT,
        data: {
          ...updatedTask,
        },
      });
    },
  });

  useOnDeleteTasksSubscription({
    onSubscriptionData: ({ client, subscriptionData }) => {
      if (!subscriptionData.data) return;

      const deletedTasks = subscriptionData.data.onDeleteTasks;

      deletedTasks.forEach((deletedTask: any) => {
        console.log('deletedTask in SUB', deletedTask);
        client.cache.evict({ id: client.cache.identify(deletedTask) });
      });

      client.cache.gc();
    },
  });

  useOnCreateCommentSubscription({
    onSubscriptionData: ({ client, subscriptionData }) => {
      if (!subscriptionData.data) return;
      const newComment = subscriptionData.data.onCreateComment;

      const data = client.readQuery({
        query: GET_COMMENTS,
        variables: { taskId: newComment.taskId },
      });

      // update comments cache only if user load comments for this task
      if (!data) return;

      const comments = data?.comments;

      client.writeQuery({
        query: GET_COMMENTS,
        variables: { taskId: newComment.taskId },
        data: { comments: [...comments, newComment] },
      });
    },
  });

  useOnDeleteCommentSubscription({
    onSubscriptionData: ({ client, subscriptionData }) => {
      if (!subscriptionData.data) return;
      const deletedComment = subscriptionData.data.onDeleteComment;
      client.cache.evict({ id: client.cache.identify(deletedComment) });
      client.cache.gc();
    },
  });

  useOnCreateTaskDescriptionHistorySubscription({
    onSubscriptionData: ({ client, subscriptionData }) => {
      if (!subscriptionData.data) return;
      const newTaskDescriptionHistory =
        subscriptionData.data.onCreateTaskDescriptionHistory;

      const data = client.readQuery({
        query: TASK_DESCRIPTION_HISTORY,
        variables: { taskId: newTaskDescriptionHistory.taskId },
      });

      // update comments cache only if user load comments for this task
      if (!data) return;

      const taskDescriptionHistory = data?.taskDescriptionHistory;

      client.writeQuery({
        query: TASK_DESCRIPTION_HISTORY,
        variables: { taskId: newTaskDescriptionHistory.taskId },
        data: {
          taskDescriptionHistory: [
            newTaskDescriptionHistory,
            ...taskDescriptionHistory,
          ],
        },
      });
    },
  });

  useOnCreateTaskHistorySubscription({
    onSubscriptionData: ({ client, subscriptionData }) => {
      if (!subscriptionData.data) return;

      const newTaskHistory = subscriptionData.data.onCreateTaskHistory;

      client.cache.updateQuery(
        {
          query: TASK_HISTORY,
          variables: { taskId: newTaskHistory.taskId },
        },
        data => {
          const taskHistory = data?.taskHistory || [];
          return { taskHistory: [newTaskHistory, ...taskHistory] };
        },
      );
    },
  });
};
