import React from 'react';

import { Flex, Skeleton, Box } from 'src/components/design-system';

import { ModalLoader, Row, Left, Right, RightBlockItem } from './styles';

const TaskModalSkeleton = () => {
  return (
    <ModalLoader>
      <Row>
        <Right>
          <RightBlockItem>
            <Flex gap={6} direction="column">
              <Skeleton height={15} />
              <Skeleton height={30} />
            </Flex>
          </RightBlockItem>

          <RightBlockItem>
            <Flex gap={6} direction="column">
              <Skeleton height={15} />
              <Skeleton height={20} />
            </Flex>
          </RightBlockItem>

          <RightBlockItem>
            <Flex gap={6} direction="column">
              <Skeleton height={15} />
              <Skeleton height={30} />
            </Flex>
          </RightBlockItem>

          <RightBlockItem>
            <Flex gap={6} direction="column">
              <Skeleton height={15} />
              <Skeleton height={30} />
            </Flex>
          </RightBlockItem>

          <RightBlockItem>
            <Flex gap={6} direction="column">
              <Skeleton height={20} />
              <Skeleton height={50} />
            </Flex>
          </RightBlockItem>
        </Right>

        <Left>
          <Box padding={20}>
            <RightBlockItem>
              <Flex gap={20} direction="column">
                <Skeleton width={600} height={30} />
              </Flex>
            </RightBlockItem>

            <RightBlockItem>
              <Flex gap={10} direction="column">
                <Skeleton width={300} />
                <Skeleton width={400} />
                <Skeleton width={200} />
                <Skeleton width={400} />
                <Skeleton width={400} height={30} />
                <Skeleton width={500} />
              </Flex>
            </RightBlockItem>

            <RightBlockItem>
              <Flex gap={10} direction="column">
                <Skeleton width={400} height={30} />
              </Flex>
            </RightBlockItem>

            <RightBlockItem>
              <Flex gap={10} direction="column">
                <Skeleton width={300} />
                <Skeleton width={400} />
                <Skeleton width={400} />
                <Skeleton width={200} />
              </Flex>
            </RightBlockItem>

            <RightBlockItem>
              <Flex gap={10} direction="column">
                <Skeleton width={400} height={30} />
              </Flex>
            </RightBlockItem>

            <RightBlockItem>
              <Flex gap={20} direction="column">
                <Skeleton width={300} />
                <Skeleton height={100} />
                <Skeleton width={400} />
              </Flex>
            </RightBlockItem>
          </Box>
        </Left>
      </Row>
    </ModalLoader>
  );
};

export default TaskModalSkeleton;
