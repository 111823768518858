export const USER_MORE_SETTINGS = {
  SETTINGS: 'settings',
  LOGOUT: 'logout',
};

export const user_more_settings = [
  {
    id: USER_MORE_SETTINGS.SETTINGS,
    icon: 'settings',
    title: 'user.menu.settings',
  },
  {
    id: 'divider-1',
    type: 'divider',
  },
  {
    id: USER_MORE_SETTINGS.LOGOUT,
    title: 'user.menu.logout',
  },
];
