import React from 'react';

import { TableUtil } from '../../utils/table.js';

const InTable = ({ editor }) => {
  const table = new TableUtil(editor);

  const handleButtonClick = action => {
    switch (action) {
      case 'row':
        table.insertRow();
        break;
      case 'column':
        table.insertColumn();
        break;
      case 'remove':
        table.removeTable();
        break;
      default:
        return;
    }
  };
  return (
    <>
      <button onClick={() => handleButtonClick('row')}>{'insert row'}</button>
      <button onClick={() => handleButtonClick('column')}>
        {'insert column'}
      </button>

      {/*<Button format="remove table" onClick={() => handleButtonClick('remove')}>*/}
      {/*  remove table*/}
      {/*  <Icon name="removeTable" />*/}
      {/*</Button>*/}
    </>
  );
};

export default InTable;
