import styled from 'styled-components/macro';

export const AddActionBlock = styled.div`
  height: 40px;

  input {
    margin: 0;
    outline: none;
    border: none;
    width: 100%;
    background: transparent;
    font-weight: bold;
    font-size: 13px;
    color: ${p => p.theme.text.primary};
    border: 1px solid ${p => p.theme.taskPreview.border};
    border-radius: ${({ theme }) => theme.border.radius.sm};
    padding: 10px;
  }
`;
