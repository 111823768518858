import { Flex } from 'src/components/design-system';
import styled from 'styled-components';

export const CreateBoard = styled(Flex)`
  width: 230px;
  background: ${({ theme }) => theme.color.navi};
  border-radius: ${({ theme }) => theme.border.radius.md};
  cursor: pointer;
`;

export const Title = styled.h2`
  margin-top: 0;
`;
