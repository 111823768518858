import React, { useCallback, useMemo } from 'react';
import { Slate, Editable, withReact, ReactEditor } from 'slate-react';

import Element from './Elements/Element';
import Leaf from './Elements/Leaf';
import Toolbar from './Toolbar/Toolbar';
import { DEFAULT_EDITOR_DATA } from './constants';
import withChecklists from './plugins/withChecklists';
import withEmbeds from './plugins/withEmbeds';
import withLinks from './plugins/withLinks';
import withTables from './plugins/withTable';
import { Descendant, createEditor } from 'slate';
import { withHistory } from 'slate-history';

import type { SlateElements, SlateEditor } from './types';

import { EditorContainer } from './styles';

type EditorProps = {
  value?: any;
  updateValue?: (newValue: Descendant[]) => void;
  placeholder?: string;
  disabled?: boolean;
};

declare module 'slate' {
  interface CustomTypes {
    Editor: SlateEditor;
    Element: SlateElements;
    Text: Text;
  }
}

const Editor = ({
  value = DEFAULT_EDITOR_DATA,
  updateValue,
  placeholder,
  disabled,
}: EditorProps) => {
  const editor = useMemo(
    () =>
      withChecklists(
        withHistory(
          withEmbeds(
            withTables(withLinks(withReact(createEditor() as ReactEditor))),
          ),
        ),
      ),
    [],
  );

  const renderElement = useCallback(props => <Element {...props} />, []);

  const renderLeaf = useCallback(props => {
    return <Leaf {...props} />;
  }, []);

  return (
    <Slate
      editor={editor}
      // onChange={updateValue}
      initialValue={value}
      onValueChange={updateValue}
    >
      <Toolbar />
      <EditorContainer>
        <Editable
          disabled={disabled}
          placeholder={placeholder}
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          spellCheck
        />
      </EditorContainer>
    </Slate>
  );
};

export default Editor;
