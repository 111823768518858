import React, { useEffect, useState } from 'react';

import { useTask } from 'src/hooks/useTask';

import { calculateTaskRank } from '../../../../../../helpers/task';
import Subtask from './SubTask';
import {
  closestCenter,
  DndContext,
  type DragEndEvent,
  KeyboardSensor,
  MouseSensor,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';

import { TableTask } from '../../../Table/types';

import { GetTasksQuery } from 'src/generated';

import { AddSubtaskInput } from './styles';

const SubTasks = ({ action, data }) => {
  const { _id, statusId } = action;
  const [value, setValue] = useState('');
  const { onCreateTask, onUpdateTask } = useTask();
  const [subtasks, setSubtasks] = useState<GetTasksQuery['getTasks']>(data);

  useEffect(() => {
    if (data.length !== subtasks.length) {
      setSubtasks(data);
    }
  }, [data, subtasks]);

  const handleCreateSubtask = e => {
    e.preventDefault();
    onCreateTask({
      parentId: _id,
      statusId,
      title: value,
    });
    setValue('');
  };

  const subtaskIds = subtasks.map(({ _id }) => _id);

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (active && over && active.id !== over.id) {
      const oldIndex = subtaskIds.indexOf(active.id as any);
      const newIndex = subtaskIds.indexOf(over.id as any);
      const reorderedSubtasks = arrayMove(
        subtasks,
        oldIndex,
        newIndex,
      ) as TableTask[];
      setSubtasks(reorderedSubtasks);
      const aboveTask = reorderedSubtasks[newIndex + 1];
      const belowTask = reorderedSubtasks[newIndex - 1];
      const rank = calculateTaskRank(aboveTask, belowTask);

      onUpdateTask({
        taskId: active.id as string,
        rank,
      });
    }
  };

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: { distance: 10 },
    }),
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {}),
  );

  return (
    <div>
      <DndContext
        collisionDetection={closestCenter}
        modifiers={[restrictToVerticalAxis]}
        onDragEnd={handleDragEnd}
        sensors={sensors}
      >
        <SortableContext
          items={subtaskIds}
          strategy={verticalListSortingStrategy}
        >
          {subtasks?.map(task => (
            <Subtask key={task._id} task={task} />
          ))}
        </SortableContext>
      </DndContext>
      <form onSubmit={handleCreateSubtask}>
        <AddSubtaskInput
          type="text"
          name="subtask"
          placeholder="Add new subtask"
          value={value}
          onChange={e => setValue(e.target.value)}
        />
      </form>
    </div>
  );
};

export default SubTasks;
