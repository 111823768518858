import styled, { css } from 'styled-components';

import { handleMarginOrPadding } from 'src/helpers/handleMarginOrPadding';

interface FlexProps {
  alignItems?:
    | 'stretch'
    | 'center'
    | 'flex-start'
    | 'flex-end'
    | 'baseline'
    | 'initial'
    | 'inherit';
  justifyContent?:
    | 'flex-start'
    | 'flex-end'
    | 'space-between'
    | 'space-around'
    | 'center'
    | 'initial'
    | 'inherit';
  direction?: 'column' | 'row';
  flexWrap?: 'wrap' | 'nowrap' | 'reverse';
  height?: string;
  maxHeight?: string;
  width?: string;
  maxWidth?: string;
  minWidth?: string;
  alignSelf?: 'stretch' | 'center' | 'start' | 'end';
  justifySelf?: 'stretch' | 'center' | 'start' | 'end';
  bgColor?: string;
  flex?: string;
  flexBasis?: string;
  flexGrow?: number;
  flexShrink?: number;

  /* padding and margin */
  padding?: number | number[];
  margin?: number | number[];

  pushDown?: boolean;
  pushLeft?: boolean;
  pushRight?: boolean;
  pushTop?: boolean;
  gap?: number;
}

const Flex = styled.div<FlexProps>`
  ${({
    alignItems,
    justifyContent,
    direction,
    flexWrap,
    height,
    maxHeight,
    bgColor,
    flex,
    flexBasis,
    flexGrow,
    flexShrink,
    width,
    maxWidth,
    padding,
    margin,
    justifySelf,
    alignSelf,
    pushDown,
    pushLeft,
    pushRight,
    pushTop,
    gap,
    minWidth,
  }) => css`
    display: flex;
    ${justifyContent &&
    css`
      justify-content: ${justifyContent};
    `}
    ${alignItems &&
    css`
      align-items: ${alignItems};
    `}
      ${direction &&
    css`
      flex-direction: ${direction};
    `}
    ${flexWrap &&
    css`
      flex-wrap: ${flexWrap};
    `}
    ${height &&
    css`
      height: ${height};
    `}
    ${maxHeight &&
    css`
      max-height: ${maxHeight};
    `}
    ${width &&
    css`
      width: ${width};
    `}
    ${maxWidth &&
    css`
      max-width: ${maxWidth};
    `}
    ${minWidth &&
    css`
      min-width: ${minWidth};
    `}
    ${bgColor &&
    css`
      background-color: ${bgColor};
    `}
    /* additional flex properties */
    ${flex &&
    css`
      flex: ${flex};
    `}
    ${flexGrow &&
    css`
      flex-grow: ${flexGrow};
    `}
    ${flexShrink &&
    css`
      flex-shrink: ${flexShrink};
    `}
    ${flexBasis &&
    css`
      flex-basis: ${flexBasis};
    `}
    ${alignSelf &&
    css`
      align-self: ${alignSelf};
    `}
    ${justifySelf &&
    css`
      justify-self: ${justifySelf};
    `}
    ${handleMarginOrPadding(margin)}
    ${handleMarginOrPadding(padding, true)}
    ${pushDown &&
    css`
      margin-top: auto;
    `}
    ${pushTop &&
    css`
      margin-bottom: auto;
    `}
    ${pushLeft &&
    css`
      margin-right: auto;
    `}
    ${pushRight &&
    css`
      margin-left: auto;
    `}
    ${gap &&
    css`
      gap: ${gap}px;
    `}
  `}
`;
export default Flex;
