import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { COLORS } from './colors';
import Dropdown from 'rc-dropdown';

import {
  ColorsList,
  InputBlock,
  InputWrapper,
  InputWrapperColor,
  ColorItem,
  ColorsDropdown,
  ColorItemWrapper,
  ColorItemHover,
} from './styles';

type LabelInputProps = {
  title?: string;
  color?: string;
  onSave: (value: { title?: string; color?: string }) => void;
};

const LabelInput = ({ title = '', color, onSave }: LabelInputProps) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(title);

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      handleUpdateLabel();
    }
  };

  const handleUpdateLabel = () => {
    if (value?.length >= 2) {
      onSave({ title: value, color });
    }
  };

  const Menu = () => (
    <ColorsDropdown>
      <ColorsList>
        {COLORS.map(({ value }) => (
          <ColorItemWrapper
            key={value}
            onClick={() => onSave({ color: value })}
          >
            <ColorItemHover />
            <ColorItem style={{ background: value }} />
          </ColorItemWrapper>
        ))}
      </ColorsList>
    </ColorsDropdown>
  );

  return (
    <InputBlock>
      <InputWrapper>
        <Dropdown
          trigger={['click']}
          overlay={<Menu />}
          getPopupContainer={(trigger: HTMLElement) =>
            trigger?.parentNode as HTMLElement
          }
        >
          <InputWrapperColor style={{ background: color }} />
        </Dropdown>

        <input
          type="text"
          placeholder={t('task.labels.addLabelPlaceholder')}
          value={value}
          onChange={event => {
            setValue(event.target.value);
          }}
          onKeyDown={handleKeyDown}
          onBlur={handleUpdateLabel}
        />
      </InputWrapper>
    </InputBlock>
  );
};

export default LabelInput;
