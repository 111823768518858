class AuthService {
  getToken() {
    return localStorage.getItem('token');
  }

  login(token) {
    localStorage.setItem('token', token);
  }

  logout() {
    localStorage.removeItem('token');
  }
}

export default new AuthService();
