import { useParams } from 'react-router-dom';

import { GET_PROJECT_LABELS } from '../graphql/labels/queries';

import {
  useCreateLabelMutation,
  useDeleteLabelMutation,
  useUpdateLabelMutation,
} from 'src/generated';

export const useLabels = () => {
  const projectId = useParams().id!;

  const [deleteLabel] = useDeleteLabelMutation({
    update: (cache, { data }) => {
      if (!data?.deleteLabel) return;
      cache.evict({ id: cache.identify(data.deleteLabel) });
      cache.gc();
    },
  });

  const [editLabel] = useUpdateLabelMutation();

  const [createLabel] = useCreateLabelMutation({
    update: (cache, { data }) => {
      if (!data?.createLabel) return;

      cache.updateQuery(
        {
          query: GET_PROJECT_LABELS,
          variables: { projectId },
        },
        ({ labels: currentLabels }) => ({
          labels: [...currentLabels, data.createLabel],
        }),
      );
    },
  });

  const handleCreateLabel = async value => {
    const { title, color } = value;

    await createLabel({
      variables: {
        projectId,
        title,
        color,
      },
      optimisticResponse: {
        __typename: 'Mutation',
        createLabel: {
          __typename: 'Label',
          _id: 'tempLabelId',
          title,
          color,
        },
      },
    });
  };

  const handleDeleteLabel = async labelId => {
    await deleteLabel({
      variables: {
        id: labelId,
      },
    });
  };

  const handleEditLabel = async (labelId, values) => {
    await editLabel({
      variables: { id: labelId, ...values },
    });
  };

  return {
    onCreateLabel: handleCreateLabel,
    onDeleteLabel: handleDeleteLabel,
    onEditLabel: handleEditLabel,
  };
};
