import React from 'react';
import { Helmet } from 'react-helmet-async';

import { Editor } from 'src/components/design-system';

import { DateHelpers } from 'src/common/helpers';

import DashboardTasksByPeriod from 'src/widgets/DashboardTasksByPeriod/DashboardTasksByPeriod';

import { useUserStore } from '../../store';
import MainLayout from 'src/layouts/MainLayout/MainLayout';

import { Container, Header } from './styles';

const Home = () => {
  const user = useUserStore(state => state.user);

  if (!user) return null;

  const { fullName } = user;

  const today = new Date();
  const hours = today.getHours();

  const greetings = [
    {
      greet: 'Wow! You`re still awake. Working Late?',
      activeHours: hours >= 24 || hours <= 4,
    },
    { greet: 'Good Night!', activeHours: hours >= 21 || hours <= 11 },
    {
      greet: 'Good Evening!',
      activeHours: hours >= 18 && hours <= 20,
    },
    {
      greet: 'Good Afternoon!',
      activeHours: hours >= 13 && hours <= 17,
    },
    {
      greet: 'Hello! Good Morning! Have a nice day.',
      activeHours: hours >= 5 && hours <= 11,
    },
  ];

  const activeGreeting = greetings.find(({ activeHours }) => activeHours);

  console.log('render component Home');

  return (
    <>
      <Helmet>
        <title>{'Home'}</title>
        <meta
          name="app descriptions"
          content="A React Boilerplate application homepage"
        />
      </Helmet>

      <MainLayout>
        <Container>
          <Header>
            <p>{DateHelpers.formatDate(today)}</p>
            <h2>{`${activeGreeting?.greet}, ${fullName}`}</h2>
          </Header>
          <DashboardTasksByPeriod />
          <Editor />
        </Container>
      </MainLayout>
    </>
  );
};

export default Home;
