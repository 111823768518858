import styled from 'styled-components';

export const Container = styled.div`
  border-top: 4px solid ${({ theme }) => theme.color.success};
`;

export const Header = styled.div`
  padding: 20px;
  border-bottom: 1px solid ${({ theme }) => theme.border.color.primary};
`;
