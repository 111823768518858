import React from 'react';

import { SkeletonElement } from './styles';

export type SkeletonProps = {
  width?: string | number;
  height?: string | number;
  circle?: boolean;
};

const Skeleton = (props: SkeletonProps) => {
  return <SkeletonElement {...props} />;
};

export default Skeleton;
