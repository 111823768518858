import { ClientSideSuspense } from '@liveblocks/react';
import React, { ReactNode } from 'react';

import { Layer } from 'src/components/liveblocks/types';

import { LiveMap, LiveList, LiveObject } from '@liveblocks/client';
import { RoomProvider } from 'src/liveblocks.config';

type RoomProps = {
  children: ReactNode;
  roomId: string;
  fallback: NonNullable<ReactNode> | null;
};

const Room = ({ children, roomId, fallback }: RoomProps) => {
  return (
    <RoomProvider
      id={roomId}
      initialPresence={{
        cursor: null,
        selection: [],
        pencilDraft: null,
        penColor: null,
      }}
      initialStorage={{
        layers: new LiveMap<string, LiveObject<Layer>>(),
        layerIds: new LiveList<string>(),
      }}
    >
      <ClientSideSuspense fallback={fallback}>
        {() => children}
      </ClientSideSuspense>
    </RoomProvider>
  );
};

export default Room;
