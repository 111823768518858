import React, { useState, useEffect } from 'react';

import * as Styled from './styles';

type ImageBlockProps = {
  src: string;
  alt: string;
  preview?: string;
};

const ImageBlock = ({ src, alt, preview }: ImageBlockProps) => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => {
      setIsLoaded(true);
    };
  }, []);

  return (
    <>
      <Styled.BlurredImg isLoaded={isLoaded} preview={preview}>
        <img loading="lazy" src={src} alt={alt} />
      </Styled.BlurredImg>
    </>
  );
};

export default ImageBlock;
