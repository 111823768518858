import React from 'react';

import { SwitchLabel } from './styles';

interface SwitchProperties {
  id: string;

  onChange: (isChecked: boolean) => void;

  'data-on'?: string;

  isChecked: boolean;

  'data-off'?: string;

  description?: string;
}

const Switch: React.FC<SwitchProperties> = props => {
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange && props.onChange(event.target.checked);
  };

  const labelId = `label-${props.id}`;
  const descriptionId = `description-${props.id}`;

  const labelBy = labelId + ' ' + descriptionId;

  return (
    <SwitchLabel htmlFor={props.id} className="switch">
      <input
        id={props.id}
        type="checkbox"
        role="switch"
        data-on={props['data-on']}
        checked={props.isChecked}
        data-off={props['data-off']}
        onChange={onChange}
        aria-checked={props.isChecked}
        aria-labelledby={labelBy}
      />
    </SwitchLabel>
  );
};

Switch.defaultProps = {
  'data-on': 'ON',
  'data-off': 'OFF',
};

export default Switch;
