import { useUser } from 'src/store';

import {
  useOnCreateNotificationSubscription,
  NotificationsDocument,
  NotificationsQueryVariables,
} from 'src/generated';

export const useNotificationSubscriptions = () => {
  const { _id, workspaceId } = useUser();

  useOnCreateNotificationSubscription({
    variables: {
      workspaceId,
      userId: _id,
      isPrivate: true,
    },
    onSubscriptionData: ({ client, subscriptionData }) => {
      if (!subscriptionData.data) return;
      const newNotification = subscriptionData.data.onCreateNotification;

      let variables: NotificationsQueryVariables = {
        workspaceId,
        isPrivate: newNotification.isPrivate,
      };
      if (newNotification.isPrivate) {
        variables.userId = _id;
      } else {
        variables.projectId = newNotification.projectId;
      }

      client.cache.updateQuery(
        {
          query: NotificationsDocument,
          variables,
        },
        data => {
          if (!data) return;
          const { notifications } = data;
          return {
            notifications: [newNotification, ...notifications],
          };
        },
      );
    },
  });
};
