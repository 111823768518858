import React from 'react';

import { Flex } from 'src/components/design-system';

const DisplayStoryPointsDropdown = ({ storyPoint }) => {
  if (!storyPoint) return null;

  const { label, value } = storyPoint;

  return (
    <Flex padding={10} alignItems="center" gap={10}>
      <strong>{value}</strong>
      <span>{label}</span>
    </Flex>
  );
};

export default DisplayStoryPointsDropdown;
