import { Flex } from 'src/components/design-system';
import styled from 'styled-components/macro';

export const Header = styled.header`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 9;
  height: 90px;
`;

export const TopHeader = styled(Flex)`
  height: 50px;
  color: ${p => p.theme.header.text};
  border-bottom: 1px solid ${({ theme }) => theme.border.color.primary};
`;
