import React from 'react';

import {
  AttachmentItem,
  AttachmentItemBody,
  AttachmentItemCloseBtn,
  AttachmentItemImg,
  AttachmentItemInfo,
  AttachmentItemInfoText,
  AttachmentItemTitle,
} from '../../../pages/Project/components/Board/Action/Attachements/styles';
import { Icon, Image } from 'src/components/design-system';

import { formatBytes } from '../../../helpers/files';

import { FilesQuery } from 'src/generated';

type FileProps = FilesQuery['files'][0] & {
  onRequestClose?: () => void;
};

const File = ({
  _id,
  fileName,
  url,
  size,
  type,
  onRequestClose,
}: FileProps) => {
  return (
    <AttachmentItem title={fileName}>
      <AttachmentItemImg>
        <Image src={url} alt={fileName} />
      </AttachmentItemImg>
      <AttachmentItemBody>
        <AttachmentItemTitle>{fileName}</AttachmentItemTitle>
        <AttachmentItemInfo>
          <AttachmentItemInfoText>
            <div>{formatBytes(size)}</div>
            <div>{type}</div>
          </AttachmentItemInfoText>
          {/*<Progress style={{ width: progressInfo.percentage + '%' }} />*/}
        </AttachmentItemInfo>
      </AttachmentItemBody>
      {onRequestClose && (
        <AttachmentItemCloseBtn
          size="small"
          type="button"
          // onClick={() => handleRemoveAttachment(_id)}
        >
          <Icon name="close" />
        </AttachmentItemCloseBtn>
      )}
    </AttachmentItem>
  );
};

export default File;
