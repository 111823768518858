import styled from 'styled-components';

type CardContainerProps = {
  width?: string;
};

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-flow: row wrap;
  padding: 12px;
`;

export const CardContainer = styled.div<CardContainerProps>`
  padding: 12px;
  width: ${props => props.width || '25%'};
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px;
  border-radius: ${({ theme }) => theme.border.radius.md};
  border: 1px solid ${p => p.theme.border.color.primary};
  &:hover {
    border: 1px solid ${p => p.theme.border.color.secondary};
  }
`;

export const TooltipBlock = styled.div`
  padding: 10px;
  outline: none;
  border-radius: ${({ theme }) => theme.border.radius.md};
  background: ${p => p.theme.background.primary};
  border: 1px solid ${p => p.theme.border.color.primary};
`;

export const Title = styled.h2`
  font-size: 20px;
  font-weight: normal;
`;

export const Count = styled.div`
  font-size: 48px;
  font-weight: bold;
  color: ${p => p.color};
`;
