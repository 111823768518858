import { css } from 'styled-components';

export const handleMarginOrPadding = (
  values?: number | number[],
  isPadding?: boolean,
) => {
  if (!values) return;

  if (Array.isArray(values)) {
    if (values.length > 4) {
      throw new Error(`${isPadding ? 'Padding' : 'Margin'} can have 4 values`);
    }

    // [1, 2] => '1px 2px'
    const convertValuesArrayToString = values
      .map(v => (v === 0 ? 0 : `${v}px`))
      .join(' ');

    if (isPadding) {
      return css`
        padding: ${convertValuesArrayToString};
      `;
    }

    return css`
      margin: ${convertValuesArrayToString};
    `;
  }

  if (isPadding) {
    return css`
      padding: ${values}px;
    `;
  }

  return css`
    margin: ${values}px;
  `;
};
