import React from 'react';

import { initialState } from './constants';

import { InitialState } from './types';

const FormContext = React.createContext({
  state: initialState,
  dispatch: (args: Partial<InitialState>) => {},
});

export default FormContext;
