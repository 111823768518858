import { Flex } from 'src/components/design-system';
import styled, { css } from 'styled-components';

import { PRIORITY } from 'src/common/constants';

type CellProps = {
  noPadding?: boolean;
  completed: boolean;
  selected: boolean;
  priority?: PRIORITY;
};

export const Cell = styled(Flex)<CellProps>`
  border-bottom: 1px solid ${({ theme }) => theme.border.color.primary};
  border-right: 1px solid ${({ theme }) => theme.border.color.primary};
  margin: 0;
  padding: 6px 12px;
  text-align: left;
  height: 50px;
  width: 100%;
  ${({ noPadding }) => noPadding && 'padding: 0;'}
  background-color: ${({ theme }) => theme.background.primary};

  ${({ priority }) => {
    if (priority === PRIORITY.ARGENT) {
      return css`
        background-color: ${({ theme }) => theme.task.urgent};
      `;
    }

    if (priority === PRIORITY.HIGH) {
      return css`
        background-color: ${({ theme }) => theme.task.high};
      `;
    }
  }}

  ${({ completed }) =>
    completed &&
    css`
      background-color: ${({ theme }) => theme.task.completed};
    `}

  ${({ selected }) =>
    selected &&
    css`
      background-color: ${({ theme }) => theme.task.selected};
    `}
`;
