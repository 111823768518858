import React from 'react';
import DatePicker from 'react-datepicker';

import { useTaskCustomFields } from 'src/hooks/useTaskCustomFields';

import { Container, Title } from './styles';

const DateCustomField = ({ customField }) => {
  const { _id, value, title } = customField;

  const { onCreateTaskCustomField } = useTaskCustomFields();
  const onChange = date => {
    onCreateTaskCustomField({
      customFieldId: _id,
      value: date,
    });
  };

  const date = value ? new Date(value) : undefined;

  return (
    <Container>
      <Title>{title}</Title>
      <DatePicker selected={date} onChange={onChange} inline />
    </Container>
  );
};

export default DateCustomField;
