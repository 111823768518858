export const COLORS = [
  {
    title: 'Brandeis Blue',
    value: '#0073ea',
  },
  {
    title: 'Paradise Pink',
    value: '#E2445C',
  },
  {
    title: 'Done Green',
    value: '#00C875',
  },

  {
    title: 'Working Orange',
    value: '#fdbc64',
  },
  {
    title: 'Grass Green',
    value: '#359970',
  },
  {
    title: 'Bright Green',
    value: '#b0dc51',
  },
  {
    title: 'Blue',
    value: '#0086C0',
  },
  {
    title: 'Saladish',
    value: '#d5c567',
  },
  {
    title: 'Egg Yolk',
    value: '#ffd533',
  },
  {
    title: 'Working Orange',
    value: '#fdbc64',
  },
  {
    title: 'Dark Orange',
    value: '#ff7b4d',
  },
  {
    title: 'Peach',
    value: '#ffbdbd',
  },
  {
    title: 'Sunset',
    value: '#ff9191',
  },
  {
    title: 'Stuck Red',
    value: '#e8697d',
  },
  {
    title: 'Dark Red',
    value: '#c95c76',
  },
  {
    title: 'Sofia Pink',
    value: '#ff44a1',
  },
  {
    title: 'Lipstick',
    value: '#ff7bd0',
  },
  {
    title: 'Bubble',
    value: '#fbb4f4',
  },
  {
    title: 'Purple',
    value: '#b57de3',
  },
  {
    title: 'Berry',
    value: '#9862a1',
  },
  {
    title: 'Dark Indigo',
    value: '#6645a9',
  },
  {
    title: 'Navy',
    value: '#4e73a7',
  },
  {
    title: 'Bright Blue',
    value: '#79affd',
  },
  {
    title: 'Aquamarine',
    value: '#71d6d1',
  },
  {
    title: 'Chili Blue',
    value: '#85d6ff',
  },
  {
    title: 'River',
    value: '#86b4ca',
  },
  {
    title: 'Winter',
    value: '#aebdca',
  },
  {
    title: 'American Gray',
    value: '#999999',
  },
  {
    title: 'Blackish',
    value: '#5c5c5c',
  },
  {
    title: 'Brown',
    value: '#99756c',
  },
  {
    title: 'Orchid',
    value: '#e190c0',
  },
  {
    title: 'Tan',
    value: '#bdab95',
  },
  {
    title: 'Sky',
    value: '#b4e9f8',
  },
  {
    title: 'Coffee',
    value: '#ca9a8b',
  },
  {
    title: 'Royal',
    value: '#5591ea',
  },
  {
    title: 'Teal',
    value: '#457b82',
  },
  {
    title: 'Lavender',
    value: '#cab9fa',
  },
  {
    title: 'Steal',
    value: '#BACBED ',
  },
  {
    title: 'Lilac',
    value: '#687185',
  },
  {
    title: 'Pecan',
    value: '#786565',
  },
];
