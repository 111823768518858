import { Link } from 'react-router-dom';

import styled, { css } from 'styled-components';

type MenuLinkProps = {
  selected?: boolean;
};

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  min-height: 100%;
  a {
    text-decoration: none;
  }
`;

export const SidebarHeader = styled.div`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  color: #fff;
  h1 {
    margin: 0;
  }
`;

export const SidebarHeaderButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
`;

type SidebarProps = {
  open: boolean;
};

export const Sidebar = styled.div<SidebarProps>`
  width: 240px;
  position: fixed;
  top: 0;
  left: ${({ open }) => (open ? '0' : '-240px')};
  bottom: 0;
  display: flex;
  flex-direction: row;
  background-color: ${p => p.theme.sidebar.primary};
  color: ${p => p.theme.text};
  box-shadow: -8px 2px 22px -7px rgba(0, 0, 0, 0.25);
  z-index: 2;
  transition: left 400ms ease-in-out;
  a {
    color: #fff;
    span {
      color: #fff;
    }
  }
`;

export const Body = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  min-height: 1px;
  position: relative;
  margin-top: 90px;
  height: 100%;
  overflow: auto;
`;

export const Content = styled.div<SidebarProps>`
  margin-left: ${({ open }) => (open ? '240px' : '0')};
  flex: 1;
  display: flex;
  flex-direction: column;
  color: ${p => p.theme.text.primary};
  background-color: ${p => p.theme.layout.background};
  box-shadow: 8px 2px 32px -2px rgba(0, 0, 0, 0.25);
  overflow: auto;
  transition: margin-left 400ms ease-in-out;
  position: relative;
  overflow-y: hidden;
`;

export const MenuLink = styled(Link)<MenuLinkProps>`
  height: 40px;
  line-height: 40px;
  align-items: center;
  display: flex;
  padding: 0 16px 0 16px;
  font-size: 14px;
  &:hover {
    background-color: rgba(255, 255, 255, 0.08);
  }

  svg {
    margin-right: 10px;
  }

  ${({ selected }) =>
    selected &&
    css`
      background-color: rgba(255, 255, 255, 0.28);
      &:hover {
        background-color: rgba(255, 255, 255, 0.28);
      }
    `}
`;
