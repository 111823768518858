import confetti from 'canvas-confetti';

const DURATION = 5000; // 5 seconds
const colors: string[] = ['#9b59b6', '#e74c3c', '#f4c505', '#035891'];

const defaultOptions: confetti.Options = {
  particleCount: colors.length,
  disableForReducedMotion: false,
  zIndex: 9999,
  spread: 120,
  decay: 0.92,
  scalar: 1.2,
  colors,
};

export const playConfettiAnimation = async (sides: ('left' | 'right')[]) => {
  const end = Date.now() + DURATION;
  const left = sides.includes('left');
  const right = sides.includes('right');
  let frameRequest: number;

  const frame = () => {
    if (Date.now() > end) return;

    if (left)
      confetti({
        ...defaultOptions,
        angle: 60,
        origin: { x: 0, y: 0.6 },
      });
    if (right)
      confetti({
        ...defaultOptions,
        angle: 120,
        origin: { x: 1, y: 0.6 },
      });

    cancelAnimationFrame(frameRequest);
    frameRequest = requestAnimationFrame(frame);
  };

  frame();
};
