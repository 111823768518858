import styled from 'styled-components/macro';

export const ToolbarButton = styled.button<{ active?: boolean }>`
  && {
    margin: 0 3px;
    border-radius: ${({ theme }) => theme.border.radius.sm};
    padding: 4px 6px;
    border: none;
    background: ${({ theme, active }) =>
      active ? theme.background.primary : theme.background.secondary};

    &:hover {
      background: ${({ theme }) => theme.background.hover};
    }

    svg {
      color: ${({ theme, active }) =>
        active ? theme.text.active : theme.text.primary};
    }
  }
`;
