import { SlateEditor } from '../types';

const withEmbeds = (editor: SlateEditor) => {
  const { isVoid } = editor;

  editor.isVoid = element =>
    ['video', 'image'].includes(element.type) ? true : isVoid(element);
  return editor;
};

export default withEmbeds;
