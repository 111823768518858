import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import AddProjectModal from './components/AddProjectModal/AddProjectModal';
import ProjectList from './components/ProjectList/ProjectList';
import { Button, Box } from 'src/components/design-system';

import MainLayout from 'src/layouts/MainLayout/MainLayout';

const Projects = () => {
  const { t } = useTranslation();
  const [isOpenModal, setIsOpenModal] = useState(false);

  return (
    <>
      <Helmet>
        <title>{t('projects.title')}</title>
        <meta
          name="app descriptions"
          content="A React Boilerplate application homepage"
        />
      </Helmet>
      <MainLayout>
        <Box padding={20}>
          <Button onClick={() => setIsOpenModal(true)}>
            {t('createProject.title')}
          </Button>
          <AddProjectModal
            isOpen={isOpenModal}
            onClose={() => setIsOpenModal(false)}
          />
          <ProjectList />
        </Box>
      </MainLayout>
    </>
  );
};

export default Projects;
