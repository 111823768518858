import React from 'react';
import { components, ControlProps } from 'react-select';

import { Box } from 'src/components/design-system';

import { PRIORITY } from 'src/common/constants';

import PriorityIcon from './PriorityIcon';

const PriorityControl = ({ children, ...props }: ControlProps) => {
  const {
    selectProps: { value },
  } = props;

  return (
    <components.Control {...props}>
      {!value ||
        (value !== PRIORITY.NONE && (
          <Box margin={[0, 0, 0, 10]}>
            <PriorityIcon {...(value as any)} />
          </Box>
        ))}
      {children}
    </components.Control>
  );
};

export default PriorityControl;
