import React from 'react';
import { useParams } from 'react-router-dom';

import { LoadingIndicator } from 'src/components/design-system';
import Canvas from 'src/components/liveblocks/Canvas';
import Room from 'src/components/liveblocks/Room';
import { useTheme } from 'styled-components';

import MainLayout from 'src/layouts/MainLayout/MainLayout';

const MiroBoard = () => {
  const { miroId } = useParams<{ miroId: string }>();
  const theme = useTheme();

  return (
    <MainLayout>
      <Room
        roomId={miroId!}
        fallback={<LoadingIndicator color={theme.color.navi} />}
      >
        <Canvas boardId={miroId} />
      </Room>
    </MainLayout>
  );
};

export default MiroBoard;
