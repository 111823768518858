import styled, { css, keyframes } from 'styled-components/macro';

const pulse = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.1;
  }
  100% {
    opacity: 0;
  }
`;

type BlurredImgProps = {
  isLoaded: boolean;
  preview?: string;
};

export const BlurredImg = styled.div<BlurredImgProps>`
  background-repeat: no-repeat;
  background-size: 100% 100%;
  filter: ${({ isLoaded }) => (isLoaded ? 'none' : 'blur(5px)')};
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  background-image: url(${({ preview }) =>
    preview || '/image-placeholder.webp'});
  img {
    opacity: ${({ isLoaded }) => (isLoaded ? 1 : 0)};
    transition: opacity 500ms ease-in-out;
  }

  &::before {
    content: ${({ isLoaded }) => (isLoaded ? 'none' : '""')};
    position: absolute;
    inset: 0;
    opacity: 0;
    animation: ${pulse} 1000ms infinite;
    background-color: white;
  }

  ${({ isLoaded }) =>
    isLoaded &&
    css`
      &::before {
        animation: none;
      }
    `}
`;
