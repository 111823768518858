import styled from 'styled-components';

type ImageBlockProps = {
  size: number;
};
export const ImageBlock = styled.div<ImageBlockProps>`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  img {
    max-width: 100%;
    max-height: 100%;
  }
`;
