import { colors, grayScale } from './colors';

const common = {
  size: {
    xxs: '10px',
    xs: '12px',
    sm: '14px',
    md: '16px',
    lg: '24px',
    xl: '32px',
    xxl: '40px',
  },
  weight: {
    regular: 400,
    medium: 500,
    semiBold: 600,
  },
  family: 'Barlow, sans-serif',
};

export const fontLight = {
  color: {
    primary: grayScale.gray60,
    secondary: grayScale.gray50,
    tertiary: grayScale.gray40,
    light: grayScale.gray35,
    extraLight: grayScale.gray30,
    inverted: grayScale.gray0,
    danger: colors.red200,
  },
  ...common,
};

export const fontDark = {
  color: {
    primary: grayScale.gray20,
    secondary: grayScale.gray35,
    tertiary: grayScale.gray45,
    light: grayScale.gray50,
    extraLight: grayScale.gray55,
    inverted: grayScale.gray100,
    danger: colors.red200,
  },
  ...common,
};
