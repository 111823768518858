import React from 'react';

import { useReactiveVar } from '@apollo/client';

import { Modal } from 'src/components/design-system';

import InviteFrom from './InviteForm/InviteFrom';
import ProjectMembers from './ProjectMembers/ProjectMembers';
import { openInviteProjectMembersModalVar } from 'src/graphql/vars';

import * as Styled from './styles';

const InviteProjectMembers = () => {
  const isOpenInviteProjectMembersModal = useReactiveVar(
    openInviteProjectMembersModalVar,
  );

  const handleClose = () => openInviteProjectMembersModalVar(false);

  return (
    <Modal
      width={700}
      onRequestClose={handleClose}
      isOpen={isOpenInviteProjectMembersModal}
    >
      <Styled.Title>{'Invite project members'}</Styled.Title>
      <InviteFrom handleClose={handleClose} />
      <ProjectMembers />
    </Modal>
  );
};

export default InviteProjectMembers;
