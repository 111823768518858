import React, { useCallback, useMemo } from 'react';
import { Slate, Editable, ReactEditor } from 'slate-react';

import Element from '../Elements/Element';
import Leaf from '../Elements/Leaf';
import { createEditor } from 'slate';

import { EditorContainer } from '../styles';
import * as Styled from './styles';

// TODO: use this to render html, maybe need to use serialize
const RichText = ({ content }) => {
  const editor = useMemo(() => createEditor() as ReactEditor, []);
  const renderElement = useCallback(props => {
    const { isAdded, isUpdated } = props.element;

    return (
      <Styled.ElementBlock isAdded={isAdded} isUpdated={isUpdated}>
        <Element {...props} />
      </Styled.ElementBlock>
    );
  }, []);

  const renderLeaf = useCallback(props => <Leaf {...props} />, []);

  return (
    <EditorContainer>
      <Slate editor={editor} initialValue={JSON.parse(content)}>
        <Editable
          readOnly={true}
          renderLeaf={renderLeaf}
          renderElement={renderElement}
        />
      </Slate>
    </EditorContainer>
  );
};

export default RichText;
