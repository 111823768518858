import styled from 'styled-components/macro';

export const Dropdown = styled.div`
  padding: 10px;
  border-radius: ${({ theme }) => theme.border.radius.sm};
  border: 1px solid ${p => p.theme.border.color.primary};
  background-color: ${p => p.theme.background.secondary};
  width: 800px;
  position: absolute;
  left: 50%;
  margin-top: 10px;
  transform: translateX(-420px);
  z-index: 10;
  max-height: 500px;
  overflow-y: auto;
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
`;
