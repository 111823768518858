import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  Modal,
  Button,
  Colors,
  FormItem,
  Flex,
} from 'src/components/design-system';

import { useProjects } from 'src/hooks/useProjects';

import { ModalContent, ModalHeader } from './styles';

type ProjectProps = {
  title: string;
  color: string;
};

const PROJECT_COLOR = '#d30c5f';

const AddProjectModal = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const { onCreateProject, createProjectLoading } = useProjects();
  const methods = useForm<ProjectProps>({
    defaultValues: {
      color: PROJECT_COLOR,
    },
  });

  const { handleSubmit, reset, setValue } = methods;

  const handleCreateProject = ({ title, color }) => {
    onCreateProject({ title, color });
    onClose();
    reset();
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} width={500}>
      <ModalContent>
        <ModalHeader>
          <h2>{t('createProject.title')}</h2>
        </ModalHeader>

        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(handleCreateProject)}>
            <FormItem name="title" label={t('createProject.inputTitle')}>
              <input
                type="text"
                placeholder={t('createProject.inputTitlePlaceholder')}
              />
            </FormItem>

            <FormItem name="color" label={t('createProject.inputColor')}>
              <Colors
                defaultColor={PROJECT_COLOR}
                onChange={color => {
                  setValue('color', color);
                }}
              />
            </FormItem>

            <Flex gap={10} justifyContent="flex-end">
              <Button onClick={onClose}>{t('general.close')}</Button>
              <Button
                loading={createProjectLoading}
                onClick={handleSubmit(handleCreateProject)}
                htmlType="primary"
              >
                {t('createProject.createProject')}
              </Button>
            </Flex>
          </form>
        </FormProvider>
      </ModalContent>
    </Modal>
  );
};

export default AddProjectModal;
