import styled from 'styled-components';

export const ActionItemHeader = styled.div`
  display: flex;
  align-items: center;
  color: ${p => p.theme.text};
  margin-bottom: 15px;
`;

export const ActionItemTitle = styled.h2`
  margin: 0;
  padding-left: 10px;
  color: ${p => p.theme.text.active};
`;

type ActionItemProps = {
  bgColor?: string;
};

export const ActionItem = styled.div<ActionItemProps>`
  padding: 0 20px;
  margin: 0 0 50px 0;
  width: 100%;
  background-color: ${p => p.bgColor};

  input[type='file'] {
    display: none;
  }
  &:last-child {
    margin-bottom: 0;
  }
`;
