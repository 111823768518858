import { gql } from '@apollo/client';

gql`
  mutation ReadNotification($notificationId: ID!) {
    readNotification(notificationId: $notificationId) {
      _id
      isRead
    }
  }
`;

gql`
  mutation ReadAllNotification {
    readAllNotification
  }
`;
