import styled, { css } from 'styled-components';

type RightProps = {
  isDragging?: boolean;
};
export const Right = styled.div<RightProps>`
  flex: 1;
  overflow-y: auto;
  height: 100%;
  background: ${({ theme }) => theme.task.primary};
  border-bottom-left-radius: ${({ theme }) => theme.border.radius.md};

  ${({ isDragging }) =>
    isDragging &&
    css`
      border: 6px dashed ${({ theme }) => theme.border.color.secondary};
    `}
`;

type RightHeaderProps = {
  isHeaderFixed?: boolean;
};
export const RightHeader = styled.div<RightHeaderProps>`
  position: sticky;
  left: 0;
  top: 0;
  z-index: 1;
  background: ${({ theme }) => theme.task.primary};

  ${({ isHeaderFixed }) =>
    isHeaderFixed &&
    css`
      box-shadow: ${({ theme }) => theme.boxShadow.md};
    `}
`;
export const RightTitleBlock = styled.div<RightHeaderProps>`
  padding: 20px 20px 30px 20px;
  ${({ isHeaderFixed }) =>
    isHeaderFixed &&
    css`
      opacity: 0;
      visibility: hidden;
    `}
`;
