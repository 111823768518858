import React from 'react';
import { useParams } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import { AvatarList } from 'src/components/design-system';

import { GET_PROJECT_DETAILS } from 'src/graphql/projects';
import { openInviteProjectMembersModalVar } from 'src/graphql/vars';

const ProjectMembers = () => {
  const { id: projectId } = useParams<{ id: string }>();
  const { loading, data } = useQuery(GET_PROJECT_DETAILS, {
    variables: { projectId },
  });

  if (loading) return null;

  const {
    project: { members },
  } = data;

  const handleOpenInviteProjectModal = () => {
    openInviteProjectMembersModalVar(true);
  };

  return (
    <AvatarList
      allowInvite
      members={members}
      onClick={handleOpenInviteProjectModal}
    />
  );
};

export default ProjectMembers;
