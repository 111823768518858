import React from 'react';
import { useTranslation } from 'react-i18next';

import differenceInDays from 'date-fns/differenceInDays';
import subDays from 'date-fns/subDays';
import Tooltip from 'rc-tooltip';

import {
  LineContainer,
  LiveWidth,
  DaysRangeInfo,
  DaysRangeCount,
} from './styles';

const DaysRange = ({ startDate, endDate }) => {
  const { t } = useTranslation();
  const allDays = differenceInDays(
    new Date(endDate),
    subDays(new Date(startDate), 1),
  );

  const daysFromToday = differenceInDays(
    new Date(),
    subDays(new Date(startDate), 1),
  );

  const daysFromTodayPercent = (daysFromToday / allDays) * 100;

  return (
    <Tooltip
      overlay={
        <DaysRangeInfo>
          <div>
            {t('task.dueDate.allDays')}:
            <DaysRangeCount>{allDays}</DaysRangeCount>
          </div>
          <div>
            {t('task.dueDate.daysPassed')}:
            <DaysRangeCount>{daysFromToday}</DaysRangeCount>
            {t('task.dueDate.daysPassedDescription')}
          </div>
          <div>
            {t('task.dueDate.daysLeft')}:
            <DaysRangeCount>{allDays - daysFromToday}</DaysRangeCount>
          </div>
        </DaysRangeInfo>
      }
      placement="right"
      showArrow={false}
      overlayClassName="custom-tooltip"
    >
      <LineContainer>
        <LiveWidth width={daysFromTodayPercent} />
      </LineContainer>
    </Tooltip>
  );
};

export default DaysRange;
