import styled from 'styled-components/macro';

type ToolbarBlockProps = {
  focused?: boolean;
  readOnly?: boolean;
};

export const Container = styled.div`
  display: flex;
  width: 100%;
`;
export const CommentInputBlock = styled.div`
  width: 100%;
`;

export const ToolbarBlock = styled.div<ToolbarBlockProps>``;

export const CommentInput = styled.div<ToolbarBlockProps>`
  border-radius: ${({ theme }) => theme.border.radius.sm};
  border: 1px solid ${({ theme }) => theme.border.color.primary};
  width: 100%;
  padding: 10px;
  position: relative;
  min-height: 60px;

  ${({ focused }) =>
    focused &&
    `
    border: 2px solid #58a6ff;
    min-height: 100px;
    padding-bottom: 44px;
  `}

  p {
    margin-top: 0;
  }

  ${({ readOnly }) =>
    readOnly &&
    `
    padding-bottom: 0;
  `}
`;

export const CommentActions = styled.div<ToolbarBlockProps>`
  position: absolute;
  left: 8px;
  right: 8px;
  bottom: 8px;
  opacity: 0;
  visibility: hidden;
  display: flex;
  justify-content: space-between;

  ${({ focused }) =>
    focused &&
    `
   opacity: 1;
   visibility: visible;
`}
`;
