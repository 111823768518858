import { Flex, Button } from 'src/components/design-system';
import styled from 'styled-components/macro';

export const Title = styled.h2`
  margin: 0;
`;

export const Input = styled.input`
  padding: 8px 14px;
  border-radius: ${({ theme }) => theme.border.radius.sm};
  height: auto;
  line-height: inherit;
  transition: all 0.3s;
  width: 100%;
  color: ${p => p.theme.text.primary};
  border: 1px solid ${p => p.theme.border.color.primary};
  background-color: ${p => p.theme.background.primary};
  margin: 10px 0 20px 0;
  &:focus {
    outline: none;
    box-shadow: rgb(59 148 191 / 30%) 0 0 0 4px;
    border-color: rgb(59, 148, 191);
  }
`;

export const List = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  align-content: flex-start;
  height: calc(100vh - 145px);
  overflow-y: auto;
  img {
    width: 100%;
    height: 100%;
    max-width: 100%;
    object-fit: cover;
    border-radius: ${({ theme }) => theme.border.radius.md};
    aspect-ratio: 1;
  }
`;

export const ImageDescriptionBlock = styled(Flex)`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  transition: opacity 0.3s;
  border-radius: ${({ theme }) => theme.border.radius.md};
  opacity: 0;
  visibility: hidden;
`;

export const ImageBlock = styled.div`
  position: relative;
  border-radius: ${({ theme }) => theme.border.radius.md};
  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 10px;
    opacity: 0;
    transition: opacity 0.3s;
    visibility: hidden;
    background: rgb(33, 33, 33);
    background: linear-gradient(
      0deg,
      rgba(33, 33, 33, 0.8883928571428571) 35%,
      rgba(33, 33, 33, 0.15730042016806722) 80%
    );
  }
  &:hover ${ImageDescriptionBlock} {
    opacity: 1;
    visibility: visible;
  }

  &:hover::before {
    opacity: 1;
    visibility: visible;
  }
`;

export const Description = styled.span`
  font-weight: 500;
  margin: 0;
  color: white;
`;

export const AttachButton = styled(Button)`
  border: none;
  padding: 0;
  transition: transform 0.3s;
  &:hover {
    border: none;
    background: transparent;
    transform: scale(1.3);
  }
`;
