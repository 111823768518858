import { gql } from '@apollo/client';

export const CREATE_TASK_CUSTOM_FIELD = gql`
  mutation CreateTaskCustomField(
    $value: String!
    $taskId: ID!
    $projectId: ID!
    $customFieldId: ID!
  ) {
    createTaskCustomField(
      input: {
        value: $value
        taskId: $taskId
        projectId: $projectId
        customFieldId: $customFieldId
      }
    ) {
      _id
      value
    }
  }
`;
