import hexRgb from 'hex-rgb';

export const colors = {
  success: '#58a182',
  successLight: 'rgba(173, 227, 205, 0.22)',

  primary: '#079aaa',

  grey100: '#f8f9fa',
  grey200: '#e9ecef',
  grey300: '#dee2e6',
  grey400: '#ced4da',
  grey500: '#adb5bd',
  grey600: '#6c757d',
  grey700: '#495057',
  grey800: '#343a40',
  grey900: '#212529',

  green100: '#e6f4ea',
  green200: '#c3e6d3',
  green300: '#9fd8bc',
  green400: '#7bcba5',
  green500: '#58a182',
  green600: '#3f7a5f',
  green700: '#2e5a44',
  green800: '#1e3b2a',
  green900: '#0f1d15',

  red100: '#fddddd',
  red200: '#f69697',
  red300: '#ee6b6e',
  red400: '#f94449',
  red500: '#ff2c2c',
  red600: '#f01c2c',
  red700: '#de0a26',
  red800: '#d1001f',
  red900: '#c30010',

  orange100: '#fff3e6',
  orange200: '#ffe0bf',
  orange300: '#ffcc99',
  orange400: '#ffb366',
  orange500: '#ff9900',
  orange600: '#e68a00',
  orange700: '#cc7a00',
  orange800: '#b36b00',
  orange900: '#995c00',

  danger: '#eb7586',
  dangerLight: 'rgba(239,26,5,0.06)',
  white: '#fff',
  navi: '#4262FF',

  urgent: '#ff2c2c',
};

export const grayScale = {
  gray100: '#000000',
  gray90: '#141414',
  gray85: '#171717',
  gray80: '#1b1b1b',
  gray75: '#1d1d1d',
  gray70: '#222222',
  gray65: '#292929',
  gray60: '#333333',
  gray55: '#4c4c4c',
  gray50: '#666666',
  gray45: '#818181',
  gray40: '#999999',
  gray35: '#b3b3b3',
  gray30: '#cccccc',
  gray25: '#d6d6d6',
  gray20: '#ebebeb',
  gray15: '#f1f1f1',
  gray10: '#fcfcfc',
  gray0: '#ffffff',
};

export const rgba = (hex: string, alpha: number) => {
  const rgb = hexRgb(hex, { format: 'array' }).slice(0, -1).join(',');
  return `rgba(${rgb},${alpha})`;
};
