import React, { useState, useRef, useEffect } from 'react';

import { usePrevious } from 'src/hooks/common';

type EditableTextProps = {
  text?: string;
  onUpdate: (value: string) => void;
  placeholder?: string;
  customReadView?: () => JSX.Element;
};

const EditableText = ({
  text,
  placeholder,
  onUpdate,
  customReadView,
}: EditableTextProps) => {
  const ref = useRef<HTMLInputElement>(null);
  const [current, setCurrent] = useState(text || '');
  const [editing, setEditing] = useState(false);
  const prevText = usePrevious(text);

  const onKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      onBlur();
    }
  };

  const onBlur = () => {
    setEditing(false);
    if (current === prevText) return;
    onUpdate(current);
  };

  const handleSetEditing = () => {
    setEditing(true);
    ref.current?.focus();
  };

  useEffect(() => {
    if (editing && ref.current) {
      ref.current.focus();
    }
  }, [editing]);

  // editing view
  if (editing) {
    return (
      <input
        ref={ref}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        value={current}
        onChange={e => setCurrent(e.target.value)}
        onBlur={onBlur}
      />
    );
  }

  if (customReadView) {
    return (
      <div onClick={handleSetEditing} onKeyDown={onKeyDown}>
        {customReadView()}
      </div>
    );
  }
  // read view
  return <div onClick={handleSetEditing}>{text}</div>;
};

export default EditableText;
