import React from 'react';

import { FormItem } from 'src/components/design-system';

import Rating from '../../FormFields/Rating/Rating';

import { FieldType } from '../../types';

import * as Styled from './styles';

const FormPreviewCard = ({ element, index }) => {
  const { name, title, type } = element;

  const component = {
    [FieldType.Input]: <input type="text" />,
    [FieldType.TextArea]: <textarea />,
    [FieldType.RadioGroup]: <div>{'RadioGroup'}</div>,
    [FieldType.RatingScale]: <Rating />,
    [FieldType.Checkboxes]: <div>{'Checkboxes'}</div>,
    [FieldType.Boolean]: <div>{'Boolean'}</div>,
  };

  const label = `${index}. ${title || name}`;

  return (
    <div>
      <Styled.Card>
        <FormItem key={name} name={name} label={label}>
          {component[type]}
        </FormItem>
      </Styled.Card>
    </div>
  );
};

export default FormPreviewCard;
