import React, { useEffect } from 'react';
import { components, SingleValueProps } from 'react-select';

import { Flex } from 'src/components/design-system';

import { StoryPointCount, StoryPointText } from './styles';

const StoryPointsSingleValue = ({ children, ...props }: SingleValueProps) => {
  const { data } = props;

  // @ts-ignore
  const { label, value } = data;

  useEffect(() => {
    if (value === null) {
      props.clearValue();
    }
  }, [props, value]);

  return (
    <components.SingleValue {...props}>
      <Flex alignItems="center">
        <StoryPointCount>{value}</StoryPointCount>
        <StoryPointText>{label}</StoryPointText>
      </Flex>
    </components.SingleValue>
  );
};

export default StoryPointsSingleValue;
