import { Flex, objectFitImg } from 'src/components/design-system';
import styled, { css } from 'styled-components';

type ActionItemProps = {
  isDragging?: boolean;
  color?: string;
  isUrgent?: boolean;
};

type DateBlockProps = {
  isOverdue?: boolean;
  isCompleted?: boolean;
  today?: boolean;
};

type ActionTitleProps = {
  completed?: boolean;
};

export const ActionItem = styled.div<ActionItemProps>`
  position: relative;
  font-weight: normal;
  color: ${p => p.theme.text.primary};
  width: 100%;
  min-height: 118px;
  border-radius: ${({ theme }) => theme.border.radius.sm};
  cursor: pointer;
  flex-grow: 1;
  font-size: 13px;
  background-color: ${p => p.theme.taskPreview.primary};
  border: 1px solid ${p => p.theme.taskPreview.border};
  box-shadow: 0 3px 6px rgba(140, 149, 159, 0.15);
  margin-bottom: 25px;
  transition: all 0.3s ease-in-out;
  &:before {
    content: '';
    display: block;
    position: absolute;
    bottom: -8px;
    left: 0;
    right: 0;
    margin: 0 6px;
    padding: 3px;
    border: 1px solid ${({ theme }) => theme.taskPreview.border};
    border-top: none;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    background-color: ${p => p.theme.taskPreview.primary};
    box-shadow: 0 3px 6px rgba(140, 149, 159, 0.15);
  }

  ${({ color }) =>
    color &&
    css`
      border-top: 4px solid ${color};
    `}

  ${({ isDragging }) =>
    isDragging &&
    css`
      transform: scale(1.05);
    `}
`;

export const ActionTitle = styled.h4<ActionTitleProps>`
  font-weight: bold;
  flex: 1;
  margin: 0 10px 0 0;
  line-height: 16px;
  color: ${p => (p.completed ? '#58a182' : p.theme.text.primary)};
`;

export const ActionImg = styled.div`
  width: 100%;
  height: 160px;

  img {
    width: 100%;
    ${objectFitImg('cover')}
  }
`;

export const DateBlock = styled(Flex)<DateBlockProps>`
  color: ${({ theme }) => theme.color.success};

  ${({ isOverdue }) =>
    isOverdue &&
    css`
      color: #f44336;
    `}

  ${({ today }) =>
    today &&
    css`
      color: #ffab40;
    `}

  ${({ isCompleted }) =>
    isCompleted &&
    css`
      color: #90a4ae;
    `}
`;
