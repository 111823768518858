import styled, { css } from 'styled-components/macro';

type PriorityIconProps = {
  isFocused?: boolean;
  isSelected?: boolean;
};
export const StyledOption = styled.div<PriorityIconProps>`
  position: relative;
  cursor: pointer;

  ${({ isFocused }) =>
    isFocused &&
    css`
      background-color: ${({ theme }) => theme.background.hover};
    `}

  ${({ isSelected }) =>
    isSelected &&
    css`
      background-color: ${({ theme }) => theme.color.success};
      color: ${({ theme }) => theme.text.active};
    `}
`;
