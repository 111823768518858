export const boxShadow = {
  none: '0 0 #0000;',
  sm: '0 1px 2px 0 rgb(0 0 0 / 0.05);',
  md: '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);',
};

// OLD CODE
// export const boxShadowLight = {
//   extraLight: `0px 1px 0px 0px ${rgba(grayScale.gray100, 0.04)}`,
//   light: `0px 2px 4px 0px ${rgba(
//     grayScale.gray100,
//     0.04,
//   )}, 0px 0px 4px 0px ${rgba(grayScale.gray100, 0.08)}`,
//   strong: `2px 4px 16px 0px ${rgba(
//     grayScale.gray100,
//     0.12,
//   )}, 0px 2px 4px 0px ${rgba(grayScale.gray100, 0.04)}`,
//   underline: `0px 1px 0px 0px ${rgba(grayScale.gray100, 0.32)}`,
// };
//
// export const boxShadowDark = {
//   extraLight: `0px 1px 0px 0px ${rgba(grayScale.gray100, 0.04)}`,
//   light: `0px 2px 4px 0px ${rgba(
//     grayScale.gray100,
//     0.04,
//   )}, 0px 0px 4px 0px ${rgba(grayScale.gray100, 0.08)}`,
//   strong: `2px 4px 16px 0px ${rgba(
//     grayScale.gray100,
//     0.16,
//   )}, 0px 2px 4px 0px ${rgba(grayScale.gray100, 0.08)}`,
//   underline: `0px 1px 0px 0px ${rgba(grayScale.gray100, 0.32)}`,
// };
