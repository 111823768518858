import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Flex, Icon, Menu } from 'src/components/design-system';

import { useProjects } from 'src/hooks/useProjects';

import { projectMoreMMenu, PROJECT_MORE_MENU } from './constants';
import Dropdown from 'rc-dropdown';
import {
  customFieldsDrawerVar,
  openEditProjectModalVar,
} from 'src/graphql/vars';

const ProjectMore = ({ projectId }) => {
  const { t } = useTranslation();

  const { onDeleteProject } = useProjects();
  const handleMenuClick = (id: string) => {
    switch (id) {
      case PROJECT_MORE_MENU.CUSTOM_FIELDS:
        handleShowCustomFields();
        break;
      case PROJECT_MORE_MENU.EDIT_PROJECT:
        openEditProjectModalVar(true);
        break;
      case PROJECT_MORE_MENU.ARCHIVE_PROJECT:
        break;
      case PROJECT_MORE_MENU.DELETE_PROJECT:
        onDeleteProject({ projectId });
        break;
      default:
        break;
    }
  };

  const handleShowCustomFields = () => {
    customFieldsDrawerVar(true);
  };

  return (
    <Flex>
      <Dropdown
        placement="bottomRight"
        trigger={['click']}
        overlay={() => (
          <Menu options={projectMoreMMenu} handleMenuClick={handleMenuClick} />
        )}
        getPopupContainer={(trigger: HTMLElement) =>
          trigger?.parentNode as HTMLElement
        }
      >
        <Button size="little">
          <Flex padding={[0, 5, 0, 0]}>
            <Icon name="dots-vertical" size={20} />
            {t('general.more')}
          </Flex>
        </Button>
      </Dropdown>
    </Flex>
  );
};

export default ProjectMore;
