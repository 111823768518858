import React from 'react';

import { Icon, Menu } from 'src/components/design-system';

import { useCanvasBoards } from 'src/hooks/useCanvasBoards';

import { canvasCardMoreMMenu, CANVAS_CARD_MORE_MENU } from './constants';
import Dropdown from 'rc-dropdown';

import * as Styled from './styles';

type CanvasCardMoreMenuProps = {
  boardId: string;
  workspaceId: string;
};

const CanvasCardMoreMenu = ({
  boardId,
  workspaceId,
}: CanvasCardMoreMenuProps) => {
  const { onDeleteCanvasBoard } = useCanvasBoards({
    workspaceId,
  });

  const handleMenuClick = async (id: string) => {
    switch (id) {
      case CANVAS_CARD_MORE_MENU.EDIT:
        console.log('Edit');
        break;
      case CANVAS_CARD_MORE_MENU.DELETE:
        await onDeleteCanvasBoard({ boardId, workspaceId });
        break;
      default:
        break;
    }
  };

  return (
    <Dropdown
      placement="bottomLeft"
      trigger={['click']}
      overlay={() => (
        <Menu
          options={canvasCardMoreMMenu}
          handleMenuClick={(id, event) => {
            event.stopPropagation();
            handleMenuClick(id);
          }}
        />
      )}
    >
      <Styled.MoreButton
        size="small"
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <Icon name="dots-vertical" />
      </Styled.MoreButton>
    </Dropdown>
  );
};

export default CanvasCardMoreMenu;
