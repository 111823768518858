import { Flex, objectFitImg } from 'src/components/design-system';
import styled from 'styled-components';

export const Book = styled(Flex)`
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid ${({ theme }) => theme.border.color.primary};
`;

export const BookImg = styled.div`
  width: 300px;
  min-width: 300px;
  margin-right: 20px;

  img {
    ${objectFitImg('cover')}
  }
`;

export const BookInfo = styled.div`
  width: 100%;
`;

export const BookTitle = styled.h2`
  margin-top: 0;
`;
