import React from 'react';
import ContentEditable, { ContentEditableEvent } from 'react-contenteditable';

import { colorToCss } from '../utils/utils';
import { useMutation } from 'src/liveblocks.config';

import { TextLayer } from '../types';

type TextProps = {
  id: string;
  layer: TextLayer;
  onPointerDown: (
    e: React.PointerEvent<SVGForeignObjectElement>,
    id: string,
  ) => void;
  selectionColor?: string;
};

const calculateFontSize = (width: number, height: number) => {
  const maxFontSize = 96;
  const scaleFactor = 0.2;
  const fontSizeBasedOnHeight = height * scaleFactor;
  const fontSizeBasedOnWidth = width * scaleFactor;

  return Math.min(fontSizeBasedOnHeight, fontSizeBasedOnWidth, maxFontSize);
};

const Text = ({ layer, onPointerDown, id, selectionColor }: TextProps) => {
  const { x, y, width, height, fill, value } = layer;

  const updateValue = useMutation(({ storage }, newValue: string) => {
    const liveLayers = storage.get('layers');

    liveLayers.get(id)?.set('value', newValue);
  }, []);

  const handleContentChange = (e: ContentEditableEvent) => {
    updateValue(e.target.value);
  };

  return (
    <foreignObject
      x={x}
      y={y}
      width={width}
      height={height}
      onPointerDown={e => onPointerDown(e, id)}
      style={{
        outline: selectionColor ? `1px solid ${selectionColor}` : 'none',
      }}
      fill="red"
    >
      <ContentEditable
        html={value || 'Text'}
        onChange={handleContentChange}
        className={
          'h-full w-full flex items-center justify-center text-center drop-shadow-md outline-none'
          // font.className,
        }
        style={{
          fontSize: calculateFontSize(width, height),
          color: fill ? colorToCss(fill) : '#000',
        }}
      />
    </foreignObject>
  );
};

export default Text;
