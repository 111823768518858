import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Flex, Icon } from 'src/components/design-system';

import { useProjectFilter } from 'src/hooks/custom';

import ProjectMore from './ProjectMore/ProjectMore';
import ProjectTasksCount from './ProjectTasksCount/ProjectTasksCount';
import ProjectViews from './ProjectViews/ProjectViews';
import { openProjectFilterDrawer } from 'src/graphql/vars';

import * as Styled from './styles';

const SecondMenu = () => {
  const { id: projectId } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const { isFilterActive } = useProjectFilter();

  const handleOpenFilter = () => {
    openProjectFilterDrawer(true);
  };

  if (!projectId) return null;

  return (
    <Styled.Container padding={[5, 8, 5, 24]}>
      <ProjectViews />
      <Flex gap={10} pushRight>
        <ProjectTasksCount />
        <Styled.FilterButton
          isFilterActive={isFilterActive}
          onClick={handleOpenFilter}
          icon={<Icon name="filter" size={15} />}
        >
          {isFilterActive
            ? t('project.filters.titleActive')
            : t('project.filters.title')}
        </Styled.FilterButton>
        <ProjectMore projectId={projectId} />
      </Flex>
    </Styled.Container>
  );
};

export default SecondMenu;
