import { Flex } from 'src/components/design-system';
import styled from 'styled-components';

export const Container = styled(Flex)`
  border-bottom: 1px solid ${p => p.theme.border.color.primary};

  p {
    margin: 0;
  }
`;

export const Date = styled.div`
  color: ${({ theme }) => theme.color.grey500};
  font-size: 12px;
`;
