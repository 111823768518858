import { IconName } from 'src/components/design-system';

type SidebarMenu = {
  id: string;
  title: string;
  path: string;
  icon: IconName;
};

export const sidebarMenu: SidebarMenu[] = [
  {
    id: 'home',
    title: 'Home',
    path: '/',
    icon: 'home',
  },
  {
    id: 'books',
    title: 'Books',
    path: '/books',
    icon: 'book',
  },
  {
    id: 'projects',
    title: 'Projects',
    path: '/projects',
    icon: 'project',
  },
  {
    id: 'forms',
    title: 'Forms',
    path: '/forms',
    icon: 'project',
  },
  {
    id: 'mails',
    title: 'Mails',
    path: '/mails',
    icon: 'mail',
  },
  {
    id: 'miro',
    title: 'Miro',
    path: '/miro',
    icon: 'pencil',
  },
];
