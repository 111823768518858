import React from 'react';
import { useTranslation } from 'react-i18next';

import { Flex, Icon } from 'src/components/design-system';

import * as Styled from './styles';

const Menu = ({ options, handleMenuClick, clean = false }) => {
  const { t } = useTranslation();

  return (
    <Styled.Menu clean={clean}>
      {options.map(({ id, title, type, icon, iconColor, ...rest }, index) => {
        if (type === 'header') {
          return <Styled.Title key={index}>{t(title)}</Styled.Title>;
        }
        if (type === 'divider') {
          return <Styled.Divider key={index} />;
        }
        return (
          <Styled.MenuItem
            {...rest}
            key={id}
            onClick={event => {
              handleMenuClick(id, event);
            }}
          >
            <Flex gap={10} alignItems="center">
              <Icon name={icon} color={iconColor || ''} />
              {t(title)}
            </Flex>
          </Styled.MenuItem>
        );
      })}
    </Styled.Menu>
  );
};

export default Menu;
