import React from 'react';
import ContentEditable, { ContentEditableEvent } from 'react-contenteditable';

import { colorToCss, getContrastingTextColor } from '../utils/utils';
import { useMutation } from 'src/liveblocks.config';

import { NoteLayer } from '../types';

type NoteProps = {
  id: string;
  layer: NoteLayer;
  onPointerDown: (
    e: React.PointerEvent<SVGForeignObjectElement>,
    id: string,
  ) => void;
  selectionColor?: string;
};

const calculateFontSize = (width: number, height: number) => {
  const maxFontSize = 96;
  const scaleFactor = 0.2;
  const fontSizeBasedOnHeight = height * scaleFactor;
  const fontSizeBasedOnWidth = width * scaleFactor;

  return Math.min(fontSizeBasedOnHeight, fontSizeBasedOnWidth, maxFontSize);
};

const Note = ({ layer, onPointerDown, id, selectionColor }: NoteProps) => {
  const { x, y, width, height, fill, value } = layer;

  const updateValue = useMutation(({ storage }, newValue: string) => {
    const liveLayers = storage.get('layers');

    liveLayers.get(id)?.set('value', newValue);
  }, []);

  const handleContentChange = (e: ContentEditableEvent) => {
    updateValue(e.target.value);
  };

  return (
    <foreignObject
      x={x}
      y={y}
      width={width}
      height={height}
      onPointerDown={e => onPointerDown(e, id)}
      style={{
        filter:
          'drop-shadow(0 4px 3px rgb(0 0 0 / 0.07)) drop-shadow(0 2px 2px rgb(0 0 0 / 0.06)',
        outline: selectionColor ? `1px solid ${selectionColor}` : 'none',
        backgroundColor: fill ? colorToCss(fill) : '#000',
      }}
    >
      <ContentEditable
        html={value || 'Text'}
        onChange={handleContentChange}
        className={
          'h-full w-full flex items-center justify-center text-center outline-none'
        }
        style={{
          fontSize: calculateFontSize(width, height),
          color: fill ? getContrastingTextColor(fill) : '#000',
        }}
      />
    </foreignObject>
  );
};

export default Note;
