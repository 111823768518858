import React from 'react';
import { OptionProps } from 'react-select';

import { Flex } from 'src/components/design-system';

import { PRIORITY } from 'src/common/constants';

import Option from '../Option/Option';
import PriorityIcon from './PriorityIcon';

const PriorityOption = ({
  data,
  innerRef,
  innerProps,
  isFocused,
  isSelected,
}: OptionProps) => {
  // @ts-ignore
  const { label, value } = data;

  return (
    <Option
      ref={innerRef}
      isFocused={isFocused}
      isSelected={isSelected}
      {...innerProps}
    >
      <Flex padding={10} alignItems="center" gap={10}>
        {value !== PRIORITY.NONE && <PriorityIcon {...(data as any)} />}
        <div>{label}</div>
      </Flex>
    </Option>
  );
};

export default PriorityOption;
