import { Flex } from 'src/components/design-system';
import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
`;

export const TableHeadCell = styled(Flex)`
  border-bottom: 1px solid ${({ theme }) => theme.border.color.primary};
  border-right: 1px solid ${({ theme }) => theme.border.color.primary};
  margin: 0;
  text-align: left;
  height: 50px;
  width: 100%;
  position: relative;
  cursor: grab;
  padding: 6px 12px;
  background: ${({ theme }) => theme.background.secondary};
`;

type ResizerProps = {
  isResizing: boolean;
};

export const Resizer = styled.div<ResizerProps>`
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 10px;
  background-color: #8ecae6;
  cursor: col-resize;
  user-select: none;
  touch-action: none;
  transform: translateX(50%);
  opacity: 0;
  z-index: 1;
  &:hover {
    opacity: 1;
  }
`;
