import { gql } from '@apollo/client';

export const ADD_COMMENT_SUBSCRIPTION = gql`
  subscription onCreateComment {
    onCreateComment {
      _id
      text
      type
      sticker
      createdAt
      taskId
      reactions {
        id
        emoji
        user {
          _id
          fullName
        }
      }
      user {
        _id
        fullName
        profilePicture
      }
    }
  }
`;

export const DELETE_COMMENT_SUBSCRIPTION = gql`
  subscription onDeleteComment {
    onDeleteComment {
      _id
    }
  }
`;
