import styled from 'styled-components/macro';

export const InputWrapper = styled.div`
  width: 70%;
  max-width: 600px;
  background: ${({ theme }) => theme.background.secondary};
  border-radius: ${({ theme }) => theme.border.radius.md};
  input {
    background: ${({ theme }) => theme.background.secondary};
  }
`;

export const Container = styled.div`
  position: relative;
  width: 800px;
  top: 4px;
  left: 50%;
  transform: translateX(-300px);
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 1200px) {
    width: 500px;
    transform: translateX(-150px);
  }
`;

export const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;
