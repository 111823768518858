import { gql } from '@apollo/client';

gql`
  mutation UpdateTimeTracking(
    $actionId: String!
    $timeTrackingId: String
    $operation: TimeTrackingOperation!
  ) {
    updateTimeTracking(
      input: {
        actionId: $actionId
        timeTrackingId: $timeTrackingId
        operation: $operation
      }
    ) {
      _id
    }
  }
`;
