import { Flex } from 'src/components/design-system';
import styled from 'styled-components';

export const Container = styled.div`
  max-width: 722px;
  width: 40%;
`;

export const AudioControls = styled(Flex)`
  margin-bottom: 10px;
`;
