import React from 'react';

import { ToolbarButton } from './styles';

const Button = props => {
  const { children, format, active, ...rest } = props;

  return (
    <ToolbarButton active={active} title={format} {...rest}>
      {children}
    </ToolbarButton>
  );
};

export default Button;
