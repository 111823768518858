import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';

import { Flex, Icon } from 'src/components/design-system';

import { projectViews } from './constants';

import { MenuLink } from './styles';

const ProjectViews = () => {
  const { t } = useTranslation();
  const { id: projectId } = useParams<{ id: string }>();
  const [searchParams] = useSearchParams();

  const viewType = searchParams.get('view');

  return (
    <Flex alignItems="center" padding={0}>
      {projectViews.map(({ id, icon }) => (
        <MenuLink
          key={id}
          to={`/projects/${projectId}?view=${id}`}
          selected={viewType === id}
        >
          <Icon name={icon as any} />
          {t(`project.${id}.title`)}
        </MenuLink>
      ))}
    </Flex>
  );
};

export default ProjectViews;
