import React, { forwardRef } from 'react';

import TaskPreview from '../../../../pages/Project/components/Board/Action/TaskPreview/TaskPreview';
import { LoadingIndicator } from 'src/components/design-system';

import * as Styled from './styles';

// Define the props if there are any, or leave it as an empty object if none
interface SearchDropdownProps {
  tasks: any[];
  loading: boolean;
}

// Use forwardRef and specify the type for the ref
const SearchDropdown = forwardRef<HTMLDivElement, SearchDropdownProps>(
  ({ tasks, loading }, ref) => {
    return (
      <Styled.Dropdown ref={ref}>
        <strong>{'Tasks'}</strong>

        {loading && (
          <div>
            <LoadingIndicator />
          </div>
        )}

        {!loading && tasks.length === 0 ? (
          <div>
            {
              'Looking for something? Search above, adjust the filters to the left or try our advanced search functionality'
            }
          </div>
        ) : (
          <Styled.GridContainer>
            {tasks.map(task => (
              <TaskPreview key={task.id} task={task} />
            ))}
          </Styled.GridContainer>
        )}
      </Styled.Dropdown>
    );
  },
);

export default SearchDropdown;
