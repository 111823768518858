import React from 'react';

import { useReactiveVar } from '@apollo/client';

import { Drawer } from 'src/components/design-system';

import CustomFields from './CustomFields';
import { customFieldsDrawerVar } from 'src/graphql/vars';

const CustomFieldsDrawer = () => {
  const isOpenCustomFieldsDrawer = useReactiveVar(customFieldsDrawerVar);

  return (
    <Drawer
      width={400}
      open={isOpenCustomFieldsDrawer}
      onClose={() => customFieldsDrawerVar(false)}
    >
      <CustomFields />
    </Drawer>
  );
};

export default CustomFieldsDrawer;
