import React, { memo } from 'react';

import Path from '../LayerPreview/Path';
import { colorToCss } from '../utils/utils';
import Cursor from './Cursor';
import { shallow } from '@liveblocks/client';
import { useOthersConnectionIds, useOthersMapped } from 'src/liveblocks.config';

const Cursors = () => {
  const ids = useOthersConnectionIds();

  return (
    <>
      {ids.map(connectionId => (
        <Cursor key={connectionId} connectionId={connectionId} />
      ))}
    </>
  );
};

const Drafts = () => {
  const others = useOthersMapped(
    other => ({
      pencilDraft: other.presence.pencilDraft,
      penColor: other.presence.penColor,
    }),
    shallow,
  );

  return (
    <>
      {others.map(([key, other]) => {
        if (other.pencilDraft) {
          return (
            <Path
              key={key}
              x={0}
              y={0}
              points={other.pencilDraft}
              fill={other.penColor ? colorToCss(other.penColor) : '#000'}
            />
          );
        }

        return null;
      })}
    </>
  );
};

const CursorPresence = () => {
  return (
    <>
      <Drafts />
      <Cursors />
    </>
  );
};

export default memo(CursorPresence);
