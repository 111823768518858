import { useReactiveVar } from '@apollo/client';

import { Drawer } from 'src/components/design-system';

import NotificationsList from './NotificationsList';
import { openNotifications } from 'src/graphql/vars';

const Notifications = () => {
  const isOpenNotifications = useReactiveVar(openNotifications);

  if (!isOpenNotifications) return null;

  return (
    <Drawer
      open={isOpenNotifications}
      width={450}
      showCloseIcon={false}
      mask={false}
      onClose={() => openNotifications(false)}
    >
      <NotificationsList />
    </Drawer>
  );
};

export default Notifications;
