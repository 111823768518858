import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useReactiveVar } from '@apollo/client';

import {
  Button,
  Colors,
  FormItem,
  Modal,
  Flex,
} from 'src/components/design-system';

import { useStatuses } from 'src/hooks/useStatuses';

import { editStatusVar } from 'src/graphql/vars';

import * as Styled from './styles';

type EditStatusProps = {
  title: string;
  color: string;
};

const EditStatusModal = () => {
  const { t } = useTranslation();
  const editStatus = useReactiveVar(editStatusVar);
  const { onUpdateStatus } = useStatuses();

  const methods = useForm<EditStatusProps>();

  useEffect(() => {
    if (editStatus?._id) {
      methods.setValue('title', editStatus.title);
      methods.setValue('color', editStatus.color);
    }
  }, [editStatus, methods]);

  const { handleSubmit, reset, setValue } = methods;

  const handleCreateProject = data => {
    onUpdateStatus({
      statusId: editStatus?._id!,
      color: data.color,
      title: data.title,
    });
    onClose();
  };

  const onClose = () => {
    editStatusVar(null);
    reset();
  };

  return (
    <Modal isOpen={!!editStatus?._id} width={400} onRequestClose={onClose}>
      <Styled.Title>{t('project.statuses.edit')}</Styled.Title>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(handleCreateProject)}>
          <FormItem name="title" label="Status name">
            <input type="text" placeholder="Enter project name" />
          </FormItem>

          <FormItem name="color" label="Status color">
            <Colors
              defaultColor={editStatus?.color as any}
              onChange={color => {
                setValue('color', color);
              }}
            />
          </FormItem>

          <Flex justifyContent="flex-end" gap={10} padding={[20, 0, 0]}>
            <Button onClick={onClose}>{t('general.close')}</Button>
            <Button htmlType="primary">{t('general.submit')}</Button>
          </Flex>
        </form>
      </FormProvider>
    </Modal>
  );
};

export default EditStatusModal;
