import { Button } from 'src/components/design-system';
import styled from 'styled-components';

export const Container = styled.div`
  flex: 0 0 290px;
  margin: 5px 0;
  border-radius: ${({ theme }) => theme.border.radius.sm};
`;

export const AddColumnButton = styled(Button)`
  padding: 10px;
  font-weight: bold;
`;
