import styled from 'styled-components';

export const Container = styled.div`
  margin-bottom: 10px;
  .react-datepicker__day--today {
    background-color: transparent;
    font-weight: bold;
    color: #111111;
  }
`;

export const Title = styled.h4`
  margin: 0;
`;
