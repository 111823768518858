import React from 'react';
import { Helmet } from 'react-helmet-async';

import MainLayout from 'src/layouts/MainLayout/MainLayout';

const Mails = () => {
  return (
    <>
      <Helmet>
        <title>{'mails'}</title>
        <meta name="Mails" content="Mails" />
      </Helmet>
      <MainLayout>{'Coming soon'}</MainLayout>
    </>
  );
};

export default Mails;
