import { useSpring, animated } from '@react-spring/web';
import React from 'react';

import { Icon } from 'src/components/design-system';

import { NotificationProps } from './types';

import { NoticeItem, NoticeContent, NoticeClose, NoticeTitle } from './styles';

export const Notification = ({ notification, remove }: NotificationProps) => {
  const { id, title, message, variant } = notification;

  const styles = useSpring({
    from: { transform: 'translateX(200px)' },
    to: [{ transform: 'translateX(0px)' }],
    config: { duration: 300 },
    loop: false,
  });

  return (
    <NoticeItem as={animated.div} variant={variant} style={styles}>
      <NoticeContent>
        {title && <NoticeTitle>{title}</NoticeTitle>}
        {message}
      </NoticeContent>
      <NoticeClose size="small" onClick={() => remove(id)}>
        <Icon name="close" />
      </NoticeClose>
    </NoticeItem>
  );
};
