import React from 'react';
import { useSlateStatic, useReadOnly, ReactEditor } from 'slate-react';

import { Transforms } from 'slate';

const Checkbox = ({ attributes, children, element }) => {
  const editor = useSlateStatic();
  const readOnly = useReadOnly();
  const { checked } = element;
  return (
    <div {...attributes}>
      <span contentEditable={false} className="checklist-item-block">
        <label>
          <input
            className="checklist-item-input"
            type="checkbox"
            checked={checked}
            onChange={event => {
              const path = ReactEditor.findPath(editor, element);

              Transforms.setNodes(
                editor,
                { checked: event.target.checked },
                { at: path },
              );
            }}
          />
          <span className="checklist-item-custom-input">
            <svg viewBox="0 0 24 24">
              <polyline points="20 6 9 17 4 12" />
            </svg>
          </span>
        </label>
      </span>
      <span
        contentEditable={!readOnly}
        suppressContentEditableWarning
        className={`checklist-item-text ${checked ? 'checked' : ''}`}
      >
        {children}
      </span>
    </div>
  );
};

export default Checkbox;
