import styled from 'styled-components/macro';

export const DropdownContainer = styled.div`
  top: -999px;
  left: -999px;
  position: absolute;
  z-index: 100000;
  background: ${({ theme }) => theme.background.secondary};
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.border.color.primary};
  padding: 10px;
  width: 200px;
`;

type DropdownItemProps = {
  selected?: boolean;
};

export const DropdownItem = styled.div<DropdownItemProps>`
  background: ${({ selected, theme }) =>
    selected ? theme.background.selected : theme.background.secondary};
  padding: 5px 10px;
  border-radius: 2px;
  &:hover {
    background: ${({ theme }) => theme.background.selected};
    cursor: pointer;
  }
`;
