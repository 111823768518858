import { sortBy } from 'lodash';
import { isEmpty } from 'lodash';

type OrderArrayProps = {
  array: {
    _id: string;
  }[];
  order: string[];
};

export const orderArray = ({ array, order }: OrderArrayProps) => {
  if (isEmpty(order)) {
    return array;
  }

  return sortBy(array, item => order.indexOf(item._id));
};

export const reorderArray = (
  list: string[],
  startIndex: number,
  endIndex: number,
) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

function addOrReplaceBy<T>(array: T[], item: T, keys: (keyof T)[]): T[] {
  const index = array.findIndex(existingItem =>
    keys.every(key => existingItem[key] === item[key]),
  );

  if (index !== -1) {
    // If found, replace the existing item
    array[index] = item;
  } else {
    // If not found, add the new item
    array.push(item);
  }

  return array;
}

export { addOrReplaceBy };
