import React from 'react';
import { useParams } from 'react-router-dom';

import { Flex, Skeleton, Icon } from 'src/components/design-system';
import { useTheme } from 'styled-components';

import {
  useProjectTasksCountQuery,
  useProjectTasksCountSubSubscription,
  ProjectTasksCountDocument,
} from 'src/generated';

const ProjectTasksCount = () => {
  const theme = useTheme();
  const { id: projectId } = useParams() as never;

  const { data, loading, error } = useProjectTasksCountQuery({
    variables: {
      projectId,
    },
  });

  useProjectTasksCountSubSubscription({
    variables: { projectId },
    onSubscriptionData: ({ client, subscriptionData }) => {
      if (!subscriptionData.data) return;
      const { projectTasksCount } = subscriptionData.data;

      client.writeQuery({
        query: ProjectTasksCountDocument,
        variables: { projectId },
        data: {
          taskCounts: projectTasksCount,
        },
      });
    },
  });

  if (loading) return <Skeleton width={200} height={30} />;
  if (error) return null;

  const { incompleteTasks, completedTasks, archivedTasks, deletedTasks } =
    data?.taskCounts || {};

  return (
    <Flex alignItems="center" gap={10}>
      <Flex alignItems="center" gap={6}>
        <Icon name="circle-check-filled" color={theme.color.success} />
        {completedTasks}
      </Flex>
      <Flex alignItems="center" gap={4}>
        <Icon name="archive" color={theme.color.grey500} />
        {archivedTasks}
      </Flex>
      <Flex alignItems="center" gap={4}>
        <Icon name="trash" color={theme.color.red300} />
        {deletedTasks}
      </Flex>
      <div>/ {incompleteTasks}</div>
    </Flex>
  );
};

export default ProjectTasksCount;
