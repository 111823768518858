import styled, { css } from 'styled-components/macro';

import { ButtonProps } from './Button';

interface IIcon {
  mr: boolean;
}
const buttonSize = ({ size }: { size?: ButtonProps['size'] }) => {
  switch (size) {
    case 'little':
      return { padding: '2px' };

    case 'small':
      return { padding: '4px 7px' };

    case 'medium':
      return { padding: '9.5px 16px' };

    case 'large':
      return { padding: '13.5px 16px' };

    default:
      return { padding: '4px 7px' };
  }
};

export const Container = styled.button<
  Pick<
    ButtonProps,
    'size' | 'active' | 'htmlType' | 'disabled' | 'danger' | 'ref'
  >
>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border-radius: ${({ theme }) => theme.border.radius.sm};
  cursor: pointer;
  min-width: 22px;
  position: relative;
  color: ${p => p.theme.text.primary};
  border: 1px solid ${p => p.theme.border.color.primary};
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:hover {
    background: ${p => p.theme.background.primary};
  }

  ${({ fullWidth }: ButtonProps) =>
    fullWidth &&
    css`
      width: 100%;
      // TODO fix this
      justify-content: flex-start;
    `}

  ${({ danger }: ButtonProps) =>
    danger &&
    css`
      border: 1px solid #ff4d4f;
      color: #ff4d4f;
    `}

  ${({ htmlType }: ButtonProps) =>
    htmlType === 'primary' &&
    css`
      background-color: ${({ theme }) => theme.color.success};
      border: none;
      color: #fff;

      :hover,
      :focus {
        border: none;
        color: #fff;
        box-shadow: -8px 2px 22px -7px rgba(0, 0, 0, 0.25);
        background-color: ${({ theme }) => theme.color.success};
      }
    `}


  ${({ htmlType }: ButtonProps) =>
    htmlType === 'text' &&
    css`
      border: none;
      padding: 4px 6px;
      :hover,
      :focus {
        border: none;
        box-shadow: none;
        background-color: ${({ theme }) => theme.background.hover};
      }
    `}



  ${({ htmlType, underline }: ButtonProps) =>
    htmlType === 'link' &&
    !underline &&
    css`
      :hover,
      :focus {
        text-decoration: none;
      }
    `}

  ${({ circle }: ButtonProps) =>
    circle &&
    css`
      border-radius: 50%;
      border: 1px solid ${p => p.theme.border.color.primary};
      padding: 8px;
      &:hover,
      &:focus {
        border: 1px solid ${p => p.theme.border.color.primary};
      }
    `}

  ${({ htmlType }: ButtonProps) =>
    htmlType === 'link' &&
    css`
      background-color: transparent;
      border: none;
      color: #5590ea;
      text-decoration: none;
      font-weight: 500;

      :hover,
      :focus {
        border: none;
        box-shadow: none;
        background-color: transparent;
        text-decoration: underline;
        color: #5590ea;
      }
    `}

  // active state
  ${({ active }: ButtonProps) =>
    active &&
    css`
      background-color: ${({ theme }) => theme.button.active.background};
      border-color: ${({ theme }) => theme.button.active.background};
      color: ${({ theme }) => theme.button.active.color};

      &:hover {
        background-color: ${({ theme }) => theme.button.active.background};
        border-color: ${({ theme }) => theme.button.active.background};
      }
    `}

  // disabled state
  ${({ disabled }: ButtonProps) =>
    disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.6;
    `}

  ${buttonSize}
`;

export const Icon = styled.span`
  ${(props: IIcon) =>
    props.mr &&
    css`
      margin-right: 10px;
    `}
`;

export const Loading = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: inherit;
  background: inherit;
`;
