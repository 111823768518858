import React, { useEffect } from 'react';

import OpenAI from 'openai';

const openai = new OpenAI({
  apiKey: 'sk-proj-zlBHLFYf3CD1XGtWLe4bT3BlbkFJcpVNy4dnh5DsjWpRp3Uo',
  dangerouslyAllowBrowser: true,
});

const OpenAID = () => {
  const start = async () => {
    const response = await openai.chat.completions.create({
      messages: [
        {
          role: 'system',
          content: 'You are a helpful assistant.',
        },
        {
          role: 'user',
          content: 'What is the fastest bird?',
        },
      ],
      model: 'gpt-3.5-turbo',
      temperature: 0,
    });

    console.log(response.choices[0].message);
    console.log(response);

    return response;
  };

  useEffect(() => {
    start();
  }, []);

  return (
    <div>
      <h1>{'TYTY'}</h1>
    </div>
  );
};

export default OpenAID;
