import escapeHTML from 'escape-html';
import { Node, Text } from 'slate';

const matchBoldNode = (n: Node): boolean => {
  // @ts-ignore
  return !!n.bold;
};
const matchInlineActionNode = (n: Node): boolean => {
  // @ts-ignore
  return !!n.inlineAction;
};

const serialize = (node: Node) => {
  if (Text.isText(node)) {
    // @ts-ignore
    const string = escapeHTML(node.text);
    if (matchBoldNode(node)) {
      return `<b>${string}</b>`;
    }
    if (matchInlineActionNode(node)) {
      return `<span>${string}</span>`;
    }
    return string;
  }

  const children: string = node.children
    ?.map(n => serialize(n))
    .join('') as string;

  // @ts-ignore
  if (node.type === 'code') {
    return `<pre>${children}</pre>`;
  }
  // @ts-ignore
  if (node.type === 'paragraph') {
    return `<p>${children}</p>`;
  }
  return children;
};

export const serializeNodes = (nodes: Node[]) => {
  return nodes.map(node => serialize(node)).join('');
};
