import React from 'react';

import { Button, Flex, Icon } from 'src/components/design-system';

import { CanvasMode, CanvasState, LayerType } from '../types';

import * as Styled from './styles';

type ToolbarProps = {
  canvasState: CanvasState;
  setCanvasState: (newState: CanvasState) => void;
  undo: () => void;
  redo: () => void;
  canUndo: boolean;
  canRedo: boolean;
  savePreviewImage: () => void;
};

const Toolbar = ({
  canvasState,
  setCanvasState,
  undo,
  redo,
  canUndo,
  canRedo,
  savePreviewImage,
}: ToolbarProps) => {
  return (
    <Styled.Toolbar>
      <Flex direction="column" gap={10}>
        <Button
          onClick={() => {
            setCanvasState({ mode: CanvasMode.None });
          }}
          active={
            canvasState.mode === CanvasMode.None ||
            canvasState.mode === CanvasMode.Translating ||
            canvasState.mode === CanvasMode.SelectionNet ||
            canvasState.mode === CanvasMode.Pressing ||
            canvasState.mode === CanvasMode.Resizing
          }
        >
          <Icon name="select" />
        </Button>
        <Button
          onClick={() => {
            setCanvasState({
              mode: CanvasMode.Inserting,
              layerType: LayerType.Text,
            });
          }}
          active={
            canvasState.mode === CanvasMode.Inserting &&
            canvasState.layerType === LayerType.Text
          }
        >
          <Icon name="italic" />
        </Button>
        <Button
          onClick={() => {
            setCanvasState({
              mode: CanvasMode.Inserting,
              layerType: LayerType.Note,
            });
          }}
          active={
            canvasState.mode === CanvasMode.Inserting &&
            canvasState.layerType === LayerType.Note
          }
        >
          <Icon name="note" />
        </Button>
        <Button
          onClick={() => {
            setCanvasState({
              mode: CanvasMode.Inserting,
              layerType: LayerType.Rectangle,
            });
          }}
          active={
            canvasState.mode === CanvasMode.Inserting &&
            canvasState.layerType === LayerType.Rectangle
          }
        >
          <Icon name="square" />
        </Button>
        <Button
          onClick={() => {
            setCanvasState({
              mode: CanvasMode.Inserting,
              layerType: LayerType.Ellipse,
            });
          }}
          active={
            canvasState.mode === CanvasMode.Inserting &&
            canvasState.layerType === LayerType.Ellipse
          }
        >
          <Icon name="circle" />
        </Button>
        <Button
          onClick={() => {
            setCanvasState({
              mode: CanvasMode.Pencil,
            });
          }}
          active={canvasState.mode === CanvasMode.Pencil}
        >
          <Icon name="pencil" />
        </Button>
        <br />
        <Button onClick={undo} disabled={!canUndo}>
          <Icon name="undo" />
        </Button>
        <Button onClick={redo} disabled={!canRedo}>
          <Icon name="redo" />
        </Button>
        <Button onClick={savePreviewImage}>
          <Icon name="save" />
        </Button>
      </Flex>
    </Styled.Toolbar>
  );
};

export default Toolbar;
