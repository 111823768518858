import React from 'react';

import { getAvatarFirstLetter } from 'src/helpers/string';

import { User } from 'src/generated';

import { ImageBlock, PreviewBlock } from './styles';

type AvatarProps = {
  user: Pick<User, 'profilePicture' | 'fullName'>;
  size?: number;
  mr?: number;
  radius?: number;
};
const Avatar = ({ user, size, mr = 10, radius }: AvatarProps) => {
  const { profilePicture, fullName } = user;

  if (profilePicture) {
    return (
      <ImageBlock mr={mr} size={size} radius={radius}>
        <img src={profilePicture} alt={fullName} />
      </ImageBlock>
    );
  }

  return (
    <PreviewBlock mr={mr} size={size} radius={radius}>
      {getAvatarFirstLetter(fullName)}
    </PreviewBlock>
  );
};

export default Avatar;
