import React from 'react';

import { Select } from 'src/components/design-system';
import PriorityControl from 'src/components/design-system/Select/components/priority/PriorityControl';
import PriorityOption from 'src/components/design-system/Select/components/priority/PriorityOption';

import { useTask } from 'src/hooks/useTask';

import { priorities } from 'src/common/constants';

import * as Styled from './styles';

const EditPriorityDropdown = ({
  id,
  rowIndex,
  taskId,
  priority,
  updateData,
}) => {
  const { onUpdateTask } = useTask();

  return (
    <Styled.Wrapper>
      <Select
        menuIsOpen
        classNamePrefix="table-priority"
        isSearchable={false}
        options={priorities}
        value={priority ? priority : { value: null, label: 'None' }}
        onChange={({ value }) => {
          onUpdateTask({
            taskId,
            priority: value,
          });
          updateData(rowIndex, id, value);
        }}
        components={{
          Control: PriorityControl,
          Option: PriorityOption,
          ClearIndicator: undefined,
          DropdownIndicator: undefined,
        }}
      />
    </Styled.Wrapper>
  );
};

export default EditPriorityDropdown;
