import React, { useContext } from 'react';

import { Flex, Button } from 'src/components/design-system';

import FormContext from '../FormContext';

const FormCardSettings = ({ element }) => {
  const { name } = element;
  const {
    state: { elements },
    dispatch,
  } = useContext(FormContext);

  const handleDuplicateField = () => {};

  const handleDeleteField = () => {
    dispatch({
      elements: elements.filter(element => element.name !== name),
    });
  };

  return (
    <Flex justifyContent="flex-end" gap={10}>
      <Button htmlType="text" onClick={handleDuplicateField}>
        {'Duplicate'}
      </Button>
      <Button htmlType="text" onClick={handleDeleteField}>
        {'Delete'}
      </Button>
    </Flex>
  );
};

export default FormCardSettings;
