import styled from 'styled-components';

export const NoAssigneeIcon = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px dashed #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const AssigneeFullName = styled.span`
  margin-left: 10px;
  font-weight: 500;
`;
