import React, { useContext } from 'react';

import { Icon, IconName } from 'src/components/design-system';

import FormContext from '../../FormContext';
import { formElements } from './constants';

import * as Styled from './styles';

const FormElements = () => {
  const {
    state: { elements },
    dispatch,
  } = useContext(FormContext);

  const count = elements.length + 1;
  const handleElementClick = ({ type }) => {
    const newField = {
      type,
      name: `question${count}`,
      title: '',
      description: '',
      required: false,
    };

    dispatch({ elements: [...elements, newField] });
  };

  console.log(elements, 'elements');

  return (
    <Styled.Container>
      {formElements.map(({ title, icon, type }) => (
        <Styled.Item key={title} onClick={() => handleElementClick({ type })}>
          <Styled.ItemInner padding={[12, 18]} alignItems="center" gap={10}>
            <Icon name={icon as IconName} />
            <Styled.Title>{title}</Styled.Title>
          </Styled.ItemInner>
        </Styled.Item>
      ))}
    </Styled.Container>
  );
};

export default FormElements;
