export const CANVAS_CARD_MORE_MENU = {
  EDIT: 'edit',
  DELETE: 'delete',
};

export const canvasCardMoreMMenu = [
  {
    id: CANVAS_CARD_MORE_MENU.EDIT,
    title: 'miro.moreBtn.editBoard',
    icon: 'pencil',
    disabled: true,
  },
  {
    type: 'divider',
  },
  {
    id: CANVAS_CARD_MORE_MENU.DELETE,
    title: 'miro.moreBtn.deleteBoard',
    icon: 'trash',
    danger: true,
  },
];
