import React, { useState } from 'react';

import { Avatar, Flex, Tooltip } from 'src/components/design-system';

import { useQueryParams } from 'src/hooks/useQueryParams';
import { useTask } from 'src/hooks/useTask';

import { useProject } from 'src/helpers/apollo';

import AssigneeList from './AssigneeList/AssigneeList';
import AssigneePreview from './AssigneePreview/AssigneePreview';
import NoAssignee from './NoAssignee/NoAssignee';
import Dropdown from 'rc-dropdown';

import { AssigneeFullName } from './styles';

type TaskAssigneeProps = {
  taskId?: string;
  assignee: any;
  compact?: boolean;
  isCalendar?: boolean;
};

const TaskAssignee = ({
  taskId,
  assignee,
  compact = false,
  isCalendar,
}: TaskAssigneeProps) => {
  const query = useQueryParams();
  const currentTaskId = taskId || (query.get('taskId') as string);
  const [isOpenAssigneeList, setIsOpenAssigneeList] = useState(false);
  const { onUpdateTask } = useTask();

  const {
    project: { members },
  } = useProject();

  const handleUpdateAssignee = async ({ e, assigneeId }) => {
    e.stopPropagation();
    setIsOpenAssigneeList(false);

    // prevent update if assignee is the same
    if (assigneeId === assignee?._id) return;
    try {
      onUpdateTask({
        taskId: currentTaskId,
        assigneeId,
      });
    } catch (error) {}
  };

  const handleOpenAssignee = e => {
    e.stopPropagation();
    setIsOpenAssigneeList(true);
  };

  const shouldShowAssigneePreview = !isOpenAssigneeList && assignee?.fullName;
  const avatarSize = isCalendar ? 24 : taskId ? 32 : 36;

  return (
    <Flex alignItems="center">
      <Dropdown
        visible={isOpenAssigneeList}
        trigger={['click']}
        overlay={
          <AssigneeList
            members={members}
            handleUpdateAssignee={handleUpdateAssignee}
          />
        }
        getPopupContainer={() => document.body}
        onVisibleChange={setIsOpenAssigneeList}
      >
        <Tooltip
          mouseEnterDelay={0.5}
          overlay={
            shouldShowAssigneePreview && <AssigneePreview assignee={assignee} />
          }
          placement="right"
          showArrow={false}
          overlayClassName="custom-tooltip"
        >
          <Flex alignItems="center" onClick={handleOpenAssignee}>
            {assignee ? (
              <>
                <Avatar user={assignee} size={avatarSize} mr={0} />
                {!compact && (
                  <AssigneeFullName>{assignee.fullName}</AssigneeFullName>
                )}
              </>
            ) : (
              <NoAssignee compact={compact} />
            )}
          </Flex>
        </Tooltip>
      </Dropdown>
    </Flex>
  );
};

export default TaskAssignee;
