import { Flex } from 'src/components/design-system';
import styled from 'styled-components';

export const TableRow = styled(Flex)`
  transition: background-color 0.3s;
  background: ${({ theme }) => theme.background.primary};
  &:hover {
    background: ${({ theme }) => theme.task.secondary};
    > div {
      background: ${({ theme }) => theme.task.secondary};
    }
  }
`;
