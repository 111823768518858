import { css } from 'styled-components';

export const CkeditorStyle = css`
  .ck.ck-editor__editable.ck-focused:not(.ck-editor__nested-editable) {
    border-color: transparent;
    box-shadow: none;
  }

  .ck.ck-balloon-panel.ck-powered-by-balloon[class*='position_border'] {
    display: none;
  }
  .ck-link_selected {
    color: ${({ theme }) => theme.color.primary};
  }
`;
