import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { LoadingIndicator, File } from 'src/components/design-system';

import FileModal from '../FileModal/FileModal';

import { useFilesQuery } from 'src/generated';

import { Container, FileCard } from './styles';

const Files = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [initialSlide, setInitialSlide] = useState(0);
  const { data, loading, error, refetch } = useFilesQuery({
    variables: { projectId: id! },
  });

  useEffect(() => {
    refetch();
  }, [refetch]);

  if (loading || !data) return <LoadingIndicator />;
  if (error) return <p>{'Error :('}</p>;

  const { files } = data;

  const handleCloseModal = () => {
    setIsOpenModal(false);
  };

  const handleOpenModal = index => {
    setInitialSlide(index);
    setIsOpenModal(true);
  };

  if (!files.length) {
    return (
      <Container>
        <p>{t('project.files.noFiles')}</p>
      </Container>
    );
  }

  return (
    <Container>
      {files.map((file, index) => (
        <FileCard key={file._id} onClick={() => handleOpenModal(index)}>
          <File key={file._id} {...file} />
        </FileCard>
      ))}
      <FileModal
        files={files}
        isOpen={isOpenModal}
        onRequestClose={handleCloseModal}
        initialSlide={initialSlide}
        setInitialSlide={setInitialSlide}
      />
    </Container>
  );
};

export default Files;
