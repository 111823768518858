import styled from 'styled-components/macro';

export const SwitchLabel = styled.label`
  display: flex;
  align-items: flex-start;
  gap: 4px;

  input {
    margin: 0;
    appearance: none;
    min-width: 50px;
    min-height: 30px;
    position: relative;
    border-radius: 32px;
    background-color: ${({ theme }) => theme.switch.secondary};
    border: 1px solid ${({ theme }) => theme.border.color.primary};

    cursor: pointer;
    user-select: none;
    transition: background-color 0.3s, border-color 0.3s;

    &:checked {
      background-color: ${({ theme }) => theme.color.success};
      border-color: ${({ theme }) => theme.color.success};

      &::before {
        left: unset;
        transform: translateX(calc(100% - 2px));
      }

      &::after {
        display: none;
      }
    }

    &::before {
      content: '';
      position: absolute;
      height: 24px;
      width: 24px;
      top: 2px;
      left: 2px;
      bottom: 2px;
      background-color: ${({ theme }) => theme.switch.primary};
      transition: all 0.3s;
      border-radius: 50%;
      cursor: pointer;
      z-index: 1;
      box-shadow: 0 0 1px 0 rgb(0 0 0 / 25%), 0 4px 11px 0 rgb(0 0 0 / 8%),
        -1px 3px 3px 0 rgb(0 0 0 / 14%);
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      font-size: 10px;
      line-height: 1;
      top: 1px;
      right: 1px;
      bottom: 1px;
      left: 1px;
      background: ${({ theme }) => theme.switch.secondary}
      border-radius: 15px;
    }
  }
`;
