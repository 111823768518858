import React from 'react';

import * as Styled from './styles';

const ContentEditable = ({ value, onBlur }) => {
  const handleChange = event => {
    console.log(event.target.innerHTML);
  };
  const handleBlur = event => {
    console.log(event.target.innerHTML);
    onBlur(event.target.innerHTML);
  };

  return (
    <Styled.Editable
      contentEditable
      suppressContentEditableWarning
      onChange={handleChange}
      onBlur={handleBlur}
    >
      {value}
    </Styled.Editable>
  );
};

export default ContentEditable;
