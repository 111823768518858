import React from 'react';
import { useParams } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import DateCustomField from './DateCustomField/DateCustomField';
import TextCustomField from './TextCustomField/TextCustomField';
import { GET_TASK_CUSTOM_FIELDS } from 'src/graphql/task-custom-fields';
import { useUserWorkspace } from 'src/store';

const TaskCustomFields = ({ action }) => {
  const workspaceId = useUserWorkspace();
  const { id: projectId } = useParams() as any;
  // const customFields = useCustomFields();

  const { data, loading, error } = useQuery(GET_TASK_CUSTOM_FIELDS, {
    variables: {
      workspaceId,
      taskId: action._id,
      projectId,
      projectIds: [projectId],
    },
    // TODO: check if we need to use cache-and-network
    // fetchPolicy: 'cache-and-network',
  });

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error...</div>;

  const { customFields, taskCustomFields } = data;

  const mergedCustomFields = customFields.map(({ _id, title, type }) => {
    const currentTaskCustomFieldValue = taskCustomFields.find(
      (item: any) => item.customField._id === _id,
    )?.value;

    return {
      _id,
      type,
      title,
      value: currentTaskCustomFieldValue,
    };
  });

  const renderCustomField = customField => {
    const { type } = customField;

    switch (type) {
      case 'text':
        return <TextCustomField customField={customField} />;
      case 'date':
        return <DateCustomField customField={customField} />;
      default:
        return null;
    }
  };

  return (
    <div>
      {mergedCustomFields.map(customField => {
        return (
          <div key={customField._id}>
            <div>{renderCustomField(customField)}</div>
          </div>
        );
      })}
    </div>
  );
};

export default TaskCustomFields;
