import { Flex } from 'src/components/design-system';
import styled from 'styled-components/macro';

export const IconBlock = styled(Flex)`
  width: 28px;
  height: 28px;
  border-radius: 4px;
  background-color: #4158d0;
  background-image: linear-gradient(
    163deg,
    #4158d0 0%,
    #c850c0 46%,
    #ffcc70 100%
  );
`;
