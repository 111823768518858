import React from 'react';

import BlockButton from '../Toolbar/BlockButton';
import Dropdown from '../Toolbar/Dropdown';
import MarkButton from '../Toolbar/MarkButton';

import { ToolbarBlock } from './styles';

const toolbarGroups = [
  [
    {
      id: 3,
      format: 'bold',
      type: 'mark',
    },
    {
      id: 4,
      format: 'italic',
      type: 'mark',
    },
    {
      id: 5,
      format: 'underline',
      type: 'mark',
    },
    {
      id: 6,
      format: 'strikethrough',
      type: 'mark',
    },
  ],
  [
    {
      id: 15,
      format: 'orderedList',
      type: 'block',
    },
    {
      id: 16,
      format: 'unorderedList',
      type: 'block',
    },
  ],
];

const Toolbar = () => {
  return (
    <ToolbarBlock>
      {toolbarGroups.map((group, index) => (
        <span key={index} className="toolbar-grp">
          {group.map(element => {
            switch (element.type) {
              case 'block':
                return <BlockButton key={element.id} {...element} />;
              case 'mark':
                return <MarkButton key={element.id} {...element} />;
              case 'dropdown':
                return <Dropdown key={element.id} {...element} />;
              default:
                return null;
            }
          })}
        </span>
      ))}
    </ToolbarBlock>
  );
};

export default Toolbar;
