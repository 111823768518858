import { colors, grayScale } from './colors';

const common = {
  radius: {
    xs: '2px',
    sm: '4px',
    md: '8px',
    lg: '16px',
    xl: '32px',
    pill: '999px',
    rounded: '100%',
  },
};

export const borderLight = {
  color: {
    primary: '#d0d7de',
    secondary: 'rgba(31,34,38,0.35)',
    strong: grayScale.gray25,
    medium: grayScale.gray20,
    light: grayScale.gray15,
    secondaryInverted: grayScale.gray50,
    inverted: grayScale.gray60,
    danger: colors.red200,
  },
  ...common,
};

export const borderDark = {
  color: {
    primary: '#444c56',
    secondary: '#373e47',
    strong: grayScale.gray55,
    medium: grayScale.gray65,
    light: grayScale.gray70,
    secondaryInverted: grayScale.gray35,
    inverted: grayScale.gray20,
    danger: colors.red200,
  },
  ...common,
};
