import styled from 'styled-components';

export const PaginatedContainer = styled.div`
  width: 346px;
  height: 300px;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
`;

export const VirtualizedRow = styled.div`
  display: flex;
  gap: 10px;
`;

export const Sticker = styled.div`
  width: 64px;
  height: 64px;
  padding: 2px;
  border-radius: ${({ theme }) => theme.border.radius.md};
  &:hover {
    background: ${({ theme }) => theme.background.hover};
  }
  img {
    width: 100%;
    height: 100%;
    border-radius: ${({ theme }) => theme.border.radius.md};
  }
`;
