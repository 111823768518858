import { borderDark } from './border';
import { boxShadow } from './box-shadow';
import { colors, grayScale } from './colors';
import { fontDark } from './font';

export const darkDimmedTheme = {
  background: {
    primary: '#1C2128',
    secondary: '#2d333b',
    tertiary: grayScale.gray75,
    selected: 'rgba(144,157,171,0.08)',
    hover: 'rgba(144,157,171,0.12)',
  },
  header: {
    primary: '#1C2128',
    text: '#cdd9e5',
  },
  text: {
    primary: '#adbac7',
    primaryHover: 'rgba(205,217,229,0.7)',
    active: '#cdd9e5',
    secondary: '#768390',
  },
  taskPreview: {
    primary: '#2d333b',
    border: '#444c56',
  },
  task: {
    primary: '#2d333b',
    secondary: '#2b3036',
    completed: 'rgb(34 126 82 / 42%)',
    selected: 'rgba(149, 216, 244, 0.5)',
    urgent: 'rgb(209 55 55 / 35%)',
    high: 'rgb(196 135 53 / 46%)',
  },
  sidebar: {
    primary: '#22272e',
  },
  layout: {
    background: '#1C2128',
  },
  switch: {
    primary: '#1C2128',
    secondary: '#b4b4bb',
  },
  scrollbar: {
    scrollbarTrack: '#1e1f21',
    scrollbarThumb: '#444c56',
  },
  button: {
    active: {
      background: '#079aaa',
      color: '#fff',
    },
  },

  color: colors,
  grayScale: grayScale,
  border: borderDark,
  font: fontDark,
  boxShadow,

  spacing: (...args: number[]) =>
    args.map(multiplicator => `${multiplicator * 4}px`).join(' '),
};
