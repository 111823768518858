import React, { memo } from 'react';

import { useOther } from 'src/liveblocks.config';

type CursorProps = {
  connectionId: number;
};
const Cursor = ({ connectionId }: CursorProps) => {
  const info = useOther(connectionId, user => user.info);
  const cursor = useOther(connectionId, user => user.presence.cursor);

  if (!cursor) return null;

  const { x, y } = cursor;

  return (
    <foreignObject
      style={{
        transform: `translateX(${x}px) translateY(${y}px)`,
        border: '1px solid orangered',
      }}
      width={20}
      height={20}
    >
      <span style={{ fill: 'orangered', color: '#333' }}>{connectionId}</span>
    </foreignObject>
  );
};

export default memo(Cursor);
