import { ProjectViews } from '../../../../pages/Project/types';

export const projectViews = [
  {
    icon: 'project',
    id: ProjectViews.Kanban,
  },
  {
    icon: 'calendar',
    id: ProjectViews.Calendar,
  },
  {
    icon: 'table',
    id: ProjectViews.Table,
  },
  {
    icon: 'dashboard',
    id: ProjectViews.Dashboard,
  },
  {
    icon: 'folders',
    id: ProjectViews.Files,
  },
  {
    icon: 'info',
    id: ProjectViews.Overview,
  },
];
