import styled, { css } from 'styled-components/macro';

import Button from '../Button/Button';

type TagProps = {
  bg?: string;
  hoverBg?: string;
  color?: string;
  width?: string;
  disabled?: boolean;
};

export const Title = styled.div<TagProps>`
  color: ${({ color }) => color || '#fff'};
  width: 100%;
`;

export const Tag = styled.div<TagProps>`
  padding: 2px;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  color: #fff;
  font-weight: 600;
  border: 1px solid ${({ hoverBg }) => hoverBg};
  &:hover {
    background: ${({ hoverBg }) => hoverBg || 'transparent'};
  }

  ${({ bg }) =>
    bg &&
    css`
      background: ${bg};
    `}

  ${({ disabled, color }) =>
    disabled &&
    color &&
    css`
      background: ${color};
      cursor: not-allowed;
      ${Title} {
        color: #fff;
      }

      &:hover {
        background: ${color};
      }
    `}
`;

export const Close = styled(Button)<TagProps>`
  background: transparent;
  border: none;
  margin-left: 10px;
  width: 20px;
  height: 20px;
  svg {
    color: ${p => p.theme.text};
  }
  &:hover {
    background: ${({ hoverBg }) => hoverBg || 'transparent'};
  }
`;
