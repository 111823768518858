import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import ProjectSmallInfo from 'src/components/ProjectSmallInfo/ProjectSmallInfo';
import { Table, IColumnType, AvatarList } from 'src/components/design-system';

import { DateHelpers, DateFormats } from 'src/common/helpers';

import { ProjectsDashboard } from 'src/generated';

const ProjectsTable = ({ projects, loading }) => {
  const { t } = useTranslation();
  const columns: IColumnType<ProjectsDashboard>[] = [
    {
      key: 'count',
      title: '#',
      width: 10,
    },
    {
      key: 'title',
      title: t('projectsTable.title'),
      width: 400,
      render: (_, { _id, title, color }) => {
        return (
          <Link to={`/projects/${_id}`}>
            <ProjectSmallInfo title={title} color={color} />
          </Link>
        );
      },
    },
    {
      key: 'members',
      title: t('projectsTable.members'),
      width: 100,
      render: (_, { members }) => {
        return <AvatarList members={members} />;
      },
    },
    {
      key: 'labelsList',
      title: t('projectsTable.labelsList'),
      width: 400,
      render: (_, { labelsList }) => {
        return labelsList?.join(', ');
      },
    },
    {
      key: 'labelsCount',
      title: t('projectsTable.labelsCount'),
      width: 200,
      render: (_, { labelsCount }) => {
        return (
          <strong>
            <center>{labelsCount}</center>
          </strong>
        );
      },
    },
    {
      key: 'statusesList',
      title: t('projectsTable.statusesList'),
      width: 400,
      render: (_, { statusesList }) => {
        return statusesList?.join(', ');
      },
    },
    {
      key: 'statusesCount',
      title: t('projectsTable.statusesCount'),
      width: 200,
      render: (_, { statusesCount }) => {
        return (
          <strong>
            <center>{statusesCount}</center>
          </strong>
        );
      },
    },
    {
      key: 'taskCount',
      title: t('projectsTable.taskCount'),
      width: 200,
      render: (_, { taskCount }) => {
        return (
          <strong>
            <center>{taskCount}</center>
          </strong>
        );
      },
    },
    {
      key: 'filesCount',
      title: t('projectsTable.filesCount'),
      width: 200,
      render: (_, { filesCount }) => {
        return (
          <strong>
            <center>{filesCount}</center>
          </strong>
        );
      },
    },
    {
      key: 'createdAt',
      title: t('projectsTable.createdAt'),
      width: 120,
      render: (_, { createdAt }) => (
        <>{DateHelpers.formatDate(createdAt, DateFormats.DayLongMonthYear)}</>
      ),
    },
    {
      key: 'updatedAt',
      title: t('projectsTable.updatedAt'),
      width: 120,
      render: (_, { updatedAt }) => (
        <>{DateHelpers.formatDate(updatedAt, DateFormats.DayLongMonthYear)}</>
      ),
    },
  ];

  return <Table data={projects} columns={columns} loading={loading} />;
};

export default ProjectsTable;
