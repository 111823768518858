import styled from 'styled-components';

export const ImageBlock = styled.div`
  img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }
`;
