import { gql } from '@apollo/client';

export const TASK_DESCRIPTION_HISTORY = gql`
  query TaskDescriptionHistory($taskId: ID!, $skip: Int, $limit: Int) {
    taskDescriptionHistory(taskId: $taskId, skip: $skip, limit: $limit) {
      _id
      text
      taskId
      createdAt
      user {
        fullName
      }
    }
  }
`;
