import React, { useContext } from 'react';

import { Box } from 'src/components/design-system';

import FormContext from '../../../FormContext';
import GeneralForm from './GeneralForm';

const General = ({ element }) => {
  if (!element) return null;

  return (
    <div>
      <Box padding={20}>
        <GeneralForm element={element} />
        <pre>{JSON.stringify(element, null, 2)}</pre>
      </Box>
    </div>
  );
};

export default General;
