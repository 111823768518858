import React from 'react';

import DisplayStoryPointsDropdown from './DisplayStoryPointsDropdown';
import EditStoryPointsDropdown from './EditStoryPointsDropdown';

const PriorityDropdown = ({
  id,
  rowIndex,
  taskId,
  storyPoint,
  isEditing,
  updateData,
}) => {
  if (isEditing) {
    return (
      <EditStoryPointsDropdown
        id={id}
        rowIndex={rowIndex}
        taskId={taskId}
        storyPoint={storyPoint}
        updateData={updateData}
      />
    );
  }

  return <DisplayStoryPointsDropdown storyPoint={storyPoint} />;
};

export default PriorityDropdown;
