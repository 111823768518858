import React from 'react';

import { LoadingIndicator } from 'src/components/design-system';

import TableHeader from './TableHeader/TableHeader';
import TableRow from './TableRow/TableRow';

import * as Styled from './styles';

export interface IColumnType<T> {
  key: string;
  title: string;
  width?: number;
  render?: (column: IColumnType<T>, item: T) => void;
  onClick?: (column: IColumnType<T>, item: T) => void;
}

export interface TableProps<T> {
  data: T[];
  columns: IColumnType<T>[];
  loading?: boolean;
}

const Table = <T,>({ data, columns, loading }: TableProps<T>) => {
  const width = columns.reduce((acc, column) => {
    return acc + (column.width || 0);
  }, 0);

  return (
    <Styled.Container>
      {loading && (
        <Styled.TableLoading>
          <LoadingIndicator />
        </Styled.TableLoading>
      )}
      <Styled.Table loading={loading} width={width}>
        <Styled.Thead>
          <TableHeader columns={columns} />
        </Styled.Thead>
        <tbody>
          <TableRow data={data} columns={columns} loading={loading} />
        </tbody>
      </Styled.Table>
    </Styled.Container>
  );
};

export default Table;
