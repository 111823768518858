import { useGoogleOneTapLogin } from '@react-oauth/google';
import React, { lazy } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';

import { useNotification } from './components/design-system';

import Auth from './helpers/auth';
import { useUserStore } from './store';

import { useGoogleLoginMutation, User } from './generated';

const Login = lazy(() => import('./pages/Auth/Login/Login'));
const SignUp = lazy(() => import('./pages/Auth/SignUp/SignUp'));
const InviteToProject = lazy(
  () => import('./pages/Auth/InviteToProject/InviteToProject'),
);

const Public = () => {
  const notification = useNotification();
  const [googleLogin] = useGoogleLoginMutation();
  const navigate = useNavigate();
  const setUser = useUserStore(state => state.setUser);

  useGoogleOneTapLogin({
    onSuccess: response => {
      // Extract token from response and send to the backend
      const googleToken = response.credential;

      if (!googleToken) return;

      googleLogin({ variables: { token: googleToken } })
        .then(({ data }) => {
          if (!data) return;
          const { googleLogin } = data;
          const { token, user } = googleLogin;
          Auth.login(token);
          setUser(user as User);
          navigate('/');
        })
        .catch(() => {
          notification.show({
            title: 'Account not verified',
            message: 'Please ask the admin to verify your account',
            variant: 'error',
          });
        });
    },
    onError: () => {},
  });

  return (
    <Routes>
      <Route path="/sign-in" element={<Login />} />
      <Route path="/sign-up" element={<SignUp />} />
      <Route path="/invite" element={<InviteToProject />} />
      <Route path="*" element={<Navigate to="/sign-in" replace />} />
    </Routes>
  );
};

export default Public;
