import styled from 'styled-components/macro';

type ElementBlockProps = {
  isAdded?: boolean;
  isUpdated?: boolean;
};
export const ElementBlock = styled.div<ElementBlockProps>`
  position: relative;

  ${({ isAdded }) =>
    isAdded &&
    `
    background: #ccffd8;
      &:before {
      content: '';
      position: absolute;
      top: 0;
      left: -20px;
      width: 20px;
      height: 100%;
      background: #ccffd8;
    }
    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: -20px;
      width: 20px;
      height: 100%;
      background: #ccffd8;
    }
  `}

  ${({ isUpdated }) =>
    isUpdated &&
    `
    background: #fff8c5;
      &:before {
      content: '';
      position: absolute;
      top: 0;
      left: -20px;
      width: 20px;
      height: 100%;
      background: #fff8c5;
    }
    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: -20px;
      width: 20px;
      height: 100%;
      background: #fff8c5;
    }
  `}
`;
