import styled from 'styled-components/macro';

export const Title = styled.h2`
  margin: 0;
  font-size: 15px;
  padding: 8px 16px;
  cursor: pointer;
  display: inline-block;
  border-radius: ${({ theme }) => theme.border.radius.sm};
  background: ${({ theme }) => theme.background.hover};
`;
