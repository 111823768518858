import React, { useState, useEffect, useRef, useCallback } from 'react';

import { Flex, Button, Icon } from 'src/components/design-system';

import ProgressBar from '../ProgressBar/ProgressBar';

import { Container, AudioControls } from './styles';

const Controls = ({
  audioRef,
  progressBarRef,
  duration,
  setTimeProgress,
  timeProgress,
  handleGoToNextChapter,
  handleGoToPreviousChapter,
  saveCurrentTime,
}) => {
  const [isPlaying, setIsPlaying] = useState(false);

  const togglePlayPause = () => {
    setIsPlaying(prev => !prev);
    if (isPlaying) {
      saveCurrentTime();
    }
  };

  const playAnimationRef = useRef();

  const repeat = useCallback(() => {
    const currentTime = audioRef.current.currentTime;
    setTimeProgress(currentTime);
    progressBarRef.current.value = currentTime;
    progressBarRef.current.style.setProperty(
      '--range-progress',
      `${(progressBarRef.current.value / duration) * 100}%`,
    );

    // @ts-ignore
    playAnimationRef.current = requestAnimationFrame(repeat);
  }, [audioRef, duration, progressBarRef, setTimeProgress]);

  useEffect(() => {
    if (isPlaying) {
      audioRef.current.play();
    } else {
      audioRef.current.pause();
    }
    // @ts-ignore
    playAnimationRef.current = requestAnimationFrame(repeat);
  }, [isPlaying, audioRef, repeat]);

  const skipForward = () => {
    audioRef.current.currentTime += 10;
  };

  const skipBackward = () => {
    audioRef.current.currentTime -= 15;
  };

  return (
    <Container>
      <Flex justifyContent="center" alignItems="center" direction="column">
        <AudioControls gap={10}>
          <Button
            size="small"
            htmlType="text"
            onClick={handleGoToPreviousChapter}
          >
            <Icon name="double-backward" size={20} />
          </Button>
          <Button size="small" htmlType="text" onClick={skipBackward}>
            <Icon name="backward-15" size={25} />
          </Button>

          <Button size="small" htmlType="text" onClick={togglePlayPause}>
            <Icon
              size={20}
              name={isPlaying ? 'pause-square-fill' : 'play-square-fill'}
            />
          </Button>
          <Button size="small" htmlType="text" onClick={skipForward}>
            <Icon name="forward-15" size={25} />
          </Button>
          <Button size="small" htmlType="text" onClick={handleGoToNextChapter}>
            <Icon name="double-forward" size={20} />
          </Button>
        </AudioControls>

        <ProgressBar
          {...{ progressBarRef, audioRef, timeProgress, duration }}
        />
      </Flex>
    </Container>
  );
};

export default Controls;
