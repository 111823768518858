import styled from 'styled-components';

export const ParentLink = styled.div`
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  border-radius: ${({ theme }) => theme.border.radius.md};
  background: ${({ theme }) => theme.background.primary};
  padding: 10px;
  cursor: pointer;
  svg {
    margin-right: 10px;
  }

  &:hover {
    background: ${({ theme }) => theme.background.primary};
  }
`;
export const ParentLinkTitle = styled.h4`
  margin: 0;
  font-size: 20px;
`;
