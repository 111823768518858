import React from 'react';

import * as Styled from './styles';

type ProjectSmallInfoProps = {
  title: string;
  color: string;
  isDragging?: boolean;
  isDraggable?: boolean;
};

const ProjectSmallInfo = ({
  title,
  color,
  isDragging,
  isDraggable,
}: ProjectSmallInfoProps) => {
  return (
    <Styled.Container alignItems="center" isDragging={isDragging}>
      <Styled.BoxIcon backgroundColor={color}>
        {title.charAt(0).toLocaleUpperCase()}
      </Styled.BoxIcon>
      <Styled.Text>{title}</Styled.Text>
      {isDraggable && <Styled.ReorderIcon name="reorder" />}
    </Styled.Container>
  );
};

export default ProjectSmallInfo;
