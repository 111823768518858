import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Flex } from 'src/components/design-system';

import {
  UserPreviewBlock,
  UserPreviewContainer,
  UserPreviewFooter,
  UserPreviewImage,
} from './styles';

const AssigneePreview = ({ assignee }) => {
  const { t } = useTranslation();
  const { fullName, email, profilePicture } = assignee;

  return (
    <UserPreviewContainer>
      <UserPreviewImage>
        {profilePicture && <img src={profilePicture} alt={fullName} />}
      </UserPreviewImage>
      <UserPreviewBlock>
        <strong>{fullName}</strong>
        <div>{email}</div>
      </UserPreviewBlock>
      <UserPreviewFooter>
        <Flex direction="column" gap={10}>
          <Button htmlType="primary">{t('task.assignee.viewProfile')}</Button>
          <Button>{t('task.assignee.sendMessage')}</Button>
        </Flex>
      </UserPreviewFooter>
    </UserPreviewContainer>
  );
};

export default AssigneePreview;
