import { gql } from '@apollo/client';

import { NOTIFICATION_FRAGMENT } from '../../fragments';

gql`
  subscription OnCreateNotification(
    $workspaceId: ID!
    $projectId: ID
    $userId: ID
    $isPrivate: Boolean
  ) {
    onCreateNotification(
      workspaceId: $workspaceId
      projectId: $projectId
      userId: $userId
      isPrivate: $isPrivate
    ) {
      ...NotificationFragment
    }
  }
  ${NOTIFICATION_FRAGMENT}
`;
