import React from 'react';

import { IColumnType } from '../Table';

import { TableHeaderCell } from './styles';

interface Props<T> {
  columns: IColumnType<T>[];
}

const TableHeader = <T,>({ columns }: Props<T>) => {
  return (
    <tr>
      {columns.map((column, columnIndex) => (
        <TableHeaderCell
          key={`table-head-cell-${columnIndex}`}
          style={{ width: column.width }}
        >
          {column.title}
        </TableHeaderCell>
      ))}
    </tr>
  );
};

export default TableHeader;
