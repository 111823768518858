import styled from 'styled-components/macro';

export const Container = styled.div`
  position: absolute;
  z-index: 100;
  top: -10000px;
  left: -10000px;
  margin-top: -20px;
  opacity: 0;
  background-color: ${({ theme }) => theme.background.secondary};
  border-radius: ${({ theme }) => theme.border.radius.sm};
  transition: opacity 0.75s;
  border: 1px solid ${({ theme }) => theme.border.color.primary};
  box-shadow: 0 2px 8px rgb(0 0 0 / 15%);

  .toolbar-grp {
    cursor: pointer;
    background: transparent;
    border: none;
    display: flex;
    align-items: center;
  }

  select {
    height: 30px;
    border: none;
    width: 6.9rem;
  }
`;

export const ToolbarBlock = styled.div`
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  row-gap: 15px;
  padding: 10px;

  button {
    border: none;
    background: transparent;
  }

  svg {
    color: ${p => p.theme.text};
  }
`;

export const UrlForm = styled.form`
  display: flex;
  align-items: center;
  padding: 10px;
  width: 300px;
  input {
    border: 1px solid rgb(167, 174, 186);
    background-color: rgb(255, 255, 255);
    padding: 8px 14px;
    border-radius: ${({ theme }) => theme.border.radius.sm};
    height: auto;
    line-height: inherit;
    transition: all 0.3s;
    width: 100%;
    margin-right: 10px;
    &:focus {
      outline: none;
      box-shadow: rgb(59 148 191 / 30%) 0 0 0 4px;
      border-color: rgb(59, 148, 191);
    }
  }
`;
