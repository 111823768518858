import { useTranslation } from 'react-i18next';

import { Button, LoadingIndicator, Flex } from 'src/components/design-system';

import useQueryWithFetchMore from 'src/hooks/apollo/useQueryWithFetchMore';
import { useNotifications } from 'src/hooks/useNotifications';

import Notification from './Notification/Notification';
import { useUser } from 'src/store';

import {
  NotificationsDocument,
  NotificationsQuery,
  NotificationsQueryVariables,
} from 'src/generated';

import * as Styled from './styles';

const NotificationsList = () => {
  const { _id, workspaceId } = useUser();
  const { t } = useTranslation();

  const { data, fetchingMore, ref } = useQueryWithFetchMore<
    NotificationsQuery,
    NotificationsQueryVariables
  >({
    query: NotificationsDocument,
    variables: {
      workspaceId,
      userId: _id,
      isPrivate: true,
    },
  });

  const { onReadNotification, onReadAllNotification, unreadCount } =
    useNotifications({});

  const handleReadNotification = (notificationId: string) => {
    onReadNotification(notificationId);
  };

  const handleReadAllNotifications = () => {
    onReadAllNotification();
  };

  return (
    <Styled.Container>
      <Styled.Header justifyContent="space-between" alignItems="center">
        <Styled.Title>{t('notifications.title')}</Styled.Title>
        {unreadCount > 0 && (
          <Button htmlType="link" onClick={handleReadAllNotifications}>
            {t('notifications.readAll')}
          </Button>
        )}
      </Styled.Header>
      <Styled.List>
        {data?.notifications.length === 0 && (
          <Flex justifyContent="center">{t('notifications.empty')}</Flex>
        )}

        {data?.notifications.map(notification => (
          <Notification
            key={notification._id}
            notification={notification}
            handleReadNotification={handleReadNotification}
          />
        ))}
        <div ref={ref}>{fetchingMore && <LoadingIndicator />}</div>
      </Styled.List>
    </Styled.Container>
  );
};

export default NotificationsList;
