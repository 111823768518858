import React from 'react';

import { Icon, IconName } from 'src/components/design-system';

import { ActionItem, ActionItemHeader, ActionItemTitle } from './styles';

type TaskBlockProps = {
  title?: string;
  icon?: IconName;
  children: React.ReactNode;
  suffix?: React.ReactNode;
  bgColor?: string;
};

const TaskBlock = ({
  title,
  icon,
  children,
  suffix,
  bgColor,
}: TaskBlockProps) => {
  return (
    <ActionItem bgColor={bgColor}>
      <ActionItemHeader>
        {icon && <Icon name={icon} />}
        {title && <ActionItemTitle>{title}</ActionItemTitle>}
        {suffix && suffix}
      </ActionItemHeader>
      {children}
    </ActionItem>
  );
};

export default TaskBlock;
