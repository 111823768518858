import styled from 'styled-components';

export const Container = styled.div`
  h2 {
    margin: 0 0 10px 0;
  }
`;

export const EditorBlock = styled.div`
  border: 1px solid ${({ theme }) => theme.border.color.primary};
  border-radius: ${({ theme }) => theme.border.radius.sm};
  margin-bottom: 20px;
  min-height: 150px;
`;
