import React, { useState } from 'react';

import { useTask } from 'src/hooks/useTask';

import { AddActionBlock } from './styles';

const AddAction = ({ statusId }) => {
  const [value, setValue] = useState('');
  const { onCreateTask } = useTask();

  const handleCreateTask = async e => {
    e.preventDefault();

    if (!value) {
      return;
    }

    onCreateTask({ title: value, statusId });
    setValue('');
  };

  return (
    <AddActionBlock>
      <form onSubmit={handleCreateTask}>
        <input
          type="text"
          placeholder="Add a task"
          onBlur={handleCreateTask}
          value={value}
          onChange={e => setValue(e.target.value)}
        />
      </form>
    </AddActionBlock>
  );
};

export default AddAction;
