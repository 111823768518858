import React, { useState, useRef, useContext, createContext } from 'react';

import { Modal, Flex, Box } from 'src/components/design-system';

import * as Styled from './styles';

type ConfirmContextType = (options: ConfirmOptions) => Promise<void>;

const ConfirmContext = createContext<ConfirmContextType>(
  {} as ConfirmContextType,
);

type ConfirmOptions = {
  title?: string;
  message?: string;
};

interface ConfirmPromise {
  resolve: () => void;
  reject: () => void;
}

export const useConfirm = () => useContext(ConfirmContext);

export const ConfirmServiceProvider = ({ children }) => {
  const [options, setOptions] = useState<ConfirmOptions | null>(null);
  const awaitingPromiseRef = useRef<ConfirmPromise | null>(null);

  const openModal = (options: ConfirmOptions) => {
    setOptions(options);
    return new Promise<void>((resolve, reject) => {
      awaitingPromiseRef.current = { resolve, reject };
    });
  };

  const handleClose = () => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.reject();
    }
    setOptions(null);
  };

  const handleConfirm = () => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve();
    }
    setOptions(null);
  };

  const { message } = options || {};

  return (
    <>
      <ConfirmContext.Provider value={openModal} children={children} />

      <Modal width={400} isOpen={Boolean(options)} onRequestClose={handleClose}>
        <Box padding={20}>
          <h3>{'Are you sure you want to delete?'}</h3>
          {message && <p>{message}</p>}
        </Box>
        <Flex>
          <Styled.ConfirmButton resolve htmlType="text" onClick={handleConfirm}>
            {'Yes, I agree'}
          </Styled.ConfirmButton>
          <Styled.ConfirmButton reject htmlType="text" onClick={handleClose}>
            {'Cancel'}
          </Styled.ConfirmButton>
        </Flex>
      </Modal>
    </>
  );
};
