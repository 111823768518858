import React from 'react';

import FirstMenu from './FirstMenu/FirstMenu';
import SecondMenu from './SecondMenu/SecondMenu';

import * as Styled from './styles';

const Header = () => {
  return (
    <Styled.Header>
      <FirstMenu />
      <SecondMenu />
    </Styled.Header>
  );
};

export default Header;
