import React, { useState } from 'react';
import { HexColorPicker } from 'react-colorful';

import { COLORS } from '../../../pages/Project/components/Board/Action/Labels/colors';
import {
  ColorItem,
  ColorItemHover,
  ColorItemWrapper,
  ColorsList,
} from '../../../pages/Project/components/Board/Action/Labels/styles';

import Dropdown from 'rc-dropdown';
import Tooltip from 'rc-tooltip';

import { ColorsDropdown, PreviewColor } from './styles';

type ColorsProps = {
  value?: string;
  onChange: (color: string) => void;
  defaultColor?: string;
};

const ColorBlock = ({ title, value }) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <Tooltip
      visible={isVisible}
      placement="top"
      trigger={['hover']}
      overlay={title}
      onVisibleChange={setIsVisible}
    >
      <ColorItem
        onMouseLeave={() => setIsVisible(false)}
        style={{ background: value }}
      />
    </Tooltip>
  );
};

const Colors = ({ value, defaultColor, onChange }: ColorsProps) => {
  const [color, setColor] = useState(value || defaultColor || '#b32aa9');

  const handleChangeColor = color => {
    setColor(color);
    onChange(color);
  };

  const Menu = () => (
    <ColorsDropdown>
      <ColorsList>
        {COLORS.map(({ title, value }) => (
          <ColorItemWrapper
            key={value}
            onClick={() => handleChangeColor(value)}
          >
            <ColorItemHover />
            <ColorBlock title={title} value={value} />
          </ColorItemWrapper>
        ))}
      </ColorsList>

      <HexColorPicker color={color} onChange={handleChangeColor} />
    </ColorsDropdown>
  );

  return (
    <div>
      <Dropdown
        trigger={['click']}
        overlay={<Menu />}
        getPopupContainer={(trigger: HTMLElement) =>
          trigger?.parentNode as HTMLElement
        }
      >
        <PreviewColor style={{ background: color }} />
      </Dropdown>
    </div>
  );
};

export default Colors;
