import styled from 'styled-components';

export const Container = styled.div`
  width: 300px;
  padding: 8px;
`;

export const Title = styled.h3`
  margin: 0 0 10px 0;
`;
export const List = styled.div`
  height: 300px;
  overflow-y: auto;
`;
