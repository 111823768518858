import React from 'react';

import { Avatar, Icon, Tooltip } from 'src/components/design-system';

import * as Styled from './styles';

type AvatarListProps = {
  allowInvite?: boolean;
  members?: any[] | undefined | null; // TODO: Define type
  onClick?: () => void;
};

const AvatarList = ({ members, onClick, allowInvite }: AvatarListProps) => {
  return (
    <Styled.Container
      padding={[4, 10]}
      alignItems="center"
      onClick={onClick}
      allowInvite={allowInvite}
    >
      {members?.map((member: any) => (
        <Tooltip key={member._id} overlay={member.fullName} placement="top">
          <Styled.User>
            <Avatar user={member} mr={0} size={22} />
          </Styled.User>
        </Tooltip>
      ))}
      {allowInvite && (
        <Styled.AddUser>
          <Icon name="add-user" size={14} />
        </Styled.AddUser>
      )}
    </Styled.Container>
  );
};

export default AvatarList;
