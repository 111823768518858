import { gql } from '@apollo/client';

export const GET_ACTION_TIME_TRACKING = gql`
  query TimeTracking($actionId: String!) {
    timeTracking(actionId: $actionId) {
      _id
      startTime
      endTime
      loggedTime
      createdAt
      endTime
    }
  }
`;
