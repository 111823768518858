import React from 'react';

import { Img } from './styles';

const Giphy = ({ attributes, element, children }) => {
  const { url } = element;

  return (
    <div {...attributes}>
      <Img alt={element.alt} src={url} />
      {children}
    </div>
  );
};
export default Giphy;
