import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Flex, Icon, Menu } from 'src/components/design-system';

import { useTask } from 'src/hooks/useTask';

import { TASK_MORE_MENU } from './constants';
import Dropdown from 'rc-dropdown';
import { openTaskDescriptionHistoryDrawer } from 'src/graphql/vars';

const TaskMoreMenu = ({ action }) => {
  const { _id: taskId, isUrgent } = action;
  const { t } = useTranslation();
  const { onDuplicateTask, onCopyShareLink, onUpdateTask } = useTask();
  const handleMenuClick = (id: string) => {
    switch (id) {
      case TASK_MORE_MENU.MARK_URGENT:
        onUpdateTask({ taskId, isUrgent: !isUrgent });
        break;
      case TASK_MORE_MENU.DESCRIPTION_HISTORY:
        openTaskDescriptionHistoryDrawer(true);
        break;
      case TASK_MORE_MENU.DUPLICATE_TASK:
        onDuplicateTask({ taskId });
        break;
      case TASK_MORE_MENU.COPY_SHARE_LINK:
        onCopyShareLink();
        break;
      default:
        break;
    }
  };

  const taskMoreMenuOptions = useMemo(() => {
    return [
      {
        type: 'header',
        title: 'task.moreMenu.markAs',
      },
      {
        id: TASK_MORE_MENU.MARK_URGENT,
        title: isUrgent
          ? 'task.moreMenu.markNotUrgent'
          : 'task.moreMenu.markUrgent',
        icon: 'info-triangle-filled',
      },
      {
        type: 'divider',
      },
      {
        type: 'header',
        title: 'task.moreMenu.taskTemplates',
      },
      {
        id: TASK_MORE_MENU.APPLY_TEMPLATE,
        title: 'task.moreMenu.applyTemplate',
        disabled: true,
        // icon: 'puzzle',
      },
      {
        id: TASK_MORE_MENU.SAVE_AS_TEMPLATE,
        title: 'task.moreMenu.saveAsTemplate',
        disabled: true,
        // icon: 'puzzle',
      },
      {
        type: 'divider',
      },
      {
        id: TASK_MORE_MENU.DUPLICATE_TASK,
        title: 'task.moreMenu.duplicateTask',
        icon: 'copy',
      },
      {
        id: TASK_MORE_MENU.DESCRIPTION_HISTORY,
        title: 'task.moreMenu.descriptionHistory',
        icon: 'history',
      },
      {
        id: TASK_MORE_MENU.COPY_SHARE_LINK,
        title: 'task.moreMenu.copyShareLink',
        icon: 'share',
      },
    ];
  }, [isUrgent]);

  return (
    <Dropdown
      placement="bottomRight"
      trigger={['click']}
      overlay={() => (
        <Menu options={taskMoreMenuOptions} handleMenuClick={handleMenuClick} />
      )}
      getPopupContainer={(trigger: HTMLElement) =>
        trigger?.parentNode as HTMLElement
      }
    >
      <Button size="little" htmlType="text">
        <Flex padding={[0, 5, 0, 0]}>
          <Icon name="dots-vertical" size={20} />
          {t('general.more')}
        </Flex>
      </Button>
    </Dropdown>
  );
};

export default TaskMoreMenu;
