import styled from 'styled-components';

export const TooltipBlock = styled.div`
  padding: 10px;
  outline: none;
  border-radius: ${({ theme }) => theme.border.radius.md};
  background: ${p => p.theme.background.primary};
  border: 1px solid ${p => p.theme.border.color.primary};
  max-width: 300px;
  max-height: 300px;
  overflow: auto;
`;
