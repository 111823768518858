export enum FormViews {
  Designer = 'designer',
  Preview = 'preview',
}

export const menu = [
  {
    view: FormViews.Designer,
    title: 'Designer',
  },
  {
    view: FormViews.Preview,
    title: 'Preview',
  },
];
