import { InitialState } from './types';

export const initialState: InitialState = {
  title: 'Survey Title',
  description: 'description',
  elements: [],
  activeElement: null,
};

export const reducer = (
  state: InitialState,
  action: Partial<InitialState>,
) => ({
  ...state,
  ...action,
});
