import { useApolloClient } from '@apollo/client';

import { NOTIFICATIONS } from 'src/graphql/notifications';
import { useUser } from 'src/store';

import {
  NotificationsDocument,
  useReadNotificationMutation,
  useReadAllNotificationMutation,
  useNotificationsQuery,
  NotificationsQuery,
} from 'src/generated';

type UseNotificationsProps = {
  projectId?: string;
};

export const useNotifications = ({ projectId }: UseNotificationsProps) => {
  const { _id, workspaceId } = useUser();
  const client = useApolloClient();

  let variables: any = {
    workspaceId,
  };

  if (projectId) {
    variables['projectId'] = projectId;
  } else {
    variables['userId'] = _id;
    variables['isPrivate'] = true;
  }

  // TODO
  // need it to fetch notifications on page load
  useNotificationsQuery({
    variables,
  });

  const [readNotification] = useReadNotificationMutation();

  const [readAllNotification] = useReadAllNotificationMutation({
    update(cache) {
      const { notifications } = cache.readQuery({
        query: NOTIFICATIONS,
        variables,
      }) as any;

      cache.writeQuery({
        query: NOTIFICATIONS,
        variables,
        data: {
          notifications: notifications?.map(notification => ({
            ...notification,
            isRead: true,
          })),
        },
      });
    },
  });

  const cache = client.cache.readQuery({
    query: NotificationsDocument,
    variables,
  }) as NotificationsQuery;

  return {
    unreadCount: cache?.notifications.filter(({ isRead }) => !isRead).length,
    onReadNotification: (notificationId: string) => {
      readNotification({
        variables: {
          notificationId,
        },
        optimisticResponse: {
          __typename: 'Mutation',
          readNotification: {
            __typename: 'Notification',
            _id: notificationId,
            isRead: true,
          },
        },
      });
    },
    onReadAllNotification: () => {
      readAllNotification({
        optimisticResponse: {
          __typename: 'Mutation',
          readAllNotification: true,
        },
      });
    },
  };
};
