import { gql } from '@apollo/client';

export const GET_TASK_CUSTOM_FIELDS = gql`
  query TaskCustomFields(
    $workspaceId: ID!
    $projectId: ID!
    $taskId: ID!
    $projectIds: [ID!]
  ) {
    customFields(workspaceId: $workspaceId, projectIds: $projectIds) {
      _id
      title
      type
      projectIds
    }
    taskCustomFields(taskId: $taskId, projectId: $projectId) {
      _id
      value
      customField {
        _id
        title
        type
      }
    }
  }
`;
