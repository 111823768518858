import React from 'react';

import { Flex, Box, LoadingIndicator } from 'src/components/design-system';

import { useCanvasBoards } from 'src/hooks/useCanvasBoards';

import CanvasCard from './CanvasCard/CanvasCard';
import CreateMiroBoard from './CreateMiroBoard/CreateMiroBoard';
import MainLayout from 'src/layouts/MainLayout/MainLayout';
import { useUserWorkspace } from 'src/store';

const Miro = () => {
  const workspaceId = useUserWorkspace();
  const { canvasBoards, loading } = useCanvasBoards({
    workspaceId,
  });

  if (loading) {
    return (
      <MainLayout>
        <Flex justifyContent="center" alignItems="center" height="100%">
          <LoadingIndicator />
        </Flex>
      </MainLayout>
    );
  }

  return (
    <MainLayout>
      <Box padding={20}>
        <h2>{'Canvas Boards'}</h2>
        <Flex gap={16} flexWrap="wrap">
          <CreateMiroBoard />
          {canvasBoards.map(canvasBoard => (
            <CanvasCard
              key={canvasBoard._id}
              canvasBoard={canvasBoard}
              workspaceId={workspaceId}
            />
          ))}
        </Flex>
      </Box>
    </MainLayout>
  );
};
export default Miro;
