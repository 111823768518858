import { gql } from '@apollo/client';

export const GET_PROJECT_LABELS = gql`
  query Labels($projectId: ID!) {
    labels(projectId: $projectId) {
      _id
      title
      color
    }
  }
`;
