import { gql } from '@apollo/client';

import {
  useCreateCanvasBoardMutation,
  useCanvasBoardsQuery,
  MutationCreateCanvasBoardArgs,
  useDeleteCanvasBoardMutation,
  useUpdateCanvasBoardMutation,
  MutationDeleteCanvasBoardArgs,
  MutationUpdateCanvasBoardArgs,
} from 'src/generated';

export const useCanvasBoards = ({ workspaceId }) => {
  const { data, loading, error } = useCanvasBoardsQuery({
    variables: { workspaceId },
  });

  const [createCanvasBoard] = useCreateCanvasBoardMutation({
    update: (cache, { data }) => {
      if (!data?.createCanvasBoard) return;
      cache.modify({
        fields: {
          canvasBoards(existingCanvasBoards = []) {
            const newCanvasBoardRef = cache.writeFragment({
              data: data.createCanvasBoard,
              fragment: gql`
                fragment NewCanvasBoard on CanvasBoard {
                  _id
                  title
                }
              `,
            });
            return [...existingCanvasBoards, newCanvasBoardRef];
          },
        },
      });
    },
  });

  const [deleteCanvasBoard] = useDeleteCanvasBoardMutation({
    update: (cache, { data }) => {
      if (!data?.deleteCanvasBoard) return;
      cache.evict({ id: cache.identify(data.deleteCanvasBoard) });
      cache.gc();
    },
  });

  const [updateCanvasBoard] = useUpdateCanvasBoardMutation();

  const onDeleteCanvasBoard = async ({
    workspaceId,
    boardId,
  }: MutationDeleteCanvasBoardArgs) => {
    await deleteCanvasBoard({
      variables: {
        workspaceId,
        boardId,
      },
    });
  };

  const onUpdateCanvasBoard = async ({
    boardId,
    title,
    previewImage,
  }: MutationUpdateCanvasBoardArgs) => {
    await updateCanvasBoard({
      variables: {
        boardId,
        title,
        previewImage,
      },
    });
  };

  const onCreateCanvasBoard = async ({
    workspaceId,
    title,
    previewImage,
  }: MutationCreateCanvasBoardArgs) => {
    await createCanvasBoard({
      variables: {
        workspaceId,
        title,
        previewImage,
      },
      optimisticResponse: {
        __typename: 'Mutation',
        createCanvasBoard: {
          __typename: 'CanvasBoard',
          _id: Math.random().toString(36).substr(2, 9),
          workspaceId,
          title,
          previewImage,
          createdBy: {
            __typename: 'User',
            _id: 'temp',
            email: 'temp',
            fullName: 'temp',
          },
        },
      },
    });
  };

  return {
    loading,
    error,
    canvasBoards: data?.canvasBoards || [],
    onCreateCanvasBoard,
    onDeleteCanvasBoard,
    onUpdateCanvasBoard,
  };
};
