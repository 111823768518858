import React from 'react';

import { useQuery } from '@apollo/client';

import TaskModal from './TaskModal';
import TaskModalSkeleton from './TaskModalSkeleton';
import { GET_ACTION } from 'src/graphql/actions';

const TaskModalLoader = ({ taskId, onClose }) => {
  const { loading, error, data } = useQuery(GET_ACTION, {
    variables: { id: taskId },
  });

  if (error) return null;

  if (loading) return <TaskModalSkeleton />;

  return <TaskModal action={data.action} onClose={onClose} />;
};

export default TaskModalLoader;
