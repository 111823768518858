import React from 'react';

import { useReactiveVar } from '@apollo/client';

import { Drawer } from 'src/components/design-system';

import TaskDescriptionHistoryLoader from './TaskDescriptionHistory';
import { openTaskDescriptionHistoryDrawer } from 'src/graphql/vars';

const TaskDescriptionHistoryDrawer = () => {
  const isOpen = useReactiveVar(openTaskDescriptionHistoryDrawer);

  return (
    <Drawer
      open={isOpen}
      width={500}
      onClose={() => openTaskDescriptionHistoryDrawer(false)}
    >
      <TaskDescriptionHistoryLoader />
    </Drawer>
  );
};

export default TaskDescriptionHistoryDrawer;
