import { Button, Flex } from 'src/components/design-system';
import styled from 'styled-components';

export const Container = styled(Flex)`
  padding: 10px 20px;
  border-bottom: 1px solid ${({ theme }) => theme.border.color.primary};
`;

type CompleteButtonProps = {
  isCompleted: boolean;
};
export const CompleteButton = styled(Button)<CompleteButtonProps>`
  color: ${({ theme, isCompleted }) => isCompleted && theme.color.success};
  background-color: ${({ theme, isCompleted }) =>
    isCompleted && theme.color.successLight};
  border-color: ${({ theme, isCompleted }) =>
    isCompleted && theme.color.success};
`;
