import { Flex, Button } from 'src/components/design-system';
import styled, { css } from 'styled-components';

type FilterButtonProps = {
  isFilterActive: boolean;
};

export const Container = styled(Flex)`
  background: ${p => p.theme.header.primary};
  border-bottom: 1px solid ${({ theme }) => theme.border.color.primary};
`;

export const FilterButton = styled(Button)<FilterButtonProps>`
  ${({ isFilterActive }) =>
    isFilterActive &&
    css`
      color: #1565c0;
    `}
`;
