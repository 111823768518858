import { Flex } from 'src/components/design-system';
import styled from 'styled-components';

export const Container = styled.div`
  border: 1px solid ${({ theme }) => theme.border.color.primary};
  padding: 10px;
  margin-bottom: 30px;
  border-radius: ${({ theme }) => theme.border.radius.sm};
`;

export const Title = styled.h4`
  margin: 0 0 10px 0;
`;

export const TrackingItem = styled(Flex)`
  border-top: 1px solid ${({ theme }) => theme.border.color.primary};
`;
