import styled from 'styled-components';

export const Container = styled.div`
  padding: 20px;
`;

export const Header = styled.div`
  text-align: center;

  h2 {
    font-size: 32px;
    margin: 0;
    font-weight: normal;
  }
  p {
    margin: 0;
    font-size: 16px;
  }
`;
