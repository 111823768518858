/**
 * index.tsx
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */
import { GoogleOAuthProvider } from '@react-oauth/google';
import React, { Suspense } from 'react';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';

// apollo
import { ApolloProvider } from '@apollo/client';

import { ConfirmServiceProvider } from 'src/components/design-system';
import { NotificationProvider } from 'src/components/design-system';

import { App } from './App';
import GqlClient from './gql-client';
import { datepickerSetup } from './lib/datepicker/datepicker';
import { emojiSetup } from './lib/emoji/emoji';
// Initialize languages
import './lib/i18n/locales/i18n';
import { sentrySetup } from './lib/sentry/sentry';
import 'rc-dropdown/assets/index.css';
import 'rc-tooltip/assets/bootstrap_white.css';
import 'sanitize.css/sanitize.css';
// Use consistent styling
import 'swiper/css';

import { ThemeProvider } from 'src/styles/theme/ThemeProvider';

sentrySetup();
emojiSetup();
datepickerSetup();

const MOUNT_NODE = document.getElementById('root') as HTMLElement;

const root = createRoot(MOUNT_NODE);

root.render(
  <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID!}>
    <HelmetProvider>
      <ThemeProvider>
        <ApolloProvider client={GqlClient}>
          <ConfirmServiceProvider>
            <NotificationProvider>
              <Suspense fallback="">
                <App />
              </Suspense>
            </NotificationProvider>
          </ConfirmServiceProvider>
        </ApolloProvider>
      </ThemeProvider>
    </HelmetProvider>
  </GoogleOAuthProvider>,
);
