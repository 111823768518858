import styled from 'styled-components/macro';

export const EditorContainer = styled.div`
  height: fit-content;
  padding: 5px;

  div[role='textbox'] {
    outline: none;
  }

  h1,
  h2,
  h3 {
    margin: 5px 0;
  }

  blockquote {
    border-left: 2px solid #ddd;
    margin-left: 0;
    margin-right: 0;
    padding-left: 10px;
    color: #aaa;
    font-style: italic;
  }

  table,
  th,
  td {
    border: 1px solid #ddd;
  }

  table {
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed;
  }

  .btnActive {
    opacity: 1;
  }

  .slate-wrapper {
    margin-top: 10px;
    border-radius: ${({ theme }) => theme.border.radius.sm};
  }

  ul,
  ol {
    padding-left: 15px;
  }

  a {
    color: #58a6ff;
  }

  p {
    margin: 0;
    font-size: ${({ theme }) => theme.font.size.sm};
  }

  table {
    width: 100%;
  }

  td {
    height: 50px;
    padding: 0 5px;
  }

  .popup-wrapper {
    display: inline;
    position: relative;
  }

  .popup {
    position: absolute;
    left: 0;
    background-color: white;
    padding: 6px 10px;
    height: fit-content;
    z-index: 1;
  }

  button {
    cursor: pointer;
  }

  .checklist-item-block {
    margin-right: 8px;
  }

  .checklist-item-input {
    border: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;

    &:checked + .checklist-item-custom-input {
      background: ${({ theme }) => theme.color.green500};
      svg {
        visibility: visible;
      }
    }
  }

  .checklist-item-custom-input {
    display: inline-flex;
    width: 15px;
    height: 15px;
    background: #fff;
    border: 1px solid ${({ theme }) => theme.color.green500};
    border-radius: ${({ theme }) => theme.border.radius.sm};
    transition: all 150ms;

    svg {
      fill: none;
      stroke: white;
      stroke-width: 2px;
      visibility: hidden;
    }
  }

  .checklist-item-text {
    text-decoration: none;

    &:focus {
      outline: none;
    }

    &.checked {
      text-decoration: line-through;
      opacity: 0.5;
    }
  }
`;
