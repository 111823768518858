import React from 'react';

import { useSelectionBounds } from '../hooks/use-selection-bounds';

import { useSelf, useStorage } from '../../../liveblocks.config';

import { LayerType, Side, XYWH } from '../types';

type SelectionBoxProps = {
  onResizePointerDown: (corner: Side, initialBounds: XYWH) => void;
};

const HANDLE_WIDTH = 8;
const SelectionBox = ({ onResizePointerDown }: SelectionBoxProps) => {
  const soleLayerId = useSelf(me =>
    me.presence.selection.length === 1 ? me.presence.selection[0] : null,
  );

  const bounds = useSelectionBounds();

  const isShowingHandles = useStorage(
    root =>
      soleLayerId && root.layers.get(soleLayerId)?.type !== LayerType.Path,
  );

  if (!bounds) return null;

  return (
    <>
      <rect
        fill="transparent"
        pointerEvents="none"
        stroke="#3b82f6"
        style={{
          transform: `translate(${bounds.x}px, ${bounds.y}px)`,
        }}
        x={0}
        y={0}
        width={bounds.width}
        height={bounds.height}
        strokeWidth={1}
      />
      {isShowingHandles && (
        <>
          <rect
            x={0}
            y={0}
            fill="#fff"
            stroke="#3b82f6"
            style={{
              cursor: 'nwse-resize',
              width: `${HANDLE_WIDTH}px`,
              height: `${HANDLE_WIDTH}px`,
              transform: `
                translate(
                  ${bounds.x - HANDLE_WIDTH / 2}px,
                  ${bounds.y - HANDLE_WIDTH / 2}px
                )
              `,
            }}
            onPointerDown={e => {
              e.stopPropagation();
              onResizePointerDown(Side.Top + Side.Left, bounds);
            }}
            strokeWidth={1}
          />
          <rect
            x={0}
            y={0}
            fill="#fff"
            stroke="#3b82f6"
            strokeWidth={1}
            style={{
              cursor: 'ns-resize',
              width: `${HANDLE_WIDTH}px`,
              height: `${HANDLE_WIDTH}px`,
              transform: `
                translate(
                  ${bounds.x + bounds.width / 2 - HANDLE_WIDTH / 2}px,
                  ${bounds.y - HANDLE_WIDTH / 2}px
                )
              `,
            }}
            onPointerDown={e => {
              e.stopPropagation();
              onResizePointerDown(Side.Top, bounds);
            }}
          />
          <rect
            x={0}
            y={0}
            fill="#fff"
            stroke="#3b82f6"
            strokeWidth={1}
            style={{
              cursor: 'nesw-resize',
              width: `${HANDLE_WIDTH}px`,
              height: `${HANDLE_WIDTH}px`,
              transform: `
                translate(
                  ${bounds.x - HANDLE_WIDTH / 2 + bounds.width}px,
                  ${bounds.y - HANDLE_WIDTH / 2}px
                )`,
            }}
            onPointerDown={e => {
              e.stopPropagation();
              onResizePointerDown(Side.Top + Side.Right, bounds);
            }}
          />
          <rect
            x={0}
            y={0}
            fill="#fff"
            stroke="#3b82f6"
            strokeWidth={1}
            style={{
              cursor: 'ew-resize',
              width: `${HANDLE_WIDTH}px`,
              height: `${HANDLE_WIDTH}px`,
              transform: `
                translate(
                  ${bounds.x - HANDLE_WIDTH / 2 + bounds.width}px,
                  ${bounds.y + bounds.height / 2 - HANDLE_WIDTH / 2}px
                )`,
            }}
            onPointerDown={e => {
              e.stopPropagation();
              onResizePointerDown(Side.Right, bounds);
            }}
          />
          <rect
            x={0}
            y={0}
            fill="#fff"
            stroke="#3b82f6"
            strokeWidth={1}
            style={{
              cursor: 'nwse-resize',
              width: `${HANDLE_WIDTH}px`,
              height: `${HANDLE_WIDTH}px`,
              transform: `
                translate(
                  ${bounds.x - HANDLE_WIDTH / 2 + bounds.width}px,
                  ${bounds.y - HANDLE_WIDTH / 2 + bounds.height}px
                )`,
            }}
            onPointerDown={e => {
              e.stopPropagation();
              onResizePointerDown(Side.Bottom + Side.Right, bounds);
            }}
          />
          <rect
            x={0}
            y={0}
            fill="#fff"
            stroke="#3b82f6"
            strokeWidth={1}
            style={{
              cursor: 'ns-resize',
              width: `${HANDLE_WIDTH}px`,
              height: `${HANDLE_WIDTH}px`,
              transform: `
                translate(
                  ${bounds.x + bounds.width / 2 - HANDLE_WIDTH / 2}px,
                  ${bounds.y - HANDLE_WIDTH / 2 + bounds.height}px
                )`,
            }}
            onPointerDown={e => {
              e.stopPropagation();
              onResizePointerDown(Side.Bottom, bounds);
            }}
          />
          <rect
            x={0}
            y={0}
            fill="#fff"
            stroke="#3b82f6"
            strokeWidth={1}
            style={{
              cursor: 'nesw-resize',
              width: `${HANDLE_WIDTH}px`,
              height: `${HANDLE_WIDTH}px`,
              transform: `
                translate(
                  ${bounds.x - HANDLE_WIDTH / 2}px,
                  ${bounds.y - HANDLE_WIDTH / 2 + bounds.height}px
                )`,
            }}
            onPointerDown={e => {
              e.stopPropagation();
              onResizePointerDown(Side.Bottom + Side.Left, bounds);
            }}
          />
          <rect
            fill="#fff"
            x={0}
            y={0}
            stroke="#3b82f6"
            strokeWidth={1}
            style={{
              cursor: 'ew-resize',
              width: `${HANDLE_WIDTH}px`,
              height: `${HANDLE_WIDTH}px`,
              transform: `
                translate(
                  ${bounds.x - HANDLE_WIDTH / 2}px,
                  ${bounds.y - HANDLE_WIDTH / 2 + bounds.height / 2}px
                )`,
            }}
            onPointerDown={e => {
              e.stopPropagation();
              onResizePointerDown(Side.Left, bounds);
            }}
          />
        </>
      )}
    </>
  );
};

export default SelectionBox;
