import React from 'react';

import { useReactiveVar } from '@apollo/client';

import { Drawer } from 'src/components/design-system';

import OpenAI from './OpenAI';
import { openOpenAIDrawer } from 'src/graphql/vars';

const OpenAIDrawer = () => {
  const isOpen = useReactiveVar(openOpenAIDrawer);

  return (
    <Drawer
      open={isOpen}
      width={700}
      mask={false}
      onClose={() => openOpenAIDrawer(false)}
    >
      <OpenAI />
    </Drawer>
  );
};

export default OpenAIDrawer;
