import React from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';

import Action from '../Action/Action';
import AddAction from '../Action/AddAction/AddAction';
import TaskPreview from '../Action/TaskPreview/TaskPreview';
import ColumnHeader from './ColumnHeader/ColumnHeader';
import { isEmpty } from 'lodash';

import {
  Container,
  Head,
  EmptyText,
  ColumnList,
  TaskPreviewContainer,
} from './styles';

const Column = ({
  column,
  index,
  placeholderProps,
  dragOverlay,
  isDraggingOver,
}) => {
  const { _id, actions } = column;

  const { t } = useTranslation();

  return (
    <Draggable draggableId={_id} index={index}>
      {(provided, snapshot) => (
        <Container
          {...provided.draggableProps}
          ref={provided.innerRef}
          isDragging={snapshot.isDragging}
          isDraggingOver={!!snapshot.draggingOver}
        >
          <div {...provided.dragHandleProps}>
            <Head>
              <ColumnHeader column={column} />
              <AddAction statusId={_id} />
            </Head>
          </div>

          <Droppable droppableId={_id} type="task">
            {(provided, snapshot) => (
              <ColumnList
                {...provided.droppableProps}
                ref={provided.innerRef}
                isDraggingOver={snapshot.isDraggingOver}
                isEmpty={!actions.length}
              >
                <>
                  {actions.map((action, idx) => (
                    <Action key={action._id} action={action} index={idx} />
                  ))}

                  {!actions.length && !snapshot.isDraggingOver && (
                    <EmptyText>{t('project.noTaskInColumn')}</EmptyText>
                  )}
                </>
                {provided.placeholder}
                {!isEmpty(placeholderProps) &&
                  snapshot.isDraggingOver &&
                  dragOverlay && (
                    <TaskPreviewContainer top={placeholderProps.clientY}>
                      <TaskPreview task={dragOverlay} />
                    </TaskPreviewContainer>
                  )}
              </ColumnList>
            )}
          </Droppable>
        </Container>
      )}
    </Draggable>
  );
};

export default Column;
