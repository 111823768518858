import { gql } from '@apollo/client';

gql`
  mutation CreateComment(
    $text: String
    $taskId: ID!
    $type: CommentTypeEnum!
    $sticker: String
    $projectId: ID!
  ) {
    createComment(
      input: {
        text: $text
        taskId: $taskId
        type: $type
        sticker: $sticker
        projectId: $projectId
      }
    ) {
      _id
      text
      taskId
      type
      sticker
      createdAt
    }
  }
`;

gql`
  mutation UpdateComment($commentId: ID!, $text: String!) {
    updateComment(input: { commentId: $commentId, text: $text }) {
      _id
      text
      isEdited
    }
  }
`;

gql`
  mutation DeleteComment($id: ID!) {
    deleteComment(id: $id) {
      _id
    }
  }
`;

gql`
  mutation AddReaction($commentId: ID!, $emoji: String!) {
    addReaction(input: { commentId: $commentId, emoji: $emoji }) {
      _id
      reactions {
        id
        emoji
        user {
          _id
          fullName
        }
      }
    }
  }
`;

gql`
  mutation RemoveReaction($commentId: ID!, $emoji: String!) {
    removeReaction(input: { commentId: $commentId, emoji: $emoji }) {
      _id
      reactions {
        id
        emoji
      }
    }
  }
`;
