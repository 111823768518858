import { openUploadBookModalVar } from 'src/graphql/vars';
import { useUserWorkspace } from 'src/store';

import {
  useBooksQuery,
  useUploadBookMutation,
  useUpdateUserListeningMutation,
} from 'src/generated';

export const useBooks = () => {
  const workspaceId = useUserWorkspace();
  const { data, loading, error } = useBooksQuery({
    variables: {
      workspaceId,
    },
  });

  const [uploadBook, { loading: uploadBookLoading }] = useUploadBookMutation();

  const [updateUserListening] = useUpdateUserListeningMutation({
    update: (cache, { data }) => {
      if (!data?.updateUserListening) return;
      const { listening } = data.updateUserListening;
      cache.modify({
        id: cache.identify(data.updateUserListening),
        fields: {
          listening() {
            return listening;
          },
        },
      });
    },
  });

  return {
    error,
    loading,
    books: data?.books || [],
    uploadBookLoading,
    onUploadBook: async ({ title, poster, chapters }) => {
      await uploadBook({
        variables: {
          input: {
            title,
            poster,
            chapters,
          },
        },
        refetchQueries: ['Books'],
      });
      openUploadBookModalVar(false);
    },
    onUpdateBookListening: async ({ chapterId, listenedTime }) => {
      await updateUserListening({
        variables: {
          chapterId,
          listenedTime,
        },
      });
    },
  };
};
