import React from 'react';

type TgsStickerProps = {
  url: string;
  autoplay?: boolean;
  size?: number;
  count?: number;
  loop?: boolean;
  hover?: boolean;
};

const TgsPlayer = ({
  url,
  size = 200,
  hover = true,
  ...rest
}: TgsStickerProps) => {
  return (
    <tgs-player
      {...rest}
      hover={hover}
      mode="normal"
      src={url}
      style={{ height: `${size}px`, width: `${size}px`, margin: 0 }}
      renderer="svg"
    />
  );
};

export default TgsPlayer;
