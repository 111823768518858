import React from 'react';

import { ImageBlock } from './styles';

const Image = ({ attributes, element, children }) => {
  const { url } = element;

  return (
    <div {...attributes}>
      <ImageBlock contentEditable={false}>
        <img alt={element.alt} src={url} />
      </ImageBlock>
      {children}
    </div>
  );
};
export default Image;
