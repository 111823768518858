import React from 'react';
import { useSlateStatic } from 'slate-react';

import { gql, useMutation } from '@apollo/client';

import { Icon, DEFAULT_EDITOR_DATA } from 'src/components/design-system';

import { Transforms } from 'slate';

import { UploadImageLabel } from './styles';

export const UPLOAD_FILE = gql`
  mutation UploadFile($file: Upload!) {
    uploadFile(input: { file: $file })
  }
`;

const UploadImage = () => {
  const editor = useSlateStatic();

  const [uploadFile] = useMutation(UPLOAD_FILE, {
    onCompleted: data => {
      const image = {
        type: 'image',
        url: data.uploadFile,
        children: [{ text: '' }],
      };
      Transforms.insertNodes(editor, image);
      Transforms.insertNodes(editor, DEFAULT_EDITOR_DATA);
    },
  });

  const handleUploadFiles = async e => {
    const file = e.target.files[0];

    try {
      await uploadFile({
        variables: {
          file,
        },
        context: {
          fetchOptions: {
            useUpload: true,
            onProgress: (ev: ProgressEvent) => {
              console.log(ev, 'ev');
            },
          },
        },
      });
    } catch (e) {
      console.log(e, 'e');
    }
  };

  return (
    <>
      <UploadImageLabel htmlFor="uploadFile">
        <Icon name="upload" size={18} />
      </UploadImageLabel>
      <input id="uploadFile" type="file" hidden onChange={handleUploadFiles} />
    </>
  );
};

export default UploadImage;
