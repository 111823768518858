import { Button } from 'src/components/design-system';
import styled from 'styled-components';

type Props = {
  isOpen: boolean;
};
export const Container = styled.div<Props>`
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 90px;
  padding: 0 16px;
  justify-content: space-between;
  z-index: 100;
  background: ${({ theme }) => theme.background.secondary};
  position: fixed;
  left: 0;
  bottom: ${({ isOpen }) => (isOpen ? '0' : '-90px')};
  width: 100%;
`;

export const CollapsedButton = styled(Button)`
  position: absolute;
  right: 30px;
  top: -20px;
  background: ${({ theme }) => theme.background.secondary};
`;
