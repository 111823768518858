export const COLUMN_MORE_MENU = {
  EDIT: 'edit',
  DELETE: 'delete',
};

export const columnMoreMenu = [
  {
    id: COLUMN_MORE_MENU.EDIT,
    icon: 'edit',
    title: 'project.statuses.edit',
  },
  {
    id: COLUMN_MORE_MENU.DELETE,
    icon: 'trash',
    title: 'project.statuses.delete',
    danger: true,
  },
];
