import { Row } from '@tanstack/react-table';
import React, { memo } from 'react';

import { Icon } from 'src/components/design-system';

import { useSortable } from '@dnd-kit/sortable';

import { TableTask } from '../types';

import * as Styled from './styles';

type RowDragHandleCellProps = {
  row: Row<TableTask>;
};

const RowDragHandleCell = ({ row }: RowDragHandleCellProps) => {
  const rowId = row.id;
  const { attributes, listeners } = useSortable({
    id: rowId,
  });

  return (
    // Alternatively, you could set these attributes on the rows themselves
    <Styled.IconWrapper {...attributes} {...listeners}>
      <Icon name="drag-vertical" />
    </Styled.IconWrapper>
  );
};

export default memo(RowDragHandleCell);
