import styled from 'styled-components';

export const TitleBlock = styled.div`
  width: 100%;

  input {
    font-weight: 500;
    outline: none;
    font-size: 15px;
    border: none;
    background: transparent;
    width: 100%;
    color: ${p => p.theme.text.primary};
  }
`;
