import { flexRender, Header } from '@tanstack/react-table';
import React, { CSSProperties } from 'react';

import { Button, Icon, Flex } from 'src/components/design-system';

import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { TableTask } from '../types';

import * as Styled from './styles';

type DraggableTableHeaderProps = {
  header: Header<TableTask, unknown>;
};

const DraggableTableHeader = ({ header }: DraggableTableHeaderProps) => {
  const { attributes, isDragging, listeners, setNodeRef, transform } =
    useSortable({
      id: header.column.id,
      disabled: false,
    });

  const style: CSSProperties = {
    transform: CSS.Translate.toString(transform), // translate instead of transform to avoid squishing
    transition: 'width transform 0.2s ease-in-out',
    whiteSpace: 'nowrap',
    width: header.column.getSize(),
    zIndex: isDragging ? 2 : 'auto',
    position: 'relative',
  };

  const isAllowToMove = ![
    'drag-handle',
    'count',
    'selection',
    'title',
  ].includes(header.column.id);

  return (
    <Styled.Container>
      <Styled.TableHeadCell
        ref={setNodeRef}
        style={style}
        {...(isAllowToMove ? { ...listeners, ...attributes } : {})}
      >
        <Flex justifyContent="space-between" alignItems="center" width="100%">
          {header.isPlaceholder
            ? null
            : flexRender(header.column.columnDef.header, header.getContext())}

          {isAllowToMove && (
            <>
              <Button htmlType="link">
                <Icon name="drag-horizontal" />
              </Button>
            </>
          )}
        </Flex>
      </Styled.TableHeadCell>
      {isAllowToMove && header.column.getCanResize() && (
        <Styled.Resizer
          onMouseDown={header.getResizeHandler()}
          onTouchStart={header.getResizeHandler()}
          isResizing={header.column.getIsResizing()}
        ></Styled.Resizer>
      )}
    </Styled.Container>
  );
};

export default DraggableTableHeader;
