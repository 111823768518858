import { Tag } from 'src/components/design-system';
import styled from 'styled-components';

export const LabelsDropdown = styled.div`
  padding: 10px;
`;

export const LabelBlock = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 8px;
`;

export const Footer = styled.div`
  padding-top: 10px;
  margin-top: 5px;
  border-top: 1px solid ${({ theme }) => theme.border.color.primary};

  button {
    width: 100%;
  }
`;

export const AddLabelButton = styled.button`
  border: none;
  background: transparent;
  margin-left: 10px;
`;

export const EditingBlock = styled.div`
  grid-template-rows: repeat(6, auto);
  display: grid;
  grid-auto-flow: column;
  overflow-x: auto;
  overflow-y: hidden;
  grid-column-gap: 8px;
`;

export const InputBlock = styled.div`
  line-height: 32px;
  display: flex;
  align-content: center;
  user-select: none;
`;

export const InputWrapper = styled.div`
  display: flex;
  border-radius: ${({ theme }) => theme.border.radius.sm};
  height: 32px;
  width: 152px;
  flex: 0 0 152px;
  align-items: center;
  padding: 4px;
  overflow: hidden;
  border: 1px solid ${({ theme }) => theme.border.color.primary};
  background: ${({ theme }) => theme.background.primary};

  input {
    will-change: flex, width;
    flex: 0 0 128px;
    width: 128px;
    height: 100%;
    outline: none;
    margin-left: 4px;
    padding-right: 4px;
    border: none;
    min-width: 0;
    background: transparent;
    color: ${({ theme }) => theme.text.primary};
`;

export const InputWrapperColor = styled.div`
  flex: 0 0 24px;
  height: 24px;
  width: 24px;
  border-radius: ${({ theme }) => theme.border.radius.sm};
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LabelTag = styled(Tag)`
  width: 100%;
  height: 32px;
  line-height: 32px;
  text-align: center;
  border-radius: ${({ theme }) => theme.border.radius.sm};
  padding: 0 4px;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const LabelItemBlock = styled.div`
  width: 128px;
  font-weight: 400;
  cursor: pointer;
  position: relative;
  margin-bottom: 8px;
  border-radius: ${({ theme }) => theme.border.radius.sm};
`;

export const ColorsList = styled.ul`
  width: 140px;
  cursor: pointer;
  position: relative;
  border-radius: ${({ theme }) => theme.border.radius.sm};
  flex-wrap: wrap;
  display: flex;
  padding: 6px;
  margin: 0;
`;

export const ColorsDropdown = styled.div``;

export const ColorItemHover = styled.div``;

export const ColorItemWrapper = styled.li`
  margin: 4px;
  display: flex;
  list-style-type: none;
  position: relative;

  &:hover ${ColorItemHover} {
    content: '';
    position: absolute;
    border-radius: ${({ theme }) => theme.border.radius.sm};
    inset: -4px;
    border: 4px solid #dcdfec;
    pointer-events: none;
  }
`;

export const ColorItem = styled.div`
  width: 24px;
  height: 24px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: ${({ theme }) => theme.border.radius.sm};
  position: relative;
`;

export const DeleteLabelBlock = styled.div`
  margin-left: 2px;

  button {
    width: 18px;
    height: 18px;
  }
`;
