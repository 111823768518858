import React from 'react';

import { IColumnType } from '../Table';
import get from 'lodash/get';

import { Cell } from './styles';

interface Props<T> {
  item: T;
  column: IColumnType<T>;
  index: number;
}

const TableCell = <T,>({ item, column, index }: Props<T>): JSX.Element => {
  if (column.key === 'count') {
    return (
      <Cell>
        <strong>{index + 1}</strong>
      </Cell>
    );
  }

  const value = get(item, column.key);
  return <Cell>{column.render ? column.render(column, item) : value}</Cell>;
};

export default TableCell;
