import React from 'react';
import { useSearchParams } from 'react-router-dom';

import { menu } from './constants';

import * as Styled from './styles';

const FormHeader = () => {
  const [searchParams] = useSearchParams();
  const currentView = searchParams.get('view');

  return (
    <Styled.Container>
      {menu.map(({ title, view }) => (
        <Styled.MenuLink
          key={title}
          to={`/forms?view=${view}`}
          active={currentView === view}
        >
          {title}
        </Styled.MenuLink>
      ))}
    </Styled.Container>
  );
};

export default FormHeader;
