export const COEFFICIENT_FOR_RANDOM = 0.8;

export const calculateTaskRank = (aboveTask, belowTask) => {
  let rank;

  const ranks: number[] = [];
  const coefficient = belowTask && !aboveTask ? -1 : 1;

  const randomCoefficient =
    Math.random() * (1 - COEFFICIENT_FOR_RANDOM) + COEFFICIENT_FOR_RANDOM;

  if (aboveTask) {
    ranks.push(aboveTask.rank);
  }

  if (belowTask) {
    ranks.push(belowTask.rank);
  }

  if (ranks.length === 1) {
    rank = ranks[0] + randomCoefficient * coefficient;
  }

  if (ranks.length === 2) {
    rank = (ranks[0] + ranks[1]) / 2;
  }
  return rank;
};
