import React from 'react';
import { useFocused, useSelected, useSlateStatic } from 'slate-react';

import { Button, Icon } from 'src/components/design-system';

import { removeLink } from '../../utils/link.js';

import { LinkContainer, LinkPopup } from './styles';

const Link = ({ attributes, element, children }) => {
  const editor = useSlateStatic();
  const selected = useSelected();
  const focused = useFocused();

  return (
    <LinkContainer>
      <a
        {...attributes}
        href={element.href}
        target="_blank"
        rel="noopener noreferrer"
      >
        {children}
      </a>
      {selected && focused && (
        <LinkPopup contentEditable="false">
          <a target="_blank" rel="noopener noreferrer" href={element.href}>
            {element.href}
          </a>
          <Button size="small" type="button" onClick={() => removeLink(editor)}>
            <Icon name="unlink" />
          </Button>
        </LinkPopup>
      )}
    </LinkContainer>
  );
};

export default Link;
