import styled from 'styled-components';

type LiveWidthProps = {
  width: number;
};

export const Container = styled.div`
  padding-top: 10px;
  background-color: ${({ theme }) => theme.background.secondary};
  border-radius: ${({ theme }) => theme.border.radius.sm};
`;

export const Body = styled.div`
  position: relative;
  & .react-datepicker {
    line-height: 16px;
    display: block;
    font-weight: ${({ theme }) => theme.font.weight.regular};
    border-color: ${({ theme }) => theme.border.color.light};
    background: ${({ theme }) => theme.background.secondary};
    font-size: ${({ theme }) => theme.font.size.xs};
    border: none;
  }

  & .react-datepicker-popper {
    position: relative !important;
    inset: auto !important;
    transform: none !important;
    padding: 0 !important;
  }

  & .react-datepicker__triangle::after {
    display: none;
  }

  & .react-datepicker__triangle::before {
    display: none;
  }

  & .react-datepicker-wrapper {
    display: none;
  }

  // Header

  & .react-datepicker__header {
    background: transparent;
    border: none;
  }

  & .react-datepicker__header__dropdown {
    display: flex;
    color: ${({ theme }) => theme.font.color.primary};
    margin-left: ${({ theme }) => theme.spacing(1)};
    margin-bottom: ${({ theme }) => theme.spacing(1)};
  }

  & .react-datepicker__month-dropdown-container,
  & .react-datepicker__year-dropdown-container {
    text-align: left;
    border-radius: ${({ theme }) => theme.border.radius.sm};
    margin-left: ${({ theme }) => theme.spacing(1)};
    margin-right: 0;
    padding: ${({ theme }) => theme.spacing(2)};
    padding-right: ${({ theme }) => theme.spacing(4)};
    background-color: ${({ theme }) => theme.background.tertiary};
  }

  & .react-datepicker__month-read-view--down-arrow,
  & .react-datepicker__year-read-view--down-arrow {
    display: none;
  }

  & .react-datepicker__year-read-view,
  & .react-datepicker__month-read-view {
    padding-right: ${({ theme }) => theme.spacing(2)};
  }

  & .react-datepicker__month-dropdown-container {
    width: 110px;
  }

  & .react-datepicker__year-dropdown-container {
    width: 70px;
  }

  & .react-datepicker__month-dropdown,
  & .react-datepicker__year-dropdown {
    border: ${({ theme }) => theme.border.color.light};
    box-shadow: ${({ theme }) => theme.boxShadow.md};
    backdrop-filter: blur(8px);
    background: #fff;
    overflow-y: scroll;
    top: ${({ theme }) => theme.spacing(2)};
  }
  & .react-datepicker__month-dropdown {
    left: ${({ theme }) => theme.spacing(2)};
    width: 160px;
    height: 260px;
  }

  & .react-datepicker__year-dropdown {
    left: calc(${({ theme }) => theme.spacing(9)} + 80px);
    width: 100px;
    height: 260px;
  }

  & .react-datepicker__navigation--years {
    display: none;
  }

  & .react-datepicker__month-option--selected,
  & .react-datepicker__year-option--selected {
    display: none;
  }

  & .react-datepicker__year-option,
  & .react-datepicker__month-option {
    text-align: left;
    padding: ${({ theme }) => theme.spacing(2)}
      calc(${({ theme }) => theme.spacing(2)} - 2px);
    width: calc(100% - ${({ theme }) => theme.spacing(4)});
    border-radius: ${({ theme }) => theme.border.radius.xs};
    color: ${({ theme }) => theme.font.color.secondary};
    cursor: pointer;
    margin: 2px;

    &:hover {
      background: ${({ theme }) => theme.color.white};
    }
  }

  & .react-datepicker__year-option {
    &:first-of-type,
    &:last-of-type {
      display: none;
    }
  }

  & .react-datepicker__current-month {
    display: none;
  }

  & .react-datepicker__day-name {
    color: ${({ theme }) => theme.font.color.secondary};
    width: 34px;
    height: 40px;
    line-height: 40px;
  }

  & .react-datepicker__month-container {
    float: none;
  }

  // Days
  & .react-datepicker__month {
    margin-top: 0;
  }

  & .react-datepicker__day {
    width: 38px;
    height: 38px;
    line-height: 38px;
    margin: 0;
  }

  & .react-datepicker__navigation--previous,
  & .react-datepicker__navigation--next {
    height: 34px;
    border-radius: ${({ theme }) => theme.border.radius.sm};
    padding-top: 6px;
    &:hover {
      background: ${({ theme }) => theme.background.tertiary};
    }
  }
  & .react-datepicker__navigation--previous {
    right: 38px;
    top: 8px;
    left: auto;

    & > span {
      margin-left: -6px;
    }
  }

  & .react-datepicker__navigation--next {
    right: 6px;
    top: 8px;

    & > span {
      margin-left: 6px;
    }
  }

  & .react-datepicker__navigation-icon::before {
    height: 7px;
    width: 7px;
    border-width: 1px 1px 0 0;
    border-color: ${({ theme }) => theme.font.color.tertiary};
  }

  & .react-datepicker__day--keyboard-selected {
    background-color: inherit;
  }

  & .react-datepicker__day,
  .react-datepicker__time-name {
    color: ${({ theme }) => theme.font.color.primary};
    outline: none;
  }

  & .react-datepicker__day:hover {
    color: ${({ theme }) => theme.font.color.tertiary};
  }

  & .react-datepicker__day--today {
    color: ${({ theme }) => theme.color.orange800};
    border-radius: ${({ theme }) => theme.border.radius.md};
    &:hover {
      color: ${({ theme }) => theme.color.orange800};
    }
  }

  & .react-datepicker__day--selected,
  & .react-datepicker__day--in-range {
    background-color: ${({ theme }) => theme.color.primary};
    color: ${({ theme }) => theme.font.color.inverted};
    font-weight: normal;
    &:hover {
      color: ${({ theme }) => theme.color.white};
    }
  }

  & .react-datepicker__day--in-range {
    border-radius: 0;
  }

  & .react-datepicker__day--range-start {
    border-top-left-radius: ${({ theme }) => theme.border.radius.md};
    border-bottom-left-radius: ${({ theme }) => theme.border.radius.md};
  }

  & .react-datepicker__day--range-end {
    border-top-right-radius: ${({ theme }) => theme.border.radius.md};
    border-bottom-right-radius: ${({ theme }) => theme.border.radius.md};
  }

  & .react-datepicker__day--outside-month {
    color: ${({ theme }) => theme.font.color.tertiary};
  }
`;

export const SelectDate = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: ${({ theme }) => theme.text.secondary};
  font-size: 14px;
  &:hover {
    color: ${({ theme }) => theme.text.active};
  }

  svg {
    margin-right: 5px;
    margin-bottom: 2px;
  }
`;
export const Divider = styled.span`
  padding: 0 5px;
`;

export const LineContainer = styled.div`
  margin-top: 4px;
  width: 100%;
  background: #dcd8d8;
  height: 10px;
  position: relative;
  border-radius: ${({ theme }) => theme.border.radius.sm};
  overflow: hidden;
`;

export const LiveWidth = styled.span<LiveWidthProps>`
  width: ${props => props.width}%;
  background: #f5a623;
  height: 10px;
  position: absolute;
  top: 0;
  left: 0;
`;

export const DaysHeaderDay = styled.span`
  font-weight: bold;
  color: cornflowerblue;
  padding: 10px 0 10px 5px;
`;

export const DaysRangeInfo = styled.div`
  padding: 10px;
  background: ${({ theme }) => theme.background.secondary};
  color: ${({ theme }) => theme.text.secondary};
  border-radius: ${({ theme }) => theme.border.radius.sm};
  border: 1px solid ${({ theme }) => theme.border.color.primary};
  margin-left: 17px;
`;
export const DaysRangeCount = styled.span`
  font-weight: bold;
  color: ${({ theme }) => theme.text.primary};
  margin: 0 5px;
`;
