import React from 'react';

import { colorToCss } from '../utils/utils';

import { EllipseLayer } from '../types';

type EllipseProps = {
  id: string;
  layer: EllipseLayer;
  onPointerDown: (e: React.PointerEvent<SVGEllipseElement>, id: string) => void;
  selectionColor?: string;
};

const Ellipse = ({
  id,
  layer,
  onPointerDown,
  selectionColor,
}: EllipseProps) => {
  const { x, y, width, height, fill } = layer;
  return (
    <ellipse
      onPointerDown={e => onPointerDown(e, id)}
      style={{
        transform: `translate(
          ${x}px,
          ${y}px
        )`,
        filter:
          'drop-shadow(0 4px 3px rgb(0 0 0 / 0.07)) drop-shadow(0 2px 2px rgb(0 0 0 / 0.06)',
      }}
      cx={width / 2}
      cy={height / 2}
      rx={width / 2}
      ry={height / 2}
      fill={fill ? colorToCss(fill) : '#000'}
      stroke={selectionColor || 'transparent'}
      strokeWidth={1}
    />
  );
};

export default Ellipse;
