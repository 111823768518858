import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { Modal, Tooltip, Icon } from 'src/components/design-system';

import { useProject } from 'src/helpers/apollo';

import TaskModalLoader from './TaskModalLoader';

import { Header, CloseButton } from './styles';

const TaskModalContainer = () => {
  const { project } = useProject();
  const locations = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const query = new URLSearchParams(locations.search);
  const taskId = query.get('taskId');

  const [isOpen, setIsOpen] = useState(Boolean(taskId));

  useEffect(() => {
    if (taskId) setIsOpen(true);
    if (!taskId) setIsOpen(false);
  }, [taskId]);

  const handleCloseModal = () => {
    setIsOpen(false);
    query.delete('taskId');
    navigate({ search: query.toString() });
  };

  const { color } = project;

  if (!isOpen) return null;

  return (
    <Modal
      width={1000}
      isOpen={isOpen}
      showCloseButton={false}
      showTopBorder={false}
      onRequestClose={handleCloseModal}
      style={{ content: { padding: 0 } }}
    >
      <Header bg={color}>
        <Tooltip overlay={t('general.close')} placement="top">
          <CloseButton
            size="small"
            htmlType="text"
            onClick={handleCloseModal}
            icon={<Icon name="close" size={24} color="#fff" />}
          />
        </Tooltip>
      </Header>
      <TaskModalLoader taskId={taskId} onClose={handleCloseModal} />
    </Modal>
  );
};

export default TaskModalContainer;
