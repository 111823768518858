import { Button } from 'src/components/design-system';
import styled from 'styled-components';

export const Card = styled.div`
  cursor: pointer;
  position: relative;
  width: 230px;
  border: 1px solid ${({ theme }) => theme.border.color.primary};
  border-radius: ${({ theme }) => theme.border.radius.md};
  &:hover {
    background: ${({ theme }) => theme.background.hover};
  }
`;
export const CardTitle = styled.h4`
  margin: 0;
`;

export const CardImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: ${({ theme }) => theme.border.radius.md};
`;
export const MoreButton = styled(Button)`
  position: absolute;
  top: 10px;
  right: 10px;
`;
