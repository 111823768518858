import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { useApolloClient } from '@apollo/client';

import {
  useNotification,
  playConfettiAnimation,
} from 'src/components/design-system';

import { useProject } from '../helpers/apollo';

import { useQueryParams } from './useQueryParams';
import omit from 'lodash/omit';
import uniqBy from 'lodash/uniqBy';
import {
  GET_PROJECT_TASKS_FOR_BOARD_VIEW,
  GET_TASKS,
  TASK_FRAGMENT,
} from 'src/graphql/actions';
import { useUserWorkspace } from 'src/store';

import {
  useDeleteTasksMutation,
  useCreateTaskMutation,
  useDuplicateTaskMutation,
  KanbanTasksQuery,
  useUpdateTaskMutation,
  UpdateTaskInput,
  GetTasksDocument,
  KanbanTasksDocument,
  CreateTaskMutationVariables,
} from 'src/generated';

type CreateTaskMutationProps = Omit<
  CreateTaskMutationVariables,
  'workspaceId' | 'projectId'
>;

export const useTask = () => {
  const client = useApolloClient();
  const { id: projectId } = useParams() as any;
  const query = useQueryParams();
  const navigate = useNavigate();
  const taskId = query.get('taskId') as string;
  const workspaceId = useUserWorkspace();
  const [searchParams] = useSearchParams();
  const {
    project: { color, completedStatusId },
    labels,
  } = useProject();
  const viewType = searchParams.get('view');
  const notification = useNotification();

  const [updateTask] = useUpdateTaskMutation();
  const [deleteTask] = useDeleteTasksMutation();
  const [duplicateTask] = useDuplicateTaskMutation();
  const [createTask] = useCreateTaskMutation();

  const onDuplicateTask = async ({ taskId }) => {
    await duplicateTask({
      variables: { taskId },
      update(cache, { data }) {
        if (!data?.duplicateTask) return;
        const { duplicateTask } = data;

        const cached = cache.readQuery<KanbanTasksQuery>({
          query: GET_PROJECT_TASKS_FOR_BOARD_VIEW,
          variables: { projectId },
        });
        if (!cached) return;
        const { kanbanTasks } = cached;

        cache.writeQuery({
          query: GET_PROJECT_TASKS_FOR_BOARD_VIEW,
          variables: { projectId },
          data: {
            kanbanTasks: kanbanTasks.map(column => {
              if (column._id === duplicateTask.statusId) {
                return {
                  ...column,
                  tasksCount: column.tasksCount + 1,
                  actions: [duplicateTask, ...column.actions],
                };
              }
              return column;
            }),
          },
        });
      },
      onCompleted: ({ duplicateTask }) => {
        const { _id } = duplicateTask;

        notification.show({
          title: 'Task duplicated',
          message: 'Task was successfully duplicated',
          variant: 'success',
        });
        query.set('taskId', _id);
        navigate({
          search: query.toString(),
        });
      },
    });
  };

  const onDeleteTasks = async ({ taskIds }) => {
    await deleteTask({
      variables: { taskIds, projectId },
      optimisticResponse: {
        __typename: 'Mutation',
        deleteTasks: taskIds.map((id: string) => ({
          __typename: 'Action',
          _id: id,
        })),
      },
      update(cache, { data }) {
        if (!data?.deleteTasks) return;
        const { deleteTasks } = data;

        deleteTasks.forEach((deletedTask: any) => {
          cache.evict({ id: cache.identify(deletedTask) });
        });
        cache.gc();
      },
      onCompleted: () => {
        notification.show({
          title: 'Task(s) deleted',
          message: 'Task(s) was successfully deleted',
          variant: 'success',
        });
      },
    });
  };

  const onCreateTask = ({
    title,
    statusId,
    parentId,
    dueDate,
  }: CreateTaskMutationProps) => {
    createTask({
      variables: {
        workspaceId,
        projectId,
        statusId,
        title,
        parentId,
        dueDate,
      },
      optimisticResponse: {
        __typename: 'Mutation',
        createTask: {
          __typename: 'Action',
          _id: 'temp-id',
          workspaceId: workspaceId || 'temp-workspace-id',
          projectId: {
            _id: projectId || null,
            color,
          },
          parentId: {
            _id: parentId || '',
            title: 'temp-parent-title',
          },
          title,
          statusId: statusId || 'temp-status-id',
          labels: [],
          dueDate,
        },
      },
      update(cache, { data }) {
        if (!data?.createTask) return;
        const { statusId, parentId } = data.createTask;
        const taskParent = parentId?._id;

        if (taskParent) {
          cache.updateQuery(
            {
              query: GetTasksDocument,
              variables: { workspaceId, parentId: taskParent },
            },

            getTasksData => {
              const tasks = getTasksData?.getTasks || [];

              return {
                getTasks: [...tasks, data.createTask],
              };
            },
          );
          //  update parent totalSubtasksCount field
          cache.modify({
            id: cache.identify({
              _id: taskId,
              __typename: 'Action',
            }),
            fields: {
              totalSubtasksCount: (prev: number) => prev + 1,
            },
          });
        } else {
          // update task calendar view
          cache.updateQuery(
            {
              query: GET_TASKS,
              variables: { projectId, workspaceId },
            },
            response => {
              if (!response) return;
              const getTasks = response?.getTasks || [];
              return {
                getTasks: [...getTasks, data?.createTask],
              };
            },
          );

          // optimistic response for kanban view
          cache.updateQuery(
            {
              query: KanbanTasksDocument,
              variables: { projectId },
            },
            cachedTasks => {
              const { kanbanTasks } = cachedTasks;
              return {
                kanbanTasks: kanbanTasks.map(column => {
                  if (column._id === statusId) {
                    return {
                      ...column,
                      tasksCount: column.tasksCount + 1,
                      actions: uniqBy(
                        [data.createTask, ...column.actions],
                        '_id',
                      ),
                    };
                  }
                  return column;
                }),
              };
            },
          );
        }
      },
    }).then(({ data }) => {
      if (viewType !== 'calendar') return;
      const taskId = data?.createTask._id;
      if (!taskId) return;
      query.set('taskId', taskId);
      navigate({
        search: query.toString(),
      });
    });
  };

  const onUpdateTask = (
    updatedTask: UpdateTaskInput & { shouldUpdateCache?: boolean },
  ) => {
    const { taskId, statusId, shouldUpdateCache = true } = updatedTask;

    const task = client.readFragment({
      id: `Action:${taskId}`, // Format: <Typename>:<id>
      fragment: TASK_FRAGMENT,
    }) as any;

    const taskLabels = task?.labels || [];
    const projectLabels = labels || [];
    const { addLabelIds = [], removeLabelIds = [] } = updatedTask;

    // Compute the new label set optimistically
    const newLabels = taskLabels
      .filter(({ _id }) => !removeLabelIds?.includes(_id))
      .concat(projectLabels.filter(({ _id }) => addLabelIds?.includes(_id)));

    updateTask({
      variables: {
        updateTaskInput: {
          ...omit(updatedTask, ['shouldUpdateCache']),
        },
      },
      optimisticResponse: {
        __typename: 'Mutation',
        updateTask: {
          __typename: 'Action',
          _id: taskId,
          ...task,
          ...updatedTask,
          createdAt: new Date(),
          updatedAt: new Date(),
          labels: newLabels,
        },
      },
      update: (cache, { data }) => {
        if (!data?.updateTask) return;
        const { updateTask } = data;
        const { _id, statusId } = updateTask;

        if (!shouldUpdateCache || task.statusId === statusId) return;

        cache.updateQuery(
          {
            query: KanbanTasksDocument,
            variables: { projectId: updateTask?.projectId?._id },
          },
          cachedTasks => {
            const { kanbanTasks = [] } = cachedTasks || {};
            return {
              kanbanTasks: kanbanTasks.map(column => {
                if (column._id === updateTask.statusId) {
                  const actions = uniqBy(
                    [updateTask, ...column.actions],
                    '_id',
                  );

                  return {
                    ...column,
                    tasksCount: actions.length,
                    actions,
                  };
                }
                if (column._id === task.statusId) {
                  const actions = column.actions.filter(
                    action => action._id !== _id,
                  );
                  return {
                    ...column,
                    tasksCount: actions.length,
                    actions,
                  };
                }
                return column;
              }),
            };
          },
        );
      },
    });

    if (statusId === completedStatusId) {
      notification.show({
        title: 'Done',
        message: `Task "${task.title}" was successfully completed`,
        variant: 'success',
      });
      playConfettiAnimation(['left', 'right']);
    }
  };

  const onCopyShareLink = () => {
    navigator.clipboard.writeText(window.location.href);
    notification.show({
      title: 'Link copied',
      message: 'Link was successfully copied to clipboard',
      variant: 'success',
    });
  };

  const handleOpenTask = ({ taskId }) => {
    query.set('taskId', taskId);
    navigate({
      search: query.toString(),
    });
  };

  return {
    onCreateTask,
    onDeleteTasks,
    onDuplicateTask,
    onCopyShareLink,
    onUpdateTask,
    onTaskOpen: handleOpenTask,
  };
};
