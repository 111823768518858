import React from 'react';

import { useReactiveVar } from '@apollo/client';

import AudioBar from './AudioBar';
import { currentChapterVar } from 'src/graphql/vars';

const AudioBarContainer = () => {
  const chapter = useReactiveVar(currentChapterVar);

  if (!chapter) return null;

  return <AudioBar />;
};

export default AudioBarContainer;
