import { GET_ACTION_TIME_TRACKING } from 'src/graphql/time-tracking';
import { GET_CURRENT_USER } from 'src/graphql/users';
import { useUser } from 'src/store';

import { useUpdateTimeTrackingMutation } from 'src/generated';
import { TimeTrackingStatus } from 'src/generated';

export const useTimeTracking = ({ taskId }) => {
  const user = useUser();

  const { status, actionId } = user?.timeTracking || {};

  const [updateTimeTracking] = useUpdateTimeTrackingMutation();

  return {
    isTracking:
      status === TimeTrackingStatus.Tracking && actionId?._id === taskId,
    onUpdateTimeTracking: async ({ actionId, timeTrackingId, operation }) => {
      await updateTimeTracking({
        variables: { actionId, timeTrackingId, operation },
        refetchQueries: [
          { query: GET_CURRENT_USER },
          { query: GET_ACTION_TIME_TRACKING, variables: { actionId } },
        ],
      });
    },
  };
};
