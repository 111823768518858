import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-flow: row wrap;
  padding: 12px;
`;

export const FileCard = styled.div`
  width: 33.33%;
  padding: 12px;
  cursor: pointer;
`;
