import { UserRoleEnum } from 'src/generated';

export enum PRIORITY {
  NONE = 'NONE',
  ARGENT = 'URGENT',
  HIGH = 'HIGH',
  MEDIUM = 'MEDIUM',
  LOW = 'LOW',
  EXTREME_LOW = 'EXTREME_LOW',
}

export const priorities = [
  {
    value: PRIORITY.NONE,
    label: 'None',
  },
  {
    value: PRIORITY.ARGENT,
    label: 'Urgent',
    number: 1,
    color: '#fe440a',
  },
  {
    value: PRIORITY.HIGH,
    label: 'High',
    number: 2,
    color: '#f6781a',
  },
  {
    value: PRIORITY.MEDIUM,
    label: 'Medium',
    number: 3,
    color: '#31d53a',
  },
  {
    value: PRIORITY.LOW,
    label: 'Low',
    number: 4,
    color: '#3fcaca',
  },
  {
    value: PRIORITY.EXTREME_LOW,
    label: 'Extreme low',
    number: 5,
    color: '#3390dc',
  },
];

export const userRoles = [
  {
    label: 'Admin',
    value: UserRoleEnum.Admin,
  },
  {
    label: 'Manager',
    value: UserRoleEnum.Manager,
  },
  {
    label: 'Member',
    value: UserRoleEnum.Member,
  },
];
