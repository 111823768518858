import { FieldType } from '../../types';

export const formElements = [
  {
    title: 'Radio Button Group',
    icon: 'bold',
    type: FieldType.RadioGroup,
  },
  {
    title: 'Rating Scale',
    icon: 'bold',
    type: FieldType.RatingScale,
  },
  {
    title: 'Checkboxes',
    icon: 'bold',
    type: FieldType.Checkboxes,
  },
  {
    title: 'Yes/No (Boolean)',
    icon: 'bold',
    type: FieldType.Boolean,
  },
  {
    title: 'Single Line Text',
    icon: 'bold',
    type: FieldType.Input,
  },
  {
    title: 'Long Text',
    icon: 'bold',
    type: FieldType.TextArea,
  },
];
