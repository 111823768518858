import React from 'react';

import TgsPlayer from '../../TgsPlayer';

import { CommentTypeEnum } from 'src/generated';

import * as Styled from './styles';

type StickerProps = {
  url: string;
  type: any;
  stickersName: string;
  size?: number;
};

const Sticker = ({ url, stickersName, type, size = 60 }: StickerProps) => {
  if (type === CommentTypeEnum.Sticker) {
    return (
      <Styled.ImageBlock size={size}>
        <img src={url} alt={stickersName} />
      </Styled.ImageBlock>
    );
  }

  return <TgsPlayer url={url} size={size} hover={false} />;
};

export default Sticker;
