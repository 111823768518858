import { css } from 'styled-components';

export const objectFitImg = (
  objectFit: React.CSSProperties['objectFit'] = 'scale-down',
) => css`
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  object-fit ${objectFit};
`;
