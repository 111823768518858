import React from 'react';

import { Select } from 'src/components/design-system';
import StoryPointsOption from 'src/components/design-system/Select/components/storyPoints/StoryPointsOption';
import StoryPointsSingleValue from 'src/components/design-system/Select/components/storyPoints/StoryPointsSingleValue';

import { useTask } from '../../../../hooks/useTask';

import { storyPointsList } from '../Board/Action/constants';

import * as Styled from './styles';

const EditStoryPointsDropdown = ({
  id,
  rowIndex,
  taskId,
  storyPoint,
  updateData,
}) => {
  const { onUpdateTask } = useTask();

  return (
    <Styled.Wrapper>
      <Select
        menuIsOpen
        classNamePrefix="table-story-points"
        isSearchable={false}
        options={storyPointsList}
        value={storyPoint?.value !== null ? storyPoint : null}
        onChange={data => {
          // data can be null if the user select none story points
          if (data === null) return;
          onUpdateTask({
            taskId,
            storyPoints: data.value,
          });
          updateData(rowIndex, id, data.value);
        }}
        components={{
          SingleValue: StoryPointsSingleValue,
          Option: StoryPointsOption,
          ClearIndicator: undefined,
          DropdownIndicator: undefined,
        }}
      />
    </Styled.Wrapper>
  );
};

export default EditStoryPointsDropdown;
