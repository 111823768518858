import React from 'react';
import { useTranslation } from 'react-i18next';

import { Flex, Button, Icon } from 'src/components/design-system';

import { useTimeTracking } from 'src/hooks/useTimeTracking';

import { DateHelpers, DateFormats } from 'src/common/helpers';

import { convertSecondsToHMS } from 'src/helpers/audio';
import { useUser } from 'src/store';

import { TimeTrackingOperation } from 'src/generated';
import { useTimeTrackingQuery } from 'src/generated';

import * as Styled from './styles';

const TimeTracking = ({ action }) => {
  const { _id } = action;
  const user = useUser();
  const { t } = useTranslation();

  // use query here because I use useTimeTracking hook in other place, and it, make request again
  const { data } = useTimeTrackingQuery({
    variables: { actionId: action._id },
  });

  const timeTrackingList = data?.timeTracking || [];

  const { isTracking, onUpdateTimeTracking } = useTimeTracking({ taskId: _id });

  const { timeTracking } = user;

  const updateTimerTracking = () => {
    onUpdateTimeTracking({
      actionId: _id,
      operation: isTracking
        ? TimeTrackingOperation.Stop
        : TimeTrackingOperation.Start,
      timeTrackingId: timeTracking?._id,
    });
  };

  const totalTime = timeTrackingList.reduce((acc, { loggedTime }) => {
    return acc + loggedTime;
  }, 0);

  return (
    <Styled.Container>
      <Styled.Title>{t('project.timeTracking.title')}</Styled.Title>
      <Flex margin={[0, 0, 15, 0]}>
        <Button
          icon={
            isTracking ? (
              <Icon name="stop-circle-fill" color="red" />
            ) : (
              <Icon name="play-square-fill" />
            )
          }
          onClick={updateTimerTracking}
        >
          {isTracking ? 'Recording' : 'Record'}
        </Button>
      </Flex>

      {timeTrackingList.map(({ _id, loggedTime, createdAt, endTime }) => {
        return (
          <Styled.TrackingItem
            key={_id}
            gap={20}
            padding={10}
            justifyContent="space-between"
          >
            <Flex gap={20}>
              <div>
                {DateHelpers.formatDate(createdAt, DateFormats.FullDate)}
              </div>
              <div>-</div>
              <div>
                {endTime
                  ? DateHelpers.formatDate(endTime, DateFormats.FullDate)
                  : 'Tracking'}
              </div>
            </Flex>
            <div>{endTime && convertSecondsToHMS(loggedTime)}</div>
          </Styled.TrackingItem>
        );
      })}
      <Styled.TrackingItem gap={20} padding={10} justifyContent="space-between">
        <div>{'Total'}</div>
        <div>{convertSecondsToHMS(totalTime)}</div>
      </Styled.TrackingItem>
    </Styled.Container>
  );
};

export default TimeTracking;
