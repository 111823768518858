import React from 'react';

import { useOthers, useSelf } from 'src/liveblocks.config';

const Users = () => {
  const users = useOthers();
  const currentUser = useSelf();

  // console.log(users, 'users');
  // console.log(currentUser, 'currentUser');

  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        right: 0,
        padding: '10px',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        color: 'white',
      }}
    >
      {currentUser && (
        <div>
          {'current user: '}
          {currentUser.connectionId}
        </div>
      )}
      {users.map(user => {
        return (
          <div key={user.connectionId}>
            {'user: '}
            {user.connectionId}
          </div>
        );
      })}
    </div>
  );
};

export default Users;
