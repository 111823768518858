import { gql } from '@apollo/client';

export const GET_COMMENTS = gql`
  query Comments($taskId: ID!) {
    comments(taskId: $taskId) {
      _id
      text
      type
      sticker
      createdAt
      isEdited
      user {
        _id
        fullName
        profilePicture
      }
      reactions {
        id
        emoji
        user {
          _id
          fullName
        }
      }
    }
  }
`;
