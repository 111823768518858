import styled from 'styled-components/macro';

export const ModalHeader = styled.div`
  h2 {
    margin: 0 0 20px 0;
  }
`;
export const ModalFooter = styled.div`
  margin-top: 30px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
`;
