export const DEFAULT_FILTER = {
  projectId: null,
  viewType: null,
  //  filters
  priority: null,
  dueDate: null,
  statusIds: [],
  labels: [],
  assignees: [],
  isShowCompleted: false,
};

export const dueDateOptions = [
  {
    label: 'All',
    value: null,
  },
  {
    label: 'Today',
    value: 'today',
  },
  {
    label: 'Tomorrow',
    value: 'tomorrow',
  },
  {
    label: 'This week',
    value: 'thisWeek',
  },
  {
    label: 'This month',
    value: 'thisMonth',
  },
  {
    label: 'This year',
    value: 'thisYear',
  },
  {
    label: 'Overdue',
    value: 'overdue',
  },
];
