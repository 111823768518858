import { useQueryParams } from './useQueryParams';

import { useTaskHistoryQuery } from 'src/generated';

export const useTaskHistory = () => {
  const query = useQueryParams();
  const taskId = query.get('taskId') as string;

  const { data, loading, error } = useTaskHistoryQuery({
    variables: { taskId },
  });

  return {
    loading,
    error,
    taskHistory: data?.taskHistory || [],
  };
};
