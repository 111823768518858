import React from 'react';
import { useTranslation } from 'react-i18next';

import { useReactiveVar } from '@apollo/client';

import Header from '../../components/Header/Header';
import { Flex, Icon } from 'src/components/design-system';

import ProjectsList from './ProjectsList/ProjectsList';
import { sidebarMenu } from './constants';
import { isOpenSidebarVar } from 'src/graphql/vars';

import {
  MenuLink,
  Body,
  SidebarHeaderButton,
  SidebarHeader,
  Sidebar,
  Content,
  Container,
} from './styles';

const MainLayout = ({ children }) => {
  const { t } = useTranslation();
  const isOpenSidebar = useReactiveVar(isOpenSidebarVar);

  const handleHideSidebar = () => {
    isOpenSidebarVar(false);
  };

  return (
    <Container>
      <Sidebar open={isOpenSidebar}>
        <Flex direction="column" flex="1">
          <SidebarHeader>
            <h1>{t('app.title')}</h1>
            <SidebarHeaderButton onClick={handleHideSidebar}>
              <Icon name="menu" size={24} color="#fff" />
            </SidebarHeaderButton>
          </SidebarHeader>
          {sidebarMenu.map(({ id, path, icon }) => (
            <MenuLink key={id} to={path}>
              <Icon name={icon} />
              {t(`sidebar.menu.${id}`)}
            </MenuLink>
          ))}
          <ProjectsList />
        </Flex>
      </Sidebar>
      <Content open={isOpenSidebar}>
        <Header />
        <Body>{children}</Body>
      </Content>
    </Container>
  );
};

export default MainLayout;
