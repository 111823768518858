import React from 'react';

import { Tag, Flex } from 'src/components/design-system';

import { useTask } from 'src/hooks/useTask';

const Labels = ({ action }) => {
  const { _id, labels } = action;
  const { onUpdateTask } = useTask();

  const handleRemoveLabelFromTask = (labelId: string) => {
    onUpdateTask({
      taskId: _id,
      removeLabelIds: [labelId],
    });
  };

  return (
    <Flex gap={6} flexWrap="wrap">
      {labels.map(({ _id, title, color }) => (
        <Tag
          id={_id}
          key={_id}
          color={color}
          onClose={() => handleRemoveLabelFromTask(_id)}
        >
          {title}
        </Tag>
      ))}
    </Flex>
  );
};

export default Labels;
