import styled from 'styled-components';

export const Editable = styled.span`
  position: relative;
  &:focus,
  &:hover {
    &:after {
      content: '';
      border-radius: ${({ theme }) => theme.border.radius.sm};
      position: absolute;
      top: -2px;
      left: -4px;
      right: -4px;
      bottom: -2px;
      box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.16);
    }
  }

  &:focus {
    outline: none;
    &:after {
      box-shadow: 0 0 0 3px ${({ theme }) => theme.color.success};
      top: -4px;
      left: -8px;
      right: -8px;
      bottom: -4px;
    }
  }
`;
