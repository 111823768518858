import styled from 'styled-components';

export const Container = styled.div`
  overflow: hidden;
  height: 100%;
`;

export const FieldSetting = styled.div`
  width: 440px;
  background: ${({ theme }) => theme.background.secondary};
`;

export const Content = styled.div`
  background: rgba(43, 116, 217, 0.05);
  width: 100%;
  padding: 20px;
  margin: 20px;
  border-radius: ${({ theme }) => theme.border.radius.sm};
  overflow: auto;
  &:last-of-type {
    margin-bottom: 30px;
  }
`;
