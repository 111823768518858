import { Icon, Button } from 'src/components/design-system';

import { isBlockActive } from '../../utils/SlateUtilityFunctions.js';

const LinkButton = props => {
  const { editor, handleToggleInsertLink } = props;

  return (
    <Button
      active={isBlockActive(editor, 'link')}
      format={'link'}
      onClick={handleToggleInsertLink}
    >
      <Icon name="link" />
    </Button>
  );
};

export default LinkButton;
