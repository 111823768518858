import { useNavigate } from 'react-router-dom';

import { useConfirm } from 'src/components/design-system';

import { GET_PROJECTS } from 'src/graphql/projects';

import {
  useDeleteProjectMutation,
  useUpdateProjectMutation,
  useCreateProjectMutation,
  GetProjectsQuery,
  UpdateProjectMutationVariables,
} from 'src/generated';

export const useProjects = () => {
  const confirm = useConfirm();
  const navigate = useNavigate();

  const [createProject, { loading: createProjectLoading }] =
    useCreateProjectMutation({
      update(cache, { data }) {
        if (!data?.createProject) return;
        const { createProject } = data;

        cache.modify({
          fields: {
            projects(existingProjectsRefs = []) {
              return [...existingProjectsRefs, createProject];
            },
          },
        });
      },
    });

  const [deleteProject] = useDeleteProjectMutation({
    update(cache) {
      cache.evict({
        id: 'ROOT_QUERY',
        fieldName: 'projects',
        broadcast: false,
      });
      cache.gc();
    },
  });

  const [updateProject] = useUpdateProjectMutation({
    update(cache, { data }) {
      if (!data?.updateProject) return;

      const { updateProject } = data;

      // Update project in projects list (left sidebar)
      const cashed = cache.readQuery<GetProjectsQuery>({
        query: GET_PROJECTS,
      });

      const { projects } = cashed || { projects: [] };

      cache.writeQuery({
        query: GET_PROJECTS,
        data: {
          projects: projects.map(project => {
            if (project._id === updateProject._id) {
              return { ...project, ...updateProject };
            }
            return project;
          }),
        },
      });
    },
  });

  const onDeleteProject = async ({ projectId }) => {
    confirm({
      message: 'Are you sure you want to delete this project?',
    })
      .then(() => {
        deleteProject({
          variables: { projectId },
        });
        navigate('/');
      })
      .catch(() => {});
  };

  const onUpdateProject = async ({
    projectId,
    title,
    color,
    boardsSortList,
    status,
  }: UpdateProjectMutationVariables) => {
    updateProject({
      variables: { projectId, title, color, boardsSortList, status },
    });
  };

  const onCreateProject = async ({ title, color }) => {
    createProject({
      variables: { title, color },
    });
  };

  return {
    onDeleteProject,
    onUpdateProject,
    onCreateProject,
    createProjectLoading,
  };
};
