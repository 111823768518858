import React, { useEffect, useRef } from 'react';
import { ReactEditor, useSlateStatic } from 'slate-react';

import { Portal } from 'src/components/design-system';

import { commands } from './constants';

import { DropdownContainer, DropdownItem } from './styles';

const Dropdown = ({ index, target, handleSelectCommand }) => {
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const editor = useSlateStatic();

  useEffect(() => {
    if (target) {
      const el = dropdownRef.current;
      if (!el) return;
      const domRange = ReactEditor.toDOMRange(editor as ReactEditor, target);
      const rect = domRange.getBoundingClientRect();
      el.style.top = `${rect.top + window.pageYOffset + 24}px`;
      el.style.left = `${rect.left + window.pageXOffset}px`;
    }
  }, [dropdownRef, editor, target]);

  return (
    <Portal>
      <DropdownContainer ref={dropdownRef}>
        {commands.map((command, i) => (
          <DropdownItem
            key={command}
            selected={i === index}
            onClick={() => handleSelectCommand(command)}
          >
            {command}
          </DropdownItem>
        ))}
      </DropdownContainer>
    </Portal>
  );
};

export default Dropdown;
