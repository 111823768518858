import React, { useContext } from 'react';

import FormCard from '../../FormCard/FormCard';
import FormContext from '../../FormContext';

const FormBuilder = () => {
  const {
    state: { title, description, elements },
  } = useContext(FormContext);

  const isEmpty = !elements.length;

  if (isEmpty) {
    return (
      <div>
        {
          'The survey is empty. Drag an element from the toolbox or click the button below.'
        }
      </div>
    );
  }

  return (
    <div>
      <h1>{title}</h1>
      <p>{description}</p>
      {elements.map((item, index) => (
        <FormCard key={item.name} element={item} index={index + 1} />
      ))}
    </div>
  );
};

export default FormBuilder;
