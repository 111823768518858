import styled from 'styled-components';

export const Container = styled.div`
  padding: 20px;
`;

export const UploadBookBlock = styled.div`
  padding: 20px;
  margin-bottom: 20px;
  border: 1px solid ${({ theme }) => theme.border.color.primary};
  border-radius: ${({ theme }) => theme.border.radius.sm};
`;
