import { useParams } from 'react-router-dom';

import { useApolloClient } from '@apollo/client';

import { GET_PROJECT_DETAILS } from '../../graphql/projects';

export const useIsTaskCompleted = statusId => {
  const { id: projectId } = useParams();

  const client = useApolloClient();

  if (!projectId) return false;

  const { project } = client.readQuery({
    query: GET_PROJECT_DETAILS,
    variables: { projectId },
  }) || { project: {} };

  const { completedStatusId } = project;

  return completedStatusId === statusId;
};
