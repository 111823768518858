import { Button } from 'src/components/design-system';
import styled, { css } from 'styled-components';

export const Content = styled.div`
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 1050;
  pointer-events: none;
`;

type ConfirmButtonProps = {
  resolve?: boolean;
  reject?: boolean;
};

export const ConfirmButton = styled(Button)<ConfirmButtonProps>`
  flex: 1;
  padding: 15px 20px;
  border-radius: 0;
  font-weight: bold;
  background: ${({ theme }) => theme.background.hover};
  border-top: 1px solid ${({ theme }) => theme.color.grey200};

  &:first-child {
    border-right: 1px solid ${({ theme }) => theme.color.grey200};
  }

  &:hover {
    border-top: 1px solid ${({ theme }) => theme.color.grey200};
    color: ${({ theme }) => theme.color.white};
  }

  ${({ resolve }) =>
    resolve &&
    css`
      &:hover {
        background: ${({ theme }) => theme.color.success};
      }
    `}

  ${({ reject }) =>
    reject &&
    css`
      &:hover {
        background: ${({ theme }) => theme.color.danger};
      }
    `}
`;
