import { objectFitImg } from 'src/components/design-system';
import styled from 'styled-components';

export const Container = styled.div`
  min-width: 180px;
  width: 30%;
`;
export const AudioThumbnail = styled.div`
  width: 56px;
  height: 65px;
  border-radius: ${({ theme }) => theme.border.radius.sm};
  overflow: hidden;
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    ${objectFitImg('cover')}
  }
`;

export const AudioText = styled.div`
  h2 {
    font-size: 14px;
    margin: 0;
  }
  p {
    margin: 0;
  }
`;
