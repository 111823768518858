import styled from 'styled-components';

export const Test = styled.div`
  position: relative;

  pre {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

export const Container = styled.div`
  margin: auto;
  width: 500px;
`;

export const Card = styled.div`
  padding: 20px;
`;
