import React, {
  forwardRef,
  InputHTMLAttributes,
  ReactNode,
  useState,
  useCallback,
} from 'react';

import { Icon, Button } from 'src/components/design-system';

import * as Styled from './styles';

interface InputProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'prefix'> {
  prefix?: ReactNode;
  clearable?: boolean;
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ prefix, onChange, clearable = true, ...props }, ref) => {
    const [value, setValue] = useState('');

    // Handle input value change
    const handleChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value); // Update internal value
        if (onChange) {
          onChange(e); // Notify the parent of the change
        }
      },
      [onChange],
    );

    // Handle clear action
    const handleClear = useCallback(() => {
      setValue(''); // Clear the internal state
      if (ref && typeof ref === 'object' && ref.current) {
        ref.current.value = ''; // Clear the actual input field
      }
      // Trigger the parent's onChange with an empty event, simulating the clear action
      if (onChange) {
        const event = new Event('input', {
          bubbles: true,
        }) as unknown as React.ChangeEvent<HTMLInputElement>;
        Object.defineProperty(event, 'target', {
          value: { value: '' },
          writable: false,
        });
        onChange(event); // Notify parent of the clear action
      }
    }, [onChange, ref]);

    return (
      <Styled.Container gap={4} alignItems="center">
        {prefix && <span>{prefix}</span>}
        <input
          ref={ref}
          value={value} // Internally managed value
          onChange={handleChange} // Handle change and notify parent
          {...props}
        />
        {clearable && value && (
          <Button htmlType="text" size="little" onClick={handleClear}>
            <Icon name="close" />
          </Button>
        )}
      </Styled.Container>
    );
  },
);
export default Input;
