import React from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import TaskDescriptionHistoryDrawer from './components/Board/Action/TaskDescriptionHistory/TaskDescriptionHistoryDrawer';
import BoardContainer from './components/Board/BoardContainer';
import Calendar from './components/Calendar/Calendar';
import Dashboard from './components/Dashboard/Dashboard';
import EditProject from './components/EditProject/EditProject';
import EditStatusModal from './components/EditStatusModal/EditStatusModal';
import Files from './components/Files/Files';
import Overview from './components/Overview/Overview';
import ProjectFilterContainer from './components/ProjectFilter/ProjectFilterContainer';
import Table from './components/Table/Table';
import TaskModalContainer from './components/TaskModal/TaskModalContainer';

import CustomFieldsDrawer from 'src/widgets/CustomFieldsDrawer/CustomFieldsDrawer';
import InviteProjectMembers from 'src/widgets/InviteProjectMemebers/InviteProjectMembers';
import UnsplashDrawer from 'src/widgets/UnsplashDrawer/UnsplashDrawer';

import { GET_PROJECT_DETAILS } from 'src/graphql/projects';
import { useUser } from 'src/store';

import { ProjectViews } from './types';

import {
  useOnCreateNotificationSubscription,
  NotificationsDocument,
} from 'src/generated';

const ProjectInfo = () => {
  const { id: projectId } = useParams() as any;
  const { workspaceId } = useUser();

  // useOnCreateNotificationSubscription({
  //   variables: {
  //     workspaceId,
  //     projectId,
  //     isPrivate: false,
  //   },
  //   onSubscriptionData: ({ client, subscriptionData }) => {
  //     if (!subscriptionData.data) return;
  //     const newNotification = subscriptionData.data.onCreateNotification;

  //     console.log(newNotification, 'newNotification from subscription PROJECT');

  //     client.cache.updateQuery(
  //       {
  //         query: NotificationsDocument,
  //         variables: {
  //           workspaceId,
  //           projectId,
  //           isPrivate: false,
  //         },
  //       },
  //       data => {
  //         if (!data) return;
  //         const { notifications } = data;
  //         return {
  //           notifications: [newNotification, ...notifications],
  //         };
  //       },
  //     );
  //   },
  // });

  const { id } = useParams<{ id: string }>();
  const [searchParams] = useSearchParams();
  const view = searchParams.get('view');

  const { loading } = useQuery(GET_PROJECT_DETAILS, {
    variables: { projectId: id },
  });

  if (loading) return null;

  const component = () => {
    switch (view) {
      case ProjectViews.Dashboard:
        return <Dashboard />;
      case ProjectViews.Table:
        return <Table />;
      case ProjectViews.Files:
        return <Files />;
      case ProjectViews.Calendar:
        return <Calendar />;
      case ProjectViews.Overview:
        return <Overview />;
      default:
        return <BoardContainer />;
    }
  };

  return (
    <>
      {component()}
      <EditProject />
      <TaskDescriptionHistoryDrawer />
      <ProjectFilterContainer />
      <EditStatusModal />
      <CustomFieldsDrawer />
      <InviteProjectMembers />
      <UnsplashDrawer />
      <TaskModalContainer />
    </>
  );
};

export default ProjectInfo;
