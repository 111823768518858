import React, { memo } from 'react';

import { Button, Icon } from 'src/components/design-system';
import { useTheme } from 'styled-components';

import { useTask } from 'src/hooks/useTask';

import * as Styled from './styles';

const TableTaskNameCell = ({ props }) => {
  const theme = useTheme();
  const { row, getValue } = props;
  const { onTaskOpen } = useTask();

  const { _id, isUrgent } = row.original;
  const name = getValue();

  return (
    <Styled.Title
      alignItems="center"
      gap={10}
      padding={[0, 0, 0, row.depth * 20]}
    >
      {row.getCanExpand() && (
        <Button onClick={row.getToggleExpandedHandler()} htmlType="text">
          {row.getIsExpanded() ? '👇' : '👉'}
        </Button>
      )}
      <div onClick={() => onTaskOpen({ taskId: _id })}>{name}</div>
      {isUrgent && (
        <Icon
          size={20}
          name="info-triangle-filled"
          color={theme.color.urgent}
        />
      )}
    </Styled.Title>
  );
};

export default memo(TableTaskNameCell);
