import React from 'react';

import { Flex, EditableText } from 'src/components/design-system';

import { useStatuses } from 'src/hooks/useStatuses';

import ColumnMoreMenu from '../ColumnMoreMenu/ColumnMoreMenu';

import { Container, Title, Count, Color, HeaderRightBlock } from './styles';

const ColumnHeader = ({ column }) => {
  const { title, color, tasksCount } = column;
  const { onUpdateStatus } = useStatuses();

  const handleUpdateStatus = async value => {
    onUpdateStatus({ statusId: column._id, title: value });
  };

  return (
    <Container>
      <Flex alignItems="center" gap={10}>
        {color && <Color color={color} size={15} />}
        <EditableText
          text={title}
          onUpdate={handleUpdateStatus}
          customReadView={() => <Title>{title}</Title>}
        />
        {!!tasksCount && <Count>{tasksCount}</Count>}
      </Flex>
      <HeaderRightBlock>
        <ColumnMoreMenu column={column} />
      </HeaderRightBlock>
    </Container>
  );
};

export default ColumnHeader;
