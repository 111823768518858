import styled from 'styled-components';

export const Toolbar = styled.div`
  position: absolute;
  background: white;
  border: 1px solid #e0e0e0;
  padding: 10px;
  top: 10px;
  left: 10px;
  z-index: 1;
  border-radius: ${({ theme }) => theme.border.radius.md};
`;
