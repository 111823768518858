import React, { ReactElement, cloneElement } from 'react';
import { useFormContext, Controller } from 'react-hook-form';

import { Container, ErrorMessage, Label } from './styles';

type FormItemProps = {
  name: string;
  label?: string;
  children?: ReactElement;
};

const FormItem = ({ name, label, children, ...rest }: FormItemProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const error = errors[name];

  return (
    <Container inValid={Boolean(error)}>
      {label && <Label htmlFor={name}>{label}</Label>}

      {children && (
        <Controller
          name={name}
          control={control}
          render={({ field, fieldState }) => {
            return cloneElement(children, {
              ...field,
              ...rest,
              ...children.props,
              value: field.value,
              onChange: e => {
                field.onChange(e);
                children.props.onChange && children.props.onChange(e);
              },
              onBlur: () => {
                field.onBlur();
                children.props.onBlur && children.props.onBlur();
              },
              onFocus: () => {
                // @ts-ignore
                // field?.onFocus();
                children.props.onFocus && children.props.onFocus();
              },
            });
          }}
        />
      )}
      {error && <ErrorMessage>{error?.message as any}</ErrorMessage>}
    </Container>
  );
};

export default FormItem;
