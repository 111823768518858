import { useQueryParams } from './useQueryParams';

import { useTaskDescriptionHistoryQuery } from 'src/generated';

export const useTaskDescriptionHistory = () => {
  const query = useQueryParams();
  const taskId = query.get('taskId') as string;

  const {
    data,
    loading,
    error,
    fetchMore: loadMoreTaskDescriptionHistory,
  } = useTaskDescriptionHistoryQuery({
    variables: { taskId, limit: 10 },
  });

  return {
    loading,
    error,
    taskDescriptionHistory: data?.taskDescriptionHistory || [],
    loadMoreTaskDescriptionHistory,
  };
};
