import React, { useContext } from 'react';

import FormContext from '../../FormContext';
import General from './General/General';

import * as Styled from './styles';

const FormFieldSettings = () => {
  const {
    state: { elements, activeElement },
  } = useContext(FormContext);

  const element = elements.find(element => element.name === activeElement);

  return (
    <Styled.Container>
      <Styled.Header>
        <strong>{'General'}</strong>
      </Styled.Header>
      <General element={element} />
    </Styled.Container>
  );
};

export default FormFieldSettings;
