import * as React from 'react';

import styled, { css } from 'styled-components/macro';

type LoadingIndicatorProps = {
  color?: string;
  absolute?: boolean;
};

const LoadingIndicator = ({
  color = '#8884d8',
  absolute,
}: LoadingIndicatorProps) => (
  <Container absolute={absolute}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100px"
      viewBox="0 0 100 100"
    >
      <rect y="25" width="10" height="50" rx="4" ry="4" fill={color}>
        <animate
          attributeName="x"
          values="10;100"
          dur="1.2s"
          repeatCount="indefinite"
        />
        <animateTransform
          attributeName="transform"
          type="rotate"
          from="0 10 70"
          to="-60 100 70"
          dur="1.2s"
          repeatCount="indefinite"
        />
        <animate
          attributeName="opacity"
          values="0;1;0"
          dur="1.2s"
          repeatCount="indefinite"
        />
      </rect>
      <rect y="25" width="10" height="50" rx="4" ry="4" fill={color}>
        <animate
          attributeName="x"
          values="10;100"
          dur="1.2s"
          begin="0.4s"
          repeatCount="indefinite"
        />
        <animateTransform
          attributeName="transform"
          type="rotate"
          from="0 10 70"
          to="-60 100 70"
          dur="1.2s"
          begin="0.4s"
          repeatCount="indefinite"
        />
        <animate
          attributeName="opacity"
          values="0;1;0"
          dur="1.2s"
          begin="0.4s"
          repeatCount="indefinite"
        />
      </rect>
      <rect y="25" width="10" height="50" rx="4" ry="4" fill={color}>
        <animate
          attributeName="x"
          values="10;100"
          dur="1.2s"
          begin="0.8s"
          repeatCount="indefinite"
        />
        <animateTransform
          attributeName="transform"
          type="rotate"
          from="0 10 70"
          to="-60 100 70"
          dur="1.2s"
          begin="0.8s"
          repeatCount="indefinite"
        />
        <animate
          attributeName="opacity"
          values="0;1;0"
          dur="1.2s"
          begin="0.8s"
          repeatCount="indefinite"
        />
      </rect>
    </svg>
  </Container>
);

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  ${({ absolute }: LoadingIndicatorProps) =>
    absolute &&
    css`
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      background: rgba(255, 255, 255, 0.5);
    `}
`;
export default LoadingIndicator;
