import React from 'react';

import { useReactiveVar } from '@apollo/client';

import { Modal, Button } from 'src/components/design-system';

import { useBooks } from 'src/hooks/useBooks';

import { openUploadBookModalVar } from 'src/graphql/vars';

const UploadBookModal = () => {
  const { uploadBookLoading, onUploadBook } = useBooks();
  const openUploadBookModal = useReactiveVar(openUploadBookModalVar);
  const [title, setTitle] = React.useState('');
  const [poster, setPoster] = React.useState<File | null>(null);
  const [chapters, setChapters] = React.useState<FileList | null>(null);

  const handleUploadBook = () => {
    onUploadBook({
      title,
      poster,
      chapters,
    });
  };

  const handleAddPoster = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    setPoster(e.target.files[0]);
  };

  const handleAddChapters = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    setChapters(e.target.files);
  };

  return (
    <Modal
      isOpen={openUploadBookModal}
      width={700}
      onRequestClose={() => openUploadBookModalVar(false)}
    >
      <h1>{'Upload Book'}</h1>
      <div>
        <input
          type="text"
          value={title}
          onChange={e => setTitle(e.target.value)}
        />
      </div>
      <div>
        <input type="file" onChange={handleAddPoster} />
      </div>
      <div>
        <input type="file" onChange={handleAddChapters} multiple />
      </div>
      <Button onClick={handleUploadBook} loading={uploadBookLoading}>
        {'Upload'}
      </Button>
    </Modal>
  );
};

export default UploadBookModal;
