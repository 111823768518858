import { Flex } from 'src/components/design-system';
import styled from 'styled-components';

export const Container = styled.div`
  padding: 20px;
  width: 240px;
  border-top: 4px solid ${({ theme }) => theme.color.success};
`;

export const Item = styled(Flex)`
  cursor: pointer;
`;

export const ItemInner = styled(Flex)`
  &:hover {
    border-radius: 20px;
    background: ${({ theme }) => theme.color.white};
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  }
`;

export const Title = styled.span``;
