import { CKEditor } from '@ckeditor/ckeditor5-react';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Flex, LoadingIndicator } from 'src/components/design-system';

import { useTaskDescriptionHistory } from 'src/hooks/useTaskDescriptionHistory';

import { DateFormats, DateHelpers } from 'src/common/helpers';

import Editor from 'manage-ui-ckeditor5';

import * as Styled from './styles';

const TaskDescriptionHistory = () => {
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);
  const { loading, taskDescriptionHistory, loadMoreTaskDescriptionHistory } =
    useTaskDescriptionHistory();

  useEffect(() => {
    const scrollContainer = containerRef.current;

    if (!scrollContainer) return;
    const handleScroll = () => {
      if (scrollContainer) {
        const { scrollTop, scrollHeight, clientHeight } = scrollContainer;
        const scrollThreshold = 100;

        const isNear =
          scrollTop + clientHeight + scrollThreshold >= scrollHeight;

        if (isNear) {
          loadMoreTaskDescriptionHistory({
            variables: {
              skip: taskDescriptionHistory.length,
            },
            updateQuery: (prev, { fetchMoreResult }) => {
              if (!fetchMoreResult) return prev;
              return Object.assign({}, prev, {
                taskDescriptionHistory: [
                  ...prev.taskDescriptionHistory,
                  ...fetchMoreResult.taskDescriptionHistory,
                ],
              });
            },
          });
        }
      }
    };

    scrollContainer.addEventListener('scroll', handleScroll);
    return () => {
      scrollContainer.removeEventListener('scroll', handleScroll);
    };
  }, [loadMoreTaskDescriptionHistory, taskDescriptionHistory.length]);

  if (loading)
    return (
      <Flex height="100%">
        <LoadingIndicator />
      </Flex>
    );

  return (
    <Styled.Container ref={containerRef}>
      <Styled.Title>{t('task.descriptionHistory.title')}</Styled.Title>
      {taskDescriptionHistory.map(({ _id, text, createdAt, user }) => {
        const { fullName } = user;
        return (
          <Styled.HistoryItem key={_id}>
            <strong>{fullName}</strong>
            <Styled.HistoryItemDate>
              {DateHelpers.formatDate(createdAt, DateFormats.FullDate)}
            </Styled.HistoryItemDate>
            <CKEditor editor={Editor} data={text} disabled />
          </Styled.HistoryItem>
        );
      })}
    </Styled.Container>
  );
};

export default TaskDescriptionHistory;
