import styled from 'styled-components';

export const Left = styled.div`
  width: 251px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  height: 100%;
  background-color: ${({ theme }) => theme.task.secondary};
  border-bottom-right-radius: ${({ theme }) => theme.border.radius.md};
`;

export const LeftItem = styled.div`
  margin-bottom: 30px;

  h2 {
    font-weight: bold;
    margin: 0 0 3px 0;
    font-size: 14px;
  }
`;
