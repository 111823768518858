import { gql } from '@apollo/client';

import { NOTIFICATION_FRAGMENT } from '../../fragments';

export const NOTIFICATIONS = gql`
  query Notifications(
    $workspaceId: ID!
    $userId: ID
    $skip: Int
    $limit: Int
    $projectId: ID
    $isPrivate: Boolean
    $type: NotificationTypeEnum
  ) {
    notifications(
      workspaceId: $workspaceId
      userId: $userId
      skip: $skip
      limit: $limit
      projectId: $projectId
      isPrivate: $isPrivate
      type: $type
    ) {
      ...NotificationFragment
    }
  }
  ${NOTIFICATION_FRAGMENT}
`;
