import React from 'react';

import { useReactiveVar } from '@apollo/client';

import { Drawer } from 'src/components/design-system';

import ProjectFilter from './ProjectFilter';
import { openProjectFilterDrawer } from 'src/graphql/vars';

const ProjectFilterContainer = () => {
  const isOpen = useReactiveVar(openProjectFilterDrawer);
  return (
    <Drawer
      open={isOpen}
      width={300}
      onClose={() => openProjectFilterDrawer(false)}
      mask={false}
    >
      <ProjectFilter />
    </Drawer>
  );
};

export default ProjectFilterContainer;
