import React, { useState } from 'react';

import { Select, Flex, LoadingIndicator } from 'src/components/design-system';
import DashboardTasksByPeriodChart from 'src/pages/Project/components/Dashboard/charts/DashboardTasksByPeriodChart/DashboardTasksByPeriodChart';

import { useUserWorkspace } from 'src/store';

import {
  useDashboardTasksByPeriodQuery,
  DashboardTaskPeriodEnum,
} from 'src/generated';

import * as Styled from './styles';

const periods = [
  { label: 'Year', value: DashboardTaskPeriodEnum.Year },
  { label: 'Half year', value: DashboardTaskPeriodEnum.HalfYear },
  { label: 'Quarter', value: DashboardTaskPeriodEnum.Quarter },
  { label: 'Month', value: DashboardTaskPeriodEnum.Month },
];

const getInterval = (period: DashboardTaskPeriodEnum) => {
  switch (period) {
    case DashboardTaskPeriodEnum.Year:
      return 10;
    case DashboardTaskPeriodEnum.HalfYear:
      return 5;
    case DashboardTaskPeriodEnum.Quarter:
      return 3;
    default:
      return 0;
  }
};

type DashboardTasksByPeriodProps = {
  projectId?: string;
};

const DashboardTasksByPeriod = ({ projectId }: DashboardTasksByPeriodProps) => {
  const [period, setPeriod] = useState(DashboardTaskPeriodEnum.Month);
  const workspaceId = useUserWorkspace();
  const { data, loading, refetch } = useDashboardTasksByPeriodQuery({
    variables: {
      workspaceId,
      period,
      projectId,
    },
  });

  const interval = getInterval(period);
  const currentPeriod = periods.find(({ value }) => value === period);

  return (
    <Styled.Container>
      {loading && <LoadingIndicator absolute />}
      <Flex>
        <Flex pushRight gap={10} alignItems="center">
          <h3>{`${
            projectId ? 'Project' : 'Workspace'
          } tasks by ${currentPeriod?.label.toLowerCase()}`}</h3>
          <Select
            options={periods}
            value={currentPeriod}
            onChange={({ value }) => {
              setPeriod(value);
              refetch({ workspaceId, period: value, projectId });
            }}
          />
        </Flex>
      </Flex>
      <DashboardTasksByPeriodChart
        data={data?.dashboardTasksByPeriod || []}
        interval={interval}
      />
    </Styled.Container>
  );
};

export default DashboardTasksByPeriod;
