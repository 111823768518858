import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useReactiveVar } from '@apollo/client';

import { useBooks } from '../../hooks/useBooks';

import Controls from './Controls/Controls';
import DisplayTrack from './DisplayTrack/DisplayTrack';
import RightBlock from './RightBlock/RightBlock';
import { currentChapterVar } from 'src/graphql/vars';

import { Book, Chapter } from 'src/generated';

import { Container } from './styles';
import { CollapsedButton } from './styles';

const AudioBar = () => {
  const { t } = useTranslation();
  const { books, onUpdateBookListening } = useBooks();

  const chapter = useReactiveVar(currentChapterVar);

  const [isOpen, setIsOpen] = useState(false);

  const [currentBook, setCurrentBook] = useState<Book>();
  const [currentChapter, setCurrentChapter] = useState<Chapter>();

  const [timeProgress, setTimeProgress] = useState(0);
  const [duration, setDuration] = useState(0);

  // reference
  const audioRef = useRef<HTMLAudioElement>();
  const progressBarRef = useRef();

  const saveCurrentTime = () => {
    onUpdateBookListening({
      chapterId: currentChapter?.id,
      listenedTime: timeProgress,
    });
  };

  useEffect(() => {
    if (chapter) {
      const { chapterId } = chapter;
      setIsOpen(true);

      const currentBook = books.find(({ chapters }) => {
        return chapters.some(({ id }) => id === chapterId);
      });

      const currentChapterIndex = currentBook?.chapters.findIndex(
        ({ id }) => id === chapterId,
      )!;

      if (!currentBook || currentChapterIndex < 0) return;

      setCurrentBook(currentBook as Book);

      setCurrentChapter(currentBook.chapters[currentChapterIndex]);
    }
  }, [books, chapter, audioRef]);

  useEffect(() => {
    if (!currentChapter || !chapter) return;

    const { chapterTime } = chapter;

    if (chapterTime && audioRef.current) {
      audioRef.current.currentTime = chapterTime;

      setTimeProgress(chapterTime);
    }
  }, [chapter, currentChapter]);

  const handleGoToNextChapter = () => {
    if (!currentBook) return;

    const currentChapterIndex = currentBook?.chapters.findIndex(
      chapter => chapter.id === currentChapter?.id,
    );

    if (currentChapterIndex === currentBook?.chapters?.length - 1) {
      setCurrentChapter(currentBook?.chapters[0]);
    } else {
      setCurrentChapter(currentBook?.chapters[currentChapterIndex + 1]);
    }
  };

  const handleGoToPreviousChapter = () => {
    const currentChapterIndex = currentBook?.chapters.findIndex(
      chapter => chapter.id === currentChapter?.id,
    );

    // if current chapter is not found, do nothing
    if (!currentChapterIndex) return;

    // if current chapter is the first chapter, do nothing
    if (currentChapterIndex === 0) return;

    // go to previous chapter
    setCurrentChapter(currentBook?.chapters[currentChapterIndex - 1]);
  };

  if (!currentChapter) return null;

  return (
    <Container isOpen={isOpen}>
      <CollapsedButton onClick={() => setIsOpen(prev => !prev)}>
        {isOpen ? t('audio.expandedButton') : t('audio.collapsedButton')}
      </CollapsedButton>
      <DisplayTrack
        currentChapter={currentChapter}
        audioRef={audioRef}
        setDuration={setDuration}
        progressBarRef={progressBarRef}
        handleGoToNextChapter={handleGoToNextChapter}
        saveCurrentTime={saveCurrentTime}
      />
      <Controls
        audioRef={audioRef}
        progressBarRef={progressBarRef}
        duration={duration}
        setTimeProgress={setTimeProgress}
        timeProgress={timeProgress}
        handleGoToNextChapter={handleGoToNextChapter}
        handleGoToPreviousChapter={handleGoToPreviousChapter}
        saveCurrentTime={saveCurrentTime}
      />
      <RightBlock audioRef={audioRef} />
    </Container>
  );
};

export default AudioBar;
