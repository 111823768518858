import styled, { keyframes } from 'styled-components/macro';

import { SkeletonProps } from './Skeleton';

const pulse = keyframes`
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.4;
    }
    100% {
        opacity: 1;
    }
`;

const convertToCSS = value => {
  if (isNaN(value)) return value;
  return `${value}px`;
};

export const SkeletonElement = styled.div<SkeletonProps>`
  background-color: rgba(0, 0, 0, 0.12);
  display: block;
  animation: ${pulse} 1.5s ease-in-out 0.5s infinite;
  border-radius: ${({ circle }) => (circle ? '50%' : '4px')};
  width: ${({ width }) => (width ? convertToCSS(width) : '100%')};
  height: ${({ height }) => (height ? convertToCSS(height) : '20px')};
`;
