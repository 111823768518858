import React from 'react';

import { colorToCss } from '../utils/utils';

import { Color } from '../types';

import * as Styled from './styles';

type ColorButtonProps = {
  onClick: (color: Color) => void;
  color: Color;
};
const ColorButton = ({ onClick, color }: ColorButtonProps) => {
  return (
    <Styled.ColorButton
      bgColor={colorToCss(color)}
      onClick={() => onClick(color)}
    />
  );
};

export default ColorButton;
