import { Flex } from 'src/components/design-system';
import styled from 'styled-components';

export const Title = styled.h2`
  margin: 0;
`;

export const Header = styled(Flex)`
  border-bottom: 1px solid ${({ theme }) => theme.border.color.primary};
`;
