import { useParams } from 'react-router-dom';

import { gql } from '@apollo/client';

import { GET_PROJECT_TASKS_FOR_BOARD_VIEW } from '../graphql/actions';
import { useUserWorkspace } from 'src/store';

import {
  useCreateStatusMutation,
  useDeleteStatusMutation,
  useUpdateStatusMutation,
  MutationUpdateStatusArgs,
  UpdateStatusMutation,
} from 'src/generated';

export const useStatuses = () => {
  const { id: projectId } = useParams() as any;
  const workspaceId = useUserWorkspace();

  const [updateStatus] = useUpdateStatusMutation({
    update(cache, { data }) {
      if (!data?.updateStatus) return;

      const { updateStatus } = data;

      cache.updateQuery(
        {
          query: GET_PROJECT_TASKS_FOR_BOARD_VIEW,
          variables: {
            projectId,
          },
        },
        ({ kanbanTasks = [] }) => {
          return {
            kanbanTasks: kanbanTasks.map((task: any) => {
              if (task._id === updateStatus._id) {
                return {
                  ...task,
                  title: updateStatus.title || task.title,
                  color: updateStatus.color || task.color,
                  taskOrder: updateStatus.taskOrder || task.taskOrder,
                };
              }
              return task;
            }),
          };
        },
      );
    },
  });

  const [deleteStatus] = useDeleteStatusMutation({
    update(cache, { data }) {
      if (!data?.deleteStatus) return;

      const { deleteStatus } = data;
      const cacheId = `KanbanTasks:${deleteStatus._id}`;
      cache.evict({ id: cacheId });
      cache.gc();
    },
  });

  const [createStatus] = useCreateStatusMutation({
    update(cache, { data }) {
      if (!data?.createStatus) return;

      cache.modify({
        fields: {
          kanbanTasks(existingTasks = []) {
            const newTaskRef = cache.writeFragment({
              data: {
                ...data.createStatus,
                __typename: 'KanbanTasks',
                tasksCount: 0,
                actions: [],
              },
              fragment: gql`
                fragment NewStatus on KanbanTasks {
                  _id
                  title
                  color
                  actions {
                    _id
                  }
                  tasksCount
                }
              `,
            });

            return [...existingTasks, newTaskRef];
          },
        },
      });
    },
  });

  return {
    onCreateStatus: async ({ projectId, title }) => {
      await createStatus({
        variables: {
          workspaceId,
          projectId,
          title,
        },
      });
    },
    onDeleteStatus: async ({ statusId }) => {
      await deleteStatus({
        variables: {
          statusId,
        },
      });
    },
    onUpdateStatus: async ({
      statusId,
      taskOrder,
      color,
      title,
    }: MutationUpdateStatusArgs) => {
      await updateStatus({
        variables: {
          statusId,
          taskOrder,
          color,
          title,
        },
        optimisticResponse: {
          __typename: 'Mutation',
          updateStatus: {
            __typename: 'Status',
            _id: statusId,
            title,
            color,
            taskOrder,
          } as UpdateStatusMutation['updateStatus'],
        },
      });
    },
  };
};
