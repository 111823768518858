import { DEFAULT_EDITOR_DATA } from '../constants';
import { Editor, Transforms } from 'slate';

import { SlateEditor } from '../types';

type ResetSlateProps = {
  editor: SlateEditor;
  setValue: (value: any) => void;
  shouldInsertEmptyBlock?: boolean;
};

export const resetSlate = ({
  editor,
  setValue,
  shouldInsertEmptyBlock = true,
}: ResetSlateProps) => {
  Transforms.delete(editor, {
    at: {
      anchor: Editor.start(editor, []),
      focus: Editor.end(editor, []),
    },
  });

  // Removes empty node
  Transforms.removeNodes(editor, {
    at: [0],
  });

  // Insert array of children nodes
  shouldInsertEmptyBlock && Transforms.insertNodes(editor, DEFAULT_EDITOR_DATA);

  // reset state value
  setValue(DEFAULT_EDITOR_DATA);
};
