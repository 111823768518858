export type InitialState = {
  title: string;
  description: string;
  elements: Field[];
  activeElement: string | null;
};

export enum FieldType {
  RadioGroup = 'radio-group',
  RatingScale = 'rating-scale',
  Checkboxes = 'checkboxes',
  Boolean = 'boolean',
  Input = 'input',
  TextArea = 'textarea',
}

export type Field = {
  type: FieldType;
  name: string;
  title: string;
  description: string;
  required: boolean;
};
