import styled from 'styled-components';

export const Control = styled.div`
  > div {
    background-color: transparent;
    border: none;
    outline: none;
    box-shadow: none;
    &:hover {
      border: none;
      outline: none;
      box-shadow: none;
    }
  }
`;
