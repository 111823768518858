const rgbaRegex = new RegExp(/^rgba?\(.+\)/);

export const parseRgba = rgbaString => {
  const [r, g, b, a] = rgbaString.match(/(\d\.\d|\d+)/g);
  return [+r, +g, +b, +a];
};

const parseCssAlias = nameString => {
  const ctx = document.createElement('canvas').getContext('2d');
  if (!ctx) return nameString;
  ctx.fillStyle = nameString;
  return ctx.fillStyle;
};

export const parseHex = (_color: any) => {
  const color = (_color || '#ffffff').replace('#', '');
  const r = parseInt(color.substr(0, 2), 16);
  const g = parseInt(color.substr(2, 2), 16);
  const b = parseInt(color.substr(4, 2), 16);
  return [r, g, b];
};

const parseColor = _color => {
  // Avoid color to be equals "null"
  const color = _color || '#ffffff';

  let r = 0;
  let g = 0;
  let b = 0;
  if (color.includes('#')) {
    [r, g, b] = parseHex(color);
  } else if (rgbaRegex.test(color)) {
    [r, g, b] = parseRgba(color);
  } else {
    [r, g, b] = parseHex(parseCssAlias(color));
  }

  return [r, g, b];
};

// Return whether a color is light enough that it needs dark text
// to maintain contrast
export const colorIsLight = (_color, yiqIndex = 155) => {
  try {
    const [r, g, b] = parseColor(_color);

    const yiq = (r * 299 + g * 587 + b * 114) / 1000;
    return yiq > yiqIndex;
  } catch (e) {
    console.log('Incorrect color detected', _color);
    return false;
  }
};

export const getContrastTextColor = ({ backgroundColor, themeMode }) => {
  const isLight = colorIsLight(backgroundColor);
  return isLight ? '#000' : '#fff';
};
