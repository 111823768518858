import React from 'react';
import { useSlate } from 'slate-react';

import { activeMark, addMarkData } from '../utils/SlateUtilityFunctions';

type ButtonProps = {
  format?: string;
  options?: {
    text: string;
    value: string;
  }[];
};

const Dropdown = ({ format, options }: ButtonProps) => {
  const editor = useSlate();

  const changeMarkData = (event, format) => {
    event.preventDefault();
    const value = event.target.value;
    addMarkData(editor, { format, value });
  };

  return (
    <select
      value={activeMark(editor, format)}
      onChange={e => changeMarkData(e, format)}
    >
      {options &&
        options.map((item, index) => (
          <option key={index} value={item.value}>
            {item.text}
          </option>
        ))}
    </select>
  );
};

export default Dropdown;
