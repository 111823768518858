export const convertSecondsToHMS = (seconds: number): string => {
  if (seconds && !isNaN(seconds)) {
    const fixedSeconds = Number(seconds.toFixed(0));

    const hours = Math.floor(fixedSeconds / 3600);
    const minutes = Math.floor((fixedSeconds % 3600) / 60);
    const remainingSeconds = fixedSeconds % 60;

    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(remainingSeconds).padStart(2, '0');

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  }
  return '00:00:00';
};
