import styled from 'styled-components';

type ContainerProps = {
  top: string;
  left: string;
};
export const Container = styled.div<ContainerProps>`
  position: absolute;
  top: ${({ top }) => top};
  left: ${({ left }) => left};
  border-radius: ${({ theme }) => theme.border.radius.sm};
  background-color: ${({ theme }) => theme.background.secondary};
`;
