import React from 'react';

import { Flex } from 'src/components/design-system';

import ColorButton from './ColorButton';

import { Color } from '../types';

type ColorPickerProps = {
  onChange: (color: Color) => void;
};
const ColorPicker = ({ onChange }: ColorPickerProps) => {
  return (
    <Flex gap={8} maxWidth="120px" flexWrap="wrap">
      <ColorButton color={{ r: 243, g: 82, b: 35 }} onClick={onChange} />
      <ColorButton color={{ r: 255, g: 249, b: 177 }} onClick={onChange} />
      <ColorButton color={{ r: 68, g: 202, b: 99 }} onClick={onChange} />
      <ColorButton color={{ r: 39, g: 142, b: 237 }} onClick={onChange} />
      <ColorButton color={{ r: 155, g: 105, b: 245 }} onClick={onChange} />
      <ColorButton color={{ r: 252, g: 142, b: 42 }} onClick={onChange} />
      <ColorButton color={{ r: 0, g: 0, b: 0 }} onClick={onChange} />
      <ColorButton color={{ r: 255, g: 255, b: 255 }} onClick={onChange} />
    </Flex>
  );
};

export default ColorPicker;
