import styled from 'styled-components/macro';

type MenuItemProps = {
  selected?: boolean;
  danger?: boolean;
  clean?: boolean;
  disabled?: boolean;
};

export const Menu = styled.span<MenuItemProps>`
  ${({ clean }) =>
    !clean &&
    `
    display: block;
    padding: 8px;
    width: 250px;
    overflow: hidden;
    max-width: 300px;
    box-shadow: 0 3px 6px rgba(140, 149, 159, 0.05);
    background-color: ${({ theme }) => theme.background.primary};
    border: 1px solid ${({ theme }) => theme.border.color.primary};
    border-radius: ${({ theme }) => theme.border.radius.sm};
  `}
`;

export const MenuItem = styled.span<MenuItemProps>`
  display: block;
  width: 100%;
  padding: 10px 12px;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  font-size: 12px;
  color: ${p => p.theme.text.primary};
  background-color: ${({ theme }) => theme.background.secondary};
  border-radius: ${({ theme }) => theme.border.radius.md};
  &:hover {
    background-color: ${p => p.theme.background.hover};
    border-radius: ${({ theme }) => theme.border.radius.md};
  }

  ${({ selected, theme }) =>
    selected &&
    `
    background-color: ${theme.background.selected};
    font-weight: bold;
  `}

  ${({ danger, theme }) =>
    danger &&
    `
    color: ${theme.color.danger};

    &:hover {
      background-color: ${theme.color.dangerLight};
    }
  `}


  ${({ disabled, theme }) =>
    disabled &&
    `
    opacity: 0.5;
    cursor: not-allowed;
  `}
`;

export const Divider = styled.div`
  margin: 6px -8px;
  height: 1px;
  background-color: ${({ theme }) => theme.border.color.primary};
`;

export const Title = styled.h3`
  padding-left: 10px;
`;
