import React from 'react';
import { Draggable } from 'react-beautiful-dnd';

import TaskPreview from './TaskPreview/TaskPreview';

const Action = ({ action, index }) => {
  const { _id } = action;

  return (
    <Draggable draggableId={_id} index={index}>
      {(provided, snapshot) => (
        <li
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          <TaskPreview task={action} isDragging={snapshot.isDragging} />
        </li>
      )}
    </Draggable>
  );
};

export default Action;
