import { Table } from '@tanstack/react-table';
import React from 'react';

import Trow from '../Trow/Trow';
import { type UniqueIdentifier } from '@dnd-kit/core';
import {
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';

import { TableTask } from '../types';

type TbodyProps = {
  table: Table<TableTask>;
  dataIds: UniqueIdentifier[];
  columnOrder: string[];
  setEditingCell: (value: string | null) => void;
  editingCell: string | null;
};

const Tbody = ({
  table,
  dataIds,
  columnOrder,
  setEditingCell,
  editingCell,
}: TbodyProps) => {
  return (
    <div>
      <SortableContext items={dataIds} strategy={verticalListSortingStrategy}>
        {table.getRowModel().rows.map(row => {
          return (
            <Trow
              key={row.id}
              row={row}
              table={table}
              columnOrder={columnOrder}
              setEditingCell={setEditingCell}
              editingCell={editingCell}
            />
          );
        })}
      </SortableContext>
    </div>
  );
};

export default Tbody;
