import React from 'react';

import { colorToCss } from '../utils/utils';

import { RectangleLayer } from '../types';

type RectangleProps = {
  id: string;
  layer: RectangleLayer;
  onPointerDown: (e: React.PointerEvent<SVGRectElement>, id: string) => void;
  selectionColor?: string;
};

const Rectangle = ({
  id,
  layer,
  onPointerDown,
  selectionColor,
}: RectangleProps) => {
  const { x, y, width, height, fill } = layer;
  return (
    <rect
      style={{
        transform: `translate(${x}px, ${y}px)`,
        filter:
          'drop-shadow(0 4px 3px rgb(0 0 0 / 0.07)) drop-shadow(0 2px 2px rgb(0 0 0 / 0.06))',
      }}
      onPointerDown={e => onPointerDown(e, id)}
      x={0}
      y={0}
      width={width}
      height={height}
      fill={fill ? colorToCss(fill) : '#ccc'}
      stroke={selectionColor || 'transparent'}
      strokeWidth={3}
    />
  );
};

export default Rectangle;
