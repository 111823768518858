import React, { useContext, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';

import { Button, FormItem, Checkbox, Flex } from 'src/components/design-system';

import FormContext from '../../../FormContext';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';

const GeneralForm = ({ element }) => {
  const { title, name, description, required } = element;

  const {
    state: { elements },
    dispatch,
  } = useContext(FormContext);

  const defaultValues = {
    name,
    title,
    description,
    required,
  };

  const onSubmit = data => {
    const newElements = elements.map(item => {
      if (item.name === name) {
        return {
          ...item,
          ...data,
        };
      }

      return item;
    });

    dispatch({ elements: newElements });
  };

  const FormSchema = z.object({
    name: z
      .string()
      .min(1, 'Name should be more than 1')
      .max(100, 'Name should be less than 100')
      .default(''),
    title: z.string().default('').optional(),
    description: z.string().default('').optional(),
    required: z.boolean().default(false).optional(),
  });

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: { ...defaultValues },
    mode: 'onChange',
  });

  useEffect(() => {
    form.reset({
      required,
      name,
      title,
      description,
    });
  }, [name, title, description, required]);

  return (
    <FormProvider {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        onBlur={form.handleSubmit(onSubmit)}
      >
        <FormItem name="name" label="Name">
          <input type="text" />
        </FormItem>

        <FormItem name="title" label="Title">
          <input type="text" />
        </FormItem>

        <FormItem name="description" label="Description">
          <textarea />
        </FormItem>

        <FormItem name="required">
          <label>
            <Flex alignItems="center">
              <Checkbox />
              <span>{'Required'}</span>
            </Flex>
          </label>
        </FormItem>
        <Button onSubmit={form.handleSubmit(onSubmit)}>{'Submit'}</Button>
      </form>
    </FormProvider>
  );
};

export default GeneralForm;
