import { CKEditor } from '@ckeditor/ckeditor5-react';
import React, { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useQueryParams } from 'src/hooks/useQueryParams';
import { useTask } from 'src/hooks/useTask';

import { isEqual } from 'lodash';
import debounce from 'lodash/debounce';
import Editor from 'manage-ui-ckeditor5';

const TaskDescription = ({ action }) => {
  const query = useQueryParams();
  const taskId = query.get('taskId') as string;
  const { t } = useTranslation();
  const { _id, description } = action;

  const { onUpdateTask } = useTask();

  const [value, setValue] = useState(description);

  // update description when the taskId changes
  useEffect(() => {
    setValue(description);
  }, [taskId]);

  const handleDebounceFn = newValue => {
    const isSameValue = isEqual(description, newValue);

    if (isSameValue) return;

    onUpdateTask({
      taskId: _id,
      description: newValue,
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceFn = useCallback(debounce(handleDebounceFn, 400), [
    _id,
    description,
  ]);

  const handleUpdateDescription = newValue => {
    setValue(newValue);
    debounceFn(newValue);
  };

  useEffect(() => {
    const iframes = document.querySelectorAll('.ck-content iframe');
    iframes.forEach(iframe => {
      iframe.addEventListener('click', e => {
        e.stopPropagation(); // Ensures that clicks go through
      });
    });
  }, []); // Ensures it only runs once when the component mounts

  return (
    <CKEditor
      config={{
        placeholder: t('task.description.addDescriptionPlaceholder'),
        mediaEmbed: {
          previewsInData: true,
        },
      }}
      editor={Editor}
      data={value}
      onReady={editor => {}}
      onChange={(event, editor) => {
        const data = editor.getData();
        const isSameValue = isEqual(data, description);
        if (isSameValue) return;
        handleUpdateDescription(data);
      }}
      onBlur={(event, editor) => {}}
      onFocus={(event, editor) => {}}
    />
  );
};

export default TaskDescription;
