import styled from 'styled-components';

export const Container = styled.div`
  padding: 10px;
`;

export const Field = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
  padding: 8px 16px;
  cursor: pointer;
  border: 1px solid ${p => p.theme.border.color.primary};
  border-radius: ${({ theme }) => theme.border.radius.md};

  &:hover {
    border-color: ${p => p.theme.border.color.secondary};
  }
`;
