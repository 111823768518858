import { create } from 'zustand';

import { User } from 'src/generated';

interface UserStore {
  user: User | null;
  setUser: (user: User | null) => void;
}

export const useUserStore = create<UserStore>(set => ({
  user: null,
  setUser: user => set({ user }),
}));

export const useUserWorkspace = () =>
  useUserStore(state => state.user?.workspaceId!);

export const useUser = () => useUserStore(state => state.user!);
