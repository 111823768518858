import { useState, useRef, useCallback } from 'react';

import { DocumentNode, useQuery, QueryHookOptions } from '@apollo/client';

import useIntersectionObserver from './useIntersectionObserver';

type UseQueryWithFetchMoreOptions<TData, TVariables> = {
  query: DocumentNode;
  variables?: TVariables;
  initialVariables?: QueryHookOptions<TData, TVariables>['variables'];
};

type UseQueryWithFetchMoreResult<TData> = {
  data?: TData;
  initialLoading: boolean;
  fetchingMore: boolean;
  ref: React.RefObject<HTMLDivElement>;
  isIntersecting: boolean;
};

function useQueryWithFetchMore<TData, TVariables>({
  query,
  variables,
}: UseQueryWithFetchMoreOptions<
  TData,
  TVariables
>): UseQueryWithFetchMoreResult<TData> {
  const hasMoreRef = useRef(true);
  const [fetchingMore, setFetchingMore] = useState(false);

  // Use Apollo's `useQuery` hook with initial variables
  const {
    data,
    loading: initialLoading,
    fetchMore,
  } = useQuery<TData, TVariables>(query, {
    variables,
    notifyOnNetworkStatusChange: true,
  });

  // Fetch more data when the observer triggers
  const handleIntersect = useCallback(async () => {
    if (fetchingMore || initialLoading || !hasMoreRef.current) return;

    // Type assertion on `data` to tell TypeScript what its shape is
    const dataValues: any = data ? Object.values(data)[0] : [];

    setFetchingMore(true);
    await fetchMore({
      variables: {
        ...variables,
        skip: dataValues.length,
      } as TVariables,
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        // Ensure the correct typing of prev and fetchMoreResult
        const prevValues = Object.values(prev as any)[0] || [];
        const fetchMoreValues = Object.values(fetchMoreResult)[0] || [];

        // If there's no more data to fetch, set hasMore to false
        // @ts-ignore
        if (fetchMoreValues.length === 0) {
          hasMoreRef.current = false;
        }

        return Object.assign({}, prev, {
          // @ts-ignore
          [Object.keys(prev)[0]]: [...prevValues, ...fetchMoreValues],
        });
      },
    });
    setFetchingMore(false);
  }, [fetchingMore, initialLoading, fetchMore, data, variables]);

  // Initialize intersection observer
  const { ref, isIntersecting } = useIntersectionObserver(handleIntersect, {
    rootMargin: '100px',
  });

  return { ref, data, initialLoading, fetchingMore, isIntersecting };
}

export default useQueryWithFetchMore;
