import { Avatar, Flex, Icon, IconName } from 'src/components/design-system';

import { DateHelpers, DateFormats } from 'src/common/helpers';

import { NotificationsQuery, NotificationTypeEnum } from 'src/generated';

import * as Styled from './styles';

type NotificationProps = {
  notification: NotificationsQuery['notifications'][0];
  handleReadNotification: (notificationId: string) => void;
};

const generateNotificationIcon = (
  type?: NotificationTypeEnum | null,
): IconName => {
  switch (type) {
    case NotificationTypeEnum.DueDate:
      return 'time';
    default:
      return 'time';
  }
};

const Notification = ({
  notification,
  handleReadNotification,
}: NotificationProps) => {
  const { _id, title, message, isRead, createdAt, user, type } = notification;

  return (
    <Styled.Notification
      direction="column"
      gap={10}
      isRead={isRead}
      onClick={() => !isRead && handleReadNotification(_id)}
    >
      <Flex alignItems="center" gap={10}>
        {!isRead && <Styled.UnreadDot />}
        <Styled.Title>{title}</Styled.Title>
      </Flex>
      <Flex gap={10} alignItems="center">
        <Avatar user={user} mr={10} />
        <Icon name={generateNotificationIcon(type)} />
        <Flex direction="column">
          <div>{message}</div>
          <Styled.NotificationTime>
            {DateHelpers.formatDate(createdAt, DateFormats.FullDate)}
          </Styled.NotificationTime>
        </Flex>
      </Flex>
    </Styled.Notification>
  );
};

export default Notification;
