import React from 'react';
import { useSlate } from 'slate-react';

import { Icon, IconName } from 'src/components/design-system';

import Button from '../common/Button';

import { isBlockActive, toggleBlock } from '../utils/SlateUtilityFunctions';

type ButtonProps = {
  format?: string;
  options?: {
    text: string;
    value: string;
  }[];
};

const BlockButton = ({ format }: ButtonProps) => {
  const editor = useSlate();

  return (
    <Button
      active={isBlockActive(editor, format)}
      format={format}
      onMouseDown={e => {
        e.preventDefault();
        toggleBlock(editor, format);
      }}
    >
      <Icon name={format as IconName} />
    </Button>
  );
};

export default BlockButton;
