import React from 'react';

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Cell,
  ResponsiveContainer,
  TooltipProps,
} from 'recharts';

import { TooltipBlock } from './styles';

const CustomTooltip = ({
  active,
  payload,
  label,
}: TooltipProps<number, string>) => {
  if (active && payload && payload.length) {
    return (
      <TooltipBlock>
        <div>{label}</div>
        <div>{`${payload[0].value} task(s)`}</div>
      </TooltipBlock>
    );
  }

  return null;
};

export default function TasksBySection({ data }) {
  return (
    <ResponsiveContainer width="100%" height={350}>
      <BarChart data={data}>
        <XAxis
          dataKey="title"
          angle={-45}
          textAnchor="end"
          scale="point"
          height={80}
          padding={{ left: 25, right: 25 }}
        />
        <YAxis allowDecimals={false} axisLine={false} tickLine={false} />
        <Tooltip
          wrapperStyle={{ outline: 'none' }}
          content={<CustomTooltip />}
        />
        <CartesianGrid vertical={false} strokeDasharray="3 6" />
        <Bar
          barSize={40}
          dataKey="tasksCount"
          fill="#a1043b"
          radius={[4, 4, 0, 0]}
        >
          {data.map((entry, index) => {
            const cellColor =
              entry.title === 'Completed' ? 'rgb(0, 200, 117)' : '#a1043b';

            return <Cell key={`cell-${index}`} fill={cellColor} />;
          })}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
}
