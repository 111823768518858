import { gql } from '@apollo/client';

export const ON_CREATE_TASK_DESCRIPTION_HISTORY = gql`
  subscription OnCreateTaskDescriptionHistory {
    onCreateTaskDescriptionHistory {
      _id
      text
      taskId
      user {
        fullName
      }
    }
  }
`;
