import styled from 'styled-components';

type ChapterItemProps = {
  progress: string;
};
export const ChapterItem = styled.div<ChapterItemProps>`
  padding: 20px;
  margin: 20px 0;
  border-top: 1px solid ${({ theme }) => theme.border.color.primary};
  border-bottom: 1px solid ${({ theme }) => theme.border.color.primary};
  width: 100%;
  position: relative;
  z-index: 2;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: ${({ progress }) => progress};
    background: rgba(115, 231, 7, 0.32);
    z-index: 1;
    transition: width 0.5s ease-in-out;
  }
`;

export const Inner = styled.div`
  position: relative;
  z-index: 2;
`;

export const ChapterTitle = styled.h3`
  flex: 1;
  margin-top: 0;
`;

export const ChapterTime = styled.div`
  flex: 1;
  margin-top: 0;
`;
