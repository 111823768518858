import React from 'react';

import TaskModalLeft from './TaskModalLeft/TaskModalLeft';
import TaskModalRight from './TaskModalRight/TaskModalRight';

import { Container, Row } from './styles';

const TaskModal = ({ action, onClose }) => {
  return (
    <Container>
      <Row>
        <TaskModalLeft action={action} onClose={onClose} />
        <TaskModalRight action={action} />
      </Row>
    </Container>
  );
};

export default TaskModal;
