import React from 'react';

import DisplayPriorityDropdown from './DisplayPriorityDropdown';
import EditPriorityDropdown from './EditPriorityDropdown';

const PriorityDropdown = ({
  id,
  rowIndex,
  taskId,
  priority,
  isEditing,
  updateData,
}) => {
  if (isEditing) {
    return (
      <EditPriorityDropdown
        id={id}
        rowIndex={rowIndex}
        taskId={taskId}
        priority={priority}
        updateData={updateData}
      />
    );
  }

  return <DisplayPriorityDropdown priority={priority} />;
};

export default PriorityDropdown;
