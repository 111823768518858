import { Flex, Icon } from 'src/components/design-system';

import { Container, AudioThumbnail, AudioText } from './styles';

const DisplayTrack = ({
  currentChapter,
  audioRef,
  setDuration,
  progressBarRef,
  handleGoToNextChapter,
  saveCurrentTime,
}) => {
  const onLoadedMetadata = () => {
    const seconds = audioRef.current.duration;
    setDuration(seconds);
    progressBarRef.current.max = seconds;
  };

  const handleEnded = () => {
    handleGoToNextChapter();
    saveCurrentTime();
  };

  return (
    <Container>
      <audio
        src={currentChapter.url}
        ref={audioRef}
        onLoadedMetadata={onLoadedMetadata}
        onEnded={handleEnded}
      />
      <Flex alignItems="center">
        <AudioThumbnail>
          <Icon name="note" size={40} />
        </AudioThumbnail>
        <AudioText>
          <h2>{currentChapter.title}</h2>
          <p>{currentChapter.author}</p>
        </AudioText>
      </Flex>
    </Container>
  );
};
export default DisplayTrack;
