import { gql } from '@apollo/client';

gql`
  mutation UpdateUserProfile(
    $firstName: String
    $lastName: String
    $role: UserRoleEnum
    $file: Upload
  ) {
    updateUserProfile(
      input: {
        firstName: $firstName
        lastName: $lastName
        role: $role
        file: $file
      }
    ) {
      _id
      profilePicture
      firstName
      lastName
      fullName
      email
      role
    }
  }
`;

gql`
  mutation UpdateUser($pushNotificationToken: String, $projectsOrder: [ID!]) {
    updateUser(
      pushNotificationToken: $pushNotificationToken
      projectsOrder: $projectsOrder
    ) {
      _id
      pushNotificationToken
      projectsOrder
    }
  }
`;
