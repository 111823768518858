import React from 'react';
import { components } from 'react-select';

import { Select } from 'src/components/design-system';

import { useTask } from 'src/hooks/useTask';

import { useProject } from 'src/helpers/apollo';

import { getContrastTextColor } from 'src/helpers/colors';

import * as Styled from './styles';

const SingleValue = ({ children, ...props }: any) => {
  const {
    selectProps: {
      value: { backgroundColor },
    },
  } = props;

  const color = getContrastTextColor({
    backgroundColor,
    themeMode: 'themeMode',
  });

  return (
    <components.SingleValue {...props}>
      <div style={{ color }}>{children}</div>
    </components.SingleValue>
  );
};

const ControlComponent = props => {
  return (
    <Styled.Control>
      <components.Control {...props} />
    </Styled.Control>
  );
};

const EditStatusDropdown = ({ id, rowIndex, taskId, statusId, updateData }) => {
  const [taskStatusId, setTaskStatusId] = React.useState(statusId);

  const { statuses } = useProject();

  const { onUpdateTask } = useTask();

  const status = statuses.find(({ _id }) => _id === taskStatusId);

  if (!status) return null;

  const options = statuses.map(({ _id, title, color }) => ({
    value: _id,
    label: title,
    backgroundColor: color,
  }));

  const { _id, title, color: backgroundColor } = status;

  return (
    <div style={{ width: '100%', padding: '6px 0', backgroundColor }}>
      <Select
        menuIsOpen
        menuPosition="absolute"
        value={{ value: _id, label: title, backgroundColor }}
        onChange={({ value }: any) => {
          setTaskStatusId(value);
          onUpdateTask({
            taskId,
            statusId: value,
          });
          updateData(rowIndex, id, value);
        }}
        options={options}
        components={{
          SingleValue,
          Control: ControlComponent,
          ClearIndicator: undefined,
          DropdownIndicator: undefined,
        }}
      />
    </div>
  );
};

export default EditStatusDropdown;
