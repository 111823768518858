export const storyPointsList = [
  {
    value: null,
    label: 'None',
  },
  {
    value: 1,
    label: 'few hours',
  },
  {
    value: 2,
    label: 'day',
  },
  {
    value: 3,
    label: 'two/three days',
  },
  {
    value: 5,
    label: 'week',
  },
  {
    value: 8,
    label: 'two weeks',
  },
  {
    value: 13,
    label: 'epic',
  },
  {
    value: 20,
    label: 'two epics',
  },
  {
    value: 40,
    label: 'three epics',
  },
  {
    value: 100,
    label: 'can take a while',
  },
];
