import { Flex } from 'src/components/design-system';
import styled from 'styled-components';

type CommentActionsProps = {
  visible?: boolean;
};

export const CommentActions = styled(Flex)<CommentActionsProps>`
  display: ${({ visible }) => (visible ? 'flex' : 'none')};
  position: absolute;
  top: 0;
  right: 0;
  border: 1px solid ${({ theme }) => theme.border.color.primary};
  border-radius: ${({ theme }) => theme.border.radius.sm};

  button {
    border: none;
    border-radius: 0;
    min-width: 30px;
    &:not(:first-child) {
      border-left: 1px solid ${({ theme }) => theme.border.color.primary};
    }
    &:first-child {
      border-radius: 4px 0 0 4px;
    }
    &:last-child {
      border-radius: 0 4px 4px 0;
    }
  }
`;

export const CommentBlock = styled.div`
  display: flex;
  align-items: flex-start;
  position: relative;
  width: 100%;
  padding: 15px 30px 15px 0;

  &:hover {
    ${CommentActions} {
      display: flex;
    }
  }
`;

export const CommentBody = styled.div``;

export const Time = styled.span`
  color: ${({ theme }) => theme.text.secondary};
  padding-left: 5px;
  font-size: 13px;
`;

export const Reaction = styled(Flex)`
  padding: 5px 10px;
  border: 1px solid ${({ theme }) => theme.border.color.primary};
  border-radius: ${({ theme }) => theme.border.radius.sm};
  color: ${({ theme }) => theme.color.success};
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.background.hover};
  }
`;
export const EmojiPickerWrapper = styled.div`
  position: absolute;
  top: 35px;
  right: 0;
  z-index: 2;
`;
