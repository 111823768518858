import { css } from 'styled-components';

export const FullCalendarStyle = css`
  body .fc-theme-standard .fc-scrollgrid,
  body .fc-theme-standard td,
  body .fc-theme-standard th {
    border: 8px solid ${p => p.theme.background.primary};
  }

  .fc .fc-daygrid-day-frame {
    padding: 8px;
    border-radius: ${({ theme }) => theme.border.radius.md};
    &:hover {
      background: ${({ theme }) => theme.grayScale.gray25};
    }
  }
`;
