import { gql } from '@apollo/client';

gql`
  mutation CreateProject($title: String!, $color: String!) {
    createProject(input: { title: $title, color: $color }) {
      title
      color
    }
  }
`;

gql`
  mutation UpdateProject(
    $projectId: ID!
    $title: String
    $color: String
    $boardsSortList: [ID!]
    $status: ProjectStatusEnum
  ) {
    updateProject(
      projectId: $projectId
      title: $title
      color: $color
      boardsSortList: $boardsSortList
      status: $status
    ) {
      _id
      title
      color
      boardsSortList
      status
    }
  }
`;

export const ACCEPT_INVITE_TO_PROJECT = gql`
  mutation AcceptInviteToProject(
    $firstName: String!
    $lastName: String!
    $password: String!
    $inviteToken: String!
  ) {
    acceptInviteToProject(
      firstName: $firstName
      lastName: $lastName
      password: $password
      inviteToken: $inviteToken
    ) {
      user {
        _id
        fullName
        email
        profilePicture
      }
      token
    }
  }
`;

gql`
  mutation InviteProjectMember(
    $workspaceId: ID!
    $projectId: ID!
    $email: String!
  ) {
    inviteProjectMember(
      workspaceId: $workspaceId
      projectId: $projectId
      email: $email
    )
  }
`;

gql`
  mutation DeleteProject($projectId: ID!) {
    deleteProject(projectId: $projectId)
  }
`;
