import React from 'react';

import { Flex } from 'src/components/design-system';
import PriorityIcon from 'src/components/design-system/Select/components/priority/PriorityIcon';

import { PRIORITY } from 'src/common/constants';

const DisplayPriorityDropdown = ({ priority }) => {
  if (!priority) {
    return null;
  }

  const { value, label } = priority;

  return (
    <Flex padding={10} alignItems="center" gap={10} width="100%">
      {value !== PRIORITY.NONE && <PriorityIcon {...priority} />}
      <div>{label}</div>
    </Flex>
  );
};

export default DisplayPriorityDropdown;
