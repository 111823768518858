import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Icon } from 'src/components/design-system';

import { useQueryParams } from 'src/hooks/useQueryParams';

import { ParentLink, ParentLinkTitle } from './styles';

const TaskParent = ({ _id, title }) => {
  const navigate = useNavigate();
  const query = useQueryParams();

  const handleGoToParent = () => {
    query.set('taskId', _id);
    navigate({ search: query.toString() });
  };

  return (
    <ParentLink onClick={handleGoToParent}>
      <Icon name="back-arrow" size={20} />
      <ParentLinkTitle>{title}</ParentLinkTitle>
    </ParentLink>
  );
};

export default TaskParent;
