import { useParams } from 'react-router-dom';

import { useQueryParams } from './useQueryParams';

import { useCreateTaskCustomFieldMutation } from 'src/generated';

export const useTaskCustomFields = () => {
  const { id: projectId } = useParams() as any;
  const query = useQueryParams();
  const taskId = query.get('taskId') as string;

  const [createTaskCustomField] = useCreateTaskCustomFieldMutation({});

  return {
    onCreateTaskCustomField: async ({ customFieldId, value }) => {
      await createTaskCustomField({
        variables: {
          customFieldId,
          value,
          projectId,
          taskId,
        },
      });
    },
  };
};
