import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { LoadingIndicator } from 'src/components/design-system';
import { useTheme } from 'styled-components';

import DashboardTasksByPeriod from 'src/widgets/DashboardTasksByPeriod/DashboardTasksByPeriod';

import TasksBySection from './TasksBySection';
import { useUserWorkspace } from 'src/store';

import { useTaskCountsQuery } from 'src/generated';

import { Container, Card, CardContainer, Title, Count } from './styles';

const Dashboard = () => {
  const workspaceId = useUserWorkspace();
  const { t } = useTranslation();
  const theme = useTheme();
  const projectId = useParams<{ id: string }>().id!;

  const { data, loading, error, refetch } = useTaskCountsQuery({
    variables: { workspaceId, projectId },
  });

  useEffect(() => {
    refetch();
  }, [refetch]);

  if (loading) return <LoadingIndicator />;

  if (error) return <p>{'Error :('}</p>;

  const { incompleteTasks = 0, completedTasks = 0 } = data?.taskCounts || {};

  return (
    <Container>
      <CardContainer>
        <Card>
          <Title>{t('project.dashboard.completedTasks')}</Title>
          <Count color={theme.color.success}>{completedTasks}</Count>
        </Card>
      </CardContainer>
      <CardContainer>
        <Card>
          <Title>{t('project.dashboard.incompleteTasks')}</Title>
          <Count>{incompleteTasks}</Count>
        </Card>
      </CardContainer>
      <CardContainer>
        <Card>
          <Title>{t('project.dashboard.totalTasks')}</Title>
          <Count>
            {incompleteTasks && completedTasks
              ? completedTasks + incompleteTasks
              : 0}
          </Count>
        </Card>
      </CardContainer>

      <CardContainer width={'100%'}>
        <Card>
          <Title>{t('project.dashboard.tasksByStatus')}</Title>
          <TasksBySection data={data?.taskByStatusWithCount} />
        </Card>
      </CardContainer>

      <CardContainer width={'100%'}>
        <DashboardTasksByPeriod projectId={projectId} />
      </CardContainer>
    </Container>
  );
};

export default Dashboard;
