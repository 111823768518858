import React from 'react';

import { Flex, Skeleton } from 'src/components/design-system';

import { randomIntFromInterval } from 'src/helpers/numbers';

const BoardSkeleton = () => {
  return (
    <Flex gap={20}>
      {[1, 2, 3, 4].map(item => (
        <Flex key={item} gap={10} direction="column" width="100%">
          {Array.from({ length: randomIntFromInterval(1, 7) }, (v, i) => i).map(
            item => (
              <Flex gap={10} direction="column" key={item} padding={15}>
                <Skeleton width="50%" height={10} />
                <Skeleton width="70%" height={10} />
                <Skeleton width="40%" height={10} />
                <Skeleton width="80%" height={10} />
              </Flex>
            ),
          )}
        </Flex>
      ))}
    </Flex>
  );
};

export default BoardSkeleton;
