import styled from 'styled-components/macro';

export const ColorsDropdown = styled.div`
  display: flex;

  .react-colorful {
    padding: 10px 10px 0 0;
  }
`;

export const PreviewColor = styled.div`
  width: 100px;
  height: 38px;
  border-radius: ${({ theme }) => theme.border.radius.sm};
  margin-bottom: 4px;
  min-width: 100px;
  cursor: pointer;
`;
