import React from 'react';

import { Icon, IconName } from 'src/components/design-system';

const PriorityIcon = ({ color, number }) => {
  const iconsName = `fill-dice-${number}` as IconName;

  return <Icon name={iconsName} color={color} size={16} />;
};

export default PriorityIcon;
