import React from 'react';

import Sticker from './elements/Sticker/Sticker';

const Element = props => {
  const { attributes, children, element } = props;

  switch (element.type) {
    case 'list-item':
      return <li {...attributes}>{children}</li>;
    case 'orderedList':
      return (
        <ol type="1" {...attributes}>
          {children}
        </ol>
      );
    case 'unorderedList':
      return <ul {...attributes}>{children}</ul>;
    case 'giphy':
      return <Sticker {...props}>{children}</Sticker>;
    default:
      return <p {...attributes}>{children}</p>;
  }
};

export default Element;
