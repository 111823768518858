import React from 'react';

import ProjectsTable from './ProjectsTable/ProjectsTable';

import { useProjectsDashboardQuery } from 'src/generated';

const ProjectList = () => {
  const { loading, data: { projectsDashboard } = {} } =
    useProjectsDashboardQuery();

  return <ProjectsTable projects={projectsDashboard} loading={loading} />;
};

export default ProjectList;
