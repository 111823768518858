export const PROJECT_MORE_MENU = {
  CUSTOM_FIELDS: 'customFields',
  EDIT_PROJECT: 'editProject',
  ARCHIVE_PROJECT: 'archiveProject',
  DELETE_PROJECT: 'deleteProject',
};

export const projectMoreMMenu = [
  {
    id: PROJECT_MORE_MENU.CUSTOM_FIELDS,
    title: 'project.moreBtn.customFields',
    icon: 'custom-fields',
  },
  {
    type: 'divider',
  },
  {
    id: PROJECT_MORE_MENU.EDIT_PROJECT,
    title: 'project.moreBtn.editProject',
    icon: 'edit',
  },
  {
    id: PROJECT_MORE_MENU.ARCHIVE_PROJECT,
    title: 'project.moreBtn.archiveProject',
    icon: 'archive',
    disabled: true,
  },
  {
    type: 'divider',
  },
  {
    id: PROJECT_MORE_MENU.DELETE_PROJECT,
    title: 'project.moreBtn.deleteProject',
    icon: 'trash',
    danger: true,
  },
];
