import React, { useEffect, useRef, useState } from 'react';

import Sticker from '../Sticker/Sticker';
import { stickerBucked } from '../constants';

import { CommentTypeEnum } from 'src/generated';

import * as Styled from './styles';

const DEFAULT_STICKERS_COUNT = 30;

type PaginatedStickersProps = {
  stickersName: string;
  count: number;
  onCreateComment: (data: {
    type: CommentTypeEnum;
    sticker: string;
    taskId: string;
  }) => void;
  taskId: string;
  isVisible: boolean;
  type: any;
  extension: string;
};

const PaginatedStickers = ({
  stickersName,
  count,
  onCreateComment,
  taskId,
  isVisible,
  type,
  extension,
}: PaginatedStickersProps) => {
  const [stickersCount, setStickersCount] = useState(DEFAULT_STICKERS_COUNT);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const scrollContainer = containerRef.current;

    if (!scrollContainer) return;
    const handleScroll = () => {
      if (scrollContainer) {
        const { scrollTop, scrollHeight, clientHeight } = scrollContainer;
        const scrollThreshold = 100;

        const isNear =
          scrollTop + clientHeight + scrollThreshold >= scrollHeight;

        if (isNear) {
          setStickersCount(prev => {
            const newCount = prev + DEFAULT_STICKERS_COUNT;
            return newCount > count ? count : newCount;
          });
        }
      }
    };

    scrollContainer.addEventListener('scroll', handleScroll);
    return () => {
      scrollContainer.removeEventListener('scroll', handleScroll);
    };
  }, [count]);

  useEffect(() => {
    !isVisible && setStickersCount(DEFAULT_STICKERS_COUNT);
  }, [isVisible]);

  return (
    <Styled.PaginatedContainer ref={containerRef}>
      {Array.from({ length: stickersCount }).map((_, index) => {
        const url = `${stickerBucked}${stickersName}/${index}${extension}`;

        return (
          <Styled.Sticker
            key={url}
            onClick={() =>
              onCreateComment({
                type,
                taskId,
                sticker: url,
              })
            }
          >
            <Sticker url={url} type={type} stickersName={stickersName} />
          </Styled.Sticker>
        );
      })}
    </Styled.PaginatedContainer>
  );
};

export default PaginatedStickers;
