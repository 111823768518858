import React, { memo } from 'react';

import { Button, Icon, Flex } from 'src/components/design-system';

import { useDeleteLayers } from '../hooks/use-delete-layers';
import { useSelectionBounds } from '../hooks/use-selection-bounds';

import ColorPicker from './ColorPicker';
import { useMutation, useSelf } from 'src/liveblocks.config';

import { Camera, Color } from '../types';

import * as Styled from './styles';

type SelectionToolsProps = {
  camera: Camera;
  setLastUsedColor: (color: Color) => void;
};
const SelectionTools = ({ camera, setLastUsedColor }: SelectionToolsProps) => {
  const selection = useSelf(me => me.presence.selection);

  const setFill = useMutation(
    ({ storage }, fill: Color) => {
      const liveLayers = storage.get('layers');
      setLastUsedColor(fill);

      selection.forEach(id => {
        liveLayers.get(id)?.set('fill', fill);
      });
    },
    [selection, setLastUsedColor],
  );

  const selectionBounds = useSelectionBounds();

  const deleteLayers = useDeleteLayers();

  const moveToFront = useMutation(
    ({ storage }) => {
      const liveLayerIds = storage.get('layerIds');
      const indices: number[] = [];

      const arr = liveLayerIds.toImmutable();

      for (let i = 0; i < arr.length; i++) {
        if (selection.includes(arr[i])) {
          indices.push(i);
        }
      }

      for (let i = indices.length - 1; i >= 0; i--) {
        liveLayerIds.move(
          indices[i],
          arr.length - 1 - (indices.length - 1 - i),
        );
      }
    },
    [selection],
  );

  const moveToBack = useMutation(
    ({ storage }) => {
      const liveLayerIds = storage.get('layerIds');
      const indices: number[] = [];

      const arr = liveLayerIds.toImmutable();

      for (let i = 0; i < arr.length; i++) {
        if (selection.includes(arr[i])) {
          indices.push(i);
        }
      }

      for (let i = 0; i < indices.length; i++) {
        liveLayerIds.move(indices[i], i);
      }
    },
    [selection],
  );

  if (!selectionBounds) return null;

  const x = selectionBounds.width / 2 + selectionBounds.x + camera.x;
  const y = selectionBounds.y + camera.y;

  return (
    <Styled.Container
      alignItems="center"
      gap={10}
      style={{
        transform: `translate(
          calc(${x}px - 50%),
          calc(${y - 16}px - 100%)
        )`,
      }}
    >
      <ColorPicker onChange={setFill} />
      <Flex direction="column" gap={10}>
        <Button onClick={moveToFront}>
          <Icon name="copy" />
        </Button>
        <Button onClick={moveToBack}>
          <Icon name="copy" />
        </Button>
      </Flex>
      <Button onClick={deleteLayers}>
        <Icon name="trash" />
      </Button>
    </Styled.Container>
  );
};

export default memo(SelectionTools);
