import React from 'react';
import { useParams } from 'react-router-dom';

import { useReactiveVar } from '@apollo/client';

import { Avatar, Button, Flex, Icon } from 'src/components/design-system';

import HeaderTitle from './HeaderTitle/HeaderTitle';
import OpenAI from './OpenAI/OpenAI';
import ProjectInfo from './ProjectInfo/ProjectInfo';
import ProjectMembers from './ProjectMembers/ProjectMembers';
import Search from './Search/Search';
import TimeTracking from './TimeTracking/TimeTracking';
import TopNotificationButton from './TopNotificationButton/TopNotificationButton';
import { isOpenSidebarVar, openUserSettingsDrawer } from 'src/graphql/vars';
import { useUser } from 'src/store';

import { TopHeader } from '../styles';

const FirstMenu = () => {
  const { id: projectId } = useParams<{ id: string }>();
  const isOpenSidebar = useReactiveVar(isOpenSidebarVar);
  const user = useUser();

  const handleShowSetting = () => {
    openUserSettingsDrawer(true);
  };

  return (
    <TopHeader alignItems="center" padding={8} gap={50}>
      <Flex alignItems="center" gap={10} minWidth="150px">
        {!isOpenSidebar && (
          <Button size="small" onClick={() => isOpenSidebarVar(true)}>
            <Icon name="menu" size={24} />
          </Button>
        )}
        <HeaderTitle />
        {projectId && <ProjectInfo />}
        {projectId && <ProjectMembers />}
      </Flex>
      <Flex alignItems="center" flex="1" justifyContent="center">
        <Search />
      </Flex>
      <Flex
        alignItems="center"
        gap={10}
        minWidth="150px"
        justifyContent="flex-end"
      >
        <TimeTracking />
        {/*<OpenAI />*/}
        <TopNotificationButton />
        <Flex onClick={handleShowSetting} alignItems="center">
          <Avatar user={user} size={36} />
        </Flex>
      </Flex>
    </TopHeader>
  );
};

export default FirstMenu;
