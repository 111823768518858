import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
`;

export const SVG = styled.svg`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const Screenshot = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
`;
