import React, { useReducer } from 'react';
import { useSearchParams } from 'react-router-dom';

import FormContext from './FormContext';
import FormDesign from './FormDesign/FormDesign';
import FormHeader from './FormHeader/FormHeader';
import { FormViews } from './FormHeader/constants';
import FormPreview from './FormPreview/FormPreview';
import { initialState, reducer } from './constants';
import MainLayout from 'src/layouts/MainLayout/MainLayout';

import * as Styled from './styles';

const Forms = () => {
  const [searchParams] = useSearchParams();
  const currentView = searchParams.get('view') as FormViews;
  const [state, dispatch] = useReducer(reducer, initialState);

  const component = {
    [FormViews.Designer]: <FormDesign />,
    [FormViews.Preview]: <FormPreview />,
  };

  return (
    <MainLayout>
      <FormContext.Provider value={{ state, dispatch }}>
        <Styled.Container>
          <FormHeader />
          {component[currentView]}
        </Styled.Container>
      </FormContext.Provider>
    </MainLayout>
  );
};

export default Forms;
